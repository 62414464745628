*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.notes-editor h1 {
  margin-bottom: 1rem;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.notes-editor h2 {
  margin-bottom: .75rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.notes-editor h3 {
  margin-bottom: .5rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.notes-editor h4 {
  margin-bottom: .5rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.notes-editor p {
  margin-bottom: .5rem;
}

.notes-editor ul {
  margin-bottom: 1rem;
}

.notes-editor li {
  margin-bottom: .25rem;
  margin-left: 2rem;
  list-style-type: disc;
}

.notes-editor li p {
  margin-bottom: .25rem;
}

.notes-editor .ProseMirror:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.notes-editor {
  height: 12rem;
  overflow-y: auto;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.isolate {
  isolation: isolate;
}

.z-10 {
  z-index: 10;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.float-right {
  float: right;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-\[-2px\] {
  margin-right: -2px;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-\[-13px\] {
  margin-top: -13px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.\!grid {
  display: grid !important;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.max-h-60 {
  max-height: 15rem;
}

.min-h-full {
  min-height: 100%;
}

.min-h-max {
  min-height: max-content;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-2\/3 {
  flex-basis: 66.6667%;
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1 {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\!transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  user-select: none;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity, 1));
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity, 1));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity, 1));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

.border-sky-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity, 1));
}

.border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity, 1));
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: #0000;
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity, 1));
}

.bg-amber-100\/0 {
  background-color: #fef3c700;
}

.bg-amber-100\/10 {
  background-color: #fef3c71a;
}

.bg-amber-100\/100 {
  background-color: #fef3c7;
}

.bg-amber-100\/15 {
  background-color: #fef3c726;
}

.bg-amber-100\/20 {
  background-color: #fef3c733;
}

.bg-amber-100\/25 {
  background-color: #fef3c740;
}

.bg-amber-100\/30 {
  background-color: #fef3c74d;
}

.bg-amber-100\/35 {
  background-color: #fef3c759;
}

.bg-amber-100\/40 {
  background-color: #fef3c766;
}

.bg-amber-100\/45 {
  background-color: #fef3c773;
}

.bg-amber-100\/5 {
  background-color: #fef3c70d;
}

.bg-amber-100\/50 {
  background-color: #fef3c780;
}

.bg-amber-100\/55 {
  background-color: #fef3c78c;
}

.bg-amber-100\/60 {
  background-color: #fef3c799;
}

.bg-amber-100\/65 {
  background-color: #fef3c7a6;
}

.bg-amber-100\/70 {
  background-color: #fef3c7b3;
}

.bg-amber-100\/75 {
  background-color: #fef3c7bf;
}

.bg-amber-100\/80 {
  background-color: #fef3c7cc;
}

.bg-amber-100\/85 {
  background-color: #fef3c7d9;
}

.bg-amber-100\/90 {
  background-color: #fef3c7e6;
}

.bg-amber-100\/95 {
  background-color: #fef3c7f2;
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity, 1));
}

.bg-amber-200\/0 {
  background-color: #fde68a00;
}

.bg-amber-200\/10 {
  background-color: #fde68a1a;
}

.bg-amber-200\/100 {
  background-color: #fde68a;
}

.bg-amber-200\/15 {
  background-color: #fde68a26;
}

.bg-amber-200\/20 {
  background-color: #fde68a33;
}

.bg-amber-200\/25 {
  background-color: #fde68a40;
}

.bg-amber-200\/30 {
  background-color: #fde68a4d;
}

.bg-amber-200\/35 {
  background-color: #fde68a59;
}

.bg-amber-200\/40 {
  background-color: #fde68a66;
}

.bg-amber-200\/45 {
  background-color: #fde68a73;
}

.bg-amber-200\/5 {
  background-color: #fde68a0d;
}

.bg-amber-200\/50 {
  background-color: #fde68a80;
}

.bg-amber-200\/55 {
  background-color: #fde68a8c;
}

.bg-amber-200\/60 {
  background-color: #fde68a99;
}

.bg-amber-200\/65 {
  background-color: #fde68aa6;
}

.bg-amber-200\/70 {
  background-color: #fde68ab3;
}

.bg-amber-200\/75 {
  background-color: #fde68abf;
}

.bg-amber-200\/80 {
  background-color: #fde68acc;
}

.bg-amber-200\/85 {
  background-color: #fde68ad9;
}

.bg-amber-200\/90 {
  background-color: #fde68ae6;
}

.bg-amber-200\/95 {
  background-color: #fde68af2;
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity, 1));
}

.bg-amber-500\/0 {
  background-color: #f59e0b00;
}

.bg-amber-500\/10 {
  background-color: #f59e0b1a;
}

.bg-amber-500\/100 {
  background-color: #f59e0b;
}

.bg-amber-500\/15 {
  background-color: #f59e0b26;
}

.bg-amber-500\/20 {
  background-color: #f59e0b33;
}

.bg-amber-500\/25 {
  background-color: #f59e0b40;
}

.bg-amber-500\/30 {
  background-color: #f59e0b4d;
}

.bg-amber-500\/35 {
  background-color: #f59e0b59;
}

.bg-amber-500\/40 {
  background-color: #f59e0b66;
}

.bg-amber-500\/45 {
  background-color: #f59e0b73;
}

.bg-amber-500\/5 {
  background-color: #f59e0b0d;
}

.bg-amber-500\/50 {
  background-color: #f59e0b80;
}

.bg-amber-500\/55 {
  background-color: #f59e0b8c;
}

.bg-amber-500\/60 {
  background-color: #f59e0b99;
}

.bg-amber-500\/65 {
  background-color: #f59e0ba6;
}

.bg-amber-500\/70 {
  background-color: #f59e0bb3;
}

.bg-amber-500\/75 {
  background-color: #f59e0bbf;
}

.bg-amber-500\/80 {
  background-color: #f59e0bcc;
}

.bg-amber-500\/85 {
  background-color: #f59e0bd9;
}

.bg-amber-500\/90 {
  background-color: #f59e0be6;
}

.bg-amber-500\/95 {
  background-color: #f59e0bf2;
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity, 1));
}

.bg-amber-800\/0 {
  background-color: #92400e00;
}

.bg-amber-800\/10 {
  background-color: #92400e1a;
}

.bg-amber-800\/100 {
  background-color: #92400e;
}

.bg-amber-800\/15 {
  background-color: #92400e26;
}

.bg-amber-800\/20 {
  background-color: #92400e33;
}

.bg-amber-800\/25 {
  background-color: #92400e40;
}

.bg-amber-800\/30 {
  background-color: #92400e4d;
}

.bg-amber-800\/35 {
  background-color: #92400e59;
}

.bg-amber-800\/40 {
  background-color: #92400e66;
}

.bg-amber-800\/45 {
  background-color: #92400e73;
}

.bg-amber-800\/5 {
  background-color: #92400e0d;
}

.bg-amber-800\/50 {
  background-color: #92400e80;
}

.bg-amber-800\/55 {
  background-color: #92400e8c;
}

.bg-amber-800\/60 {
  background-color: #92400e99;
}

.bg-amber-800\/65 {
  background-color: #92400ea6;
}

.bg-amber-800\/70 {
  background-color: #92400eb3;
}

.bg-amber-800\/75 {
  background-color: #92400ebf;
}

.bg-amber-800\/80 {
  background-color: #92400ecc;
}

.bg-amber-800\/85 {
  background-color: #92400ed9;
}

.bg-amber-800\/90 {
  background-color: #92400ee6;
}

.bg-amber-800\/95 {
  background-color: #92400ef2;
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity, 1));
}

.bg-amber-900\/0 {
  background-color: #78350f00;
}

.bg-amber-900\/10 {
  background-color: #78350f1a;
}

.bg-amber-900\/100 {
  background-color: #78350f;
}

.bg-amber-900\/15 {
  background-color: #78350f26;
}

.bg-amber-900\/20 {
  background-color: #78350f33;
}

.bg-amber-900\/25 {
  background-color: #78350f40;
}

.bg-amber-900\/30 {
  background-color: #78350f4d;
}

.bg-amber-900\/35 {
  background-color: #78350f59;
}

.bg-amber-900\/40 {
  background-color: #78350f66;
}

.bg-amber-900\/45 {
  background-color: #78350f73;
}

.bg-amber-900\/5 {
  background-color: #78350f0d;
}

.bg-amber-900\/50 {
  background-color: #78350f80;
}

.bg-amber-900\/55 {
  background-color: #78350f8c;
}

.bg-amber-900\/60 {
  background-color: #78350f99;
}

.bg-amber-900\/65 {
  background-color: #78350fa6;
}

.bg-amber-900\/70 {
  background-color: #78350fb3;
}

.bg-amber-900\/75 {
  background-color: #78350fbf;
}

.bg-amber-900\/80 {
  background-color: #78350fcc;
}

.bg-amber-900\/85 {
  background-color: #78350fd9;
}

.bg-amber-900\/90 {
  background-color: #78350fe6;
}

.bg-amber-900\/95 {
  background-color: #78350ff2;
}

.bg-black\/30 {
  background-color: #0000004d;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-100\/0 {
  background-color: #dbeafe00;
}

.bg-blue-100\/10 {
  background-color: #dbeafe1a;
}

.bg-blue-100\/100 {
  background-color: #dbeafe;
}

.bg-blue-100\/15 {
  background-color: #dbeafe26;
}

.bg-blue-100\/20 {
  background-color: #dbeafe33;
}

.bg-blue-100\/25 {
  background-color: #dbeafe40;
}

.bg-blue-100\/30 {
  background-color: #dbeafe4d;
}

.bg-blue-100\/35 {
  background-color: #dbeafe59;
}

.bg-blue-100\/40 {
  background-color: #dbeafe66;
}

.bg-blue-100\/45 {
  background-color: #dbeafe73;
}

.bg-blue-100\/5 {
  background-color: #dbeafe0d;
}

.bg-blue-100\/50 {
  background-color: #dbeafe80;
}

.bg-blue-100\/55 {
  background-color: #dbeafe8c;
}

.bg-blue-100\/60 {
  background-color: #dbeafe99;
}

.bg-blue-100\/65 {
  background-color: #dbeafea6;
}

.bg-blue-100\/70 {
  background-color: #dbeafeb3;
}

.bg-blue-100\/75 {
  background-color: #dbeafebf;
}

.bg-blue-100\/80 {
  background-color: #dbeafecc;
}

.bg-blue-100\/85 {
  background-color: #dbeafed9;
}

.bg-blue-100\/90 {
  background-color: #dbeafee6;
}

.bg-blue-100\/95 {
  background-color: #dbeafef2;
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

.bg-blue-200\/0 {
  background-color: #bfdbfe00;
}

.bg-blue-200\/10 {
  background-color: #bfdbfe1a;
}

.bg-blue-200\/100 {
  background-color: #bfdbfe;
}

.bg-blue-200\/15 {
  background-color: #bfdbfe26;
}

.bg-blue-200\/20 {
  background-color: #bfdbfe33;
}

.bg-blue-200\/25 {
  background-color: #bfdbfe40;
}

.bg-blue-200\/30 {
  background-color: #bfdbfe4d;
}

.bg-blue-200\/35 {
  background-color: #bfdbfe59;
}

.bg-blue-200\/40 {
  background-color: #bfdbfe66;
}

.bg-blue-200\/45 {
  background-color: #bfdbfe73;
}

.bg-blue-200\/5 {
  background-color: #bfdbfe0d;
}

.bg-blue-200\/50 {
  background-color: #bfdbfe80;
}

.bg-blue-200\/55 {
  background-color: #bfdbfe8c;
}

.bg-blue-200\/60 {
  background-color: #bfdbfe99;
}

.bg-blue-200\/65 {
  background-color: #bfdbfea6;
}

.bg-blue-200\/70 {
  background-color: #bfdbfeb3;
}

.bg-blue-200\/75 {
  background-color: #bfdbfebf;
}

.bg-blue-200\/80 {
  background-color: #bfdbfecc;
}

.bg-blue-200\/85 {
  background-color: #bfdbfed9;
}

.bg-blue-200\/90 {
  background-color: #bfdbfee6;
}

.bg-blue-200\/95 {
  background-color: #bfdbfef2;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}

.bg-blue-500\/0 {
  background-color: #3b82f600;
}

.bg-blue-500\/10 {
  background-color: #3b82f61a;
}

.bg-blue-500\/100 {
  background-color: #3b82f6;
}

.bg-blue-500\/15 {
  background-color: #3b82f626;
}

.bg-blue-500\/20 {
  background-color: #3b82f633;
}

.bg-blue-500\/25 {
  background-color: #3b82f640;
}

.bg-blue-500\/30 {
  background-color: #3b82f64d;
}

.bg-blue-500\/35 {
  background-color: #3b82f659;
}

.bg-blue-500\/40 {
  background-color: #3b82f666;
}

.bg-blue-500\/45 {
  background-color: #3b82f673;
}

.bg-blue-500\/5 {
  background-color: #3b82f60d;
}

.bg-blue-500\/50 {
  background-color: #3b82f680;
}

.bg-blue-500\/55 {
  background-color: #3b82f68c;
}

.bg-blue-500\/60 {
  background-color: #3b82f699;
}

.bg-blue-500\/65 {
  background-color: #3b82f6a6;
}

.bg-blue-500\/70 {
  background-color: #3b82f6b3;
}

.bg-blue-500\/75 {
  background-color: #3b82f6bf;
}

.bg-blue-500\/80 {
  background-color: #3b82f6cc;
}

.bg-blue-500\/85 {
  background-color: #3b82f6d9;
}

.bg-blue-500\/90 {
  background-color: #3b82f6e6;
}

.bg-blue-500\/95 {
  background-color: #3b82f6f2;
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity, 1));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1));
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity, 1));
}

.bg-blue-800\/0 {
  background-color: #1e40af00;
}

.bg-blue-800\/10 {
  background-color: #1e40af1a;
}

.bg-blue-800\/100 {
  background-color: #1e40af;
}

.bg-blue-800\/15 {
  background-color: #1e40af26;
}

.bg-blue-800\/20 {
  background-color: #1e40af33;
}

.bg-blue-800\/25 {
  background-color: #1e40af40;
}

.bg-blue-800\/30 {
  background-color: #1e40af4d;
}

.bg-blue-800\/35 {
  background-color: #1e40af59;
}

.bg-blue-800\/40 {
  background-color: #1e40af66;
}

.bg-blue-800\/45 {
  background-color: #1e40af73;
}

.bg-blue-800\/5 {
  background-color: #1e40af0d;
}

.bg-blue-800\/50 {
  background-color: #1e40af80;
}

.bg-blue-800\/55 {
  background-color: #1e40af8c;
}

.bg-blue-800\/60 {
  background-color: #1e40af99;
}

.bg-blue-800\/65 {
  background-color: #1e40afa6;
}

.bg-blue-800\/70 {
  background-color: #1e40afb3;
}

.bg-blue-800\/75 {
  background-color: #1e40afbf;
}

.bg-blue-800\/80 {
  background-color: #1e40afcc;
}

.bg-blue-800\/85 {
  background-color: #1e40afd9;
}

.bg-blue-800\/90 {
  background-color: #1e40afe6;
}

.bg-blue-800\/95 {
  background-color: #1e40aff2;
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1));
}

.bg-blue-900\/0 {
  background-color: #1e3a8a00;
}

.bg-blue-900\/10 {
  background-color: #1e3a8a1a;
}

.bg-blue-900\/100 {
  background-color: #1e3a8a;
}

.bg-blue-900\/15 {
  background-color: #1e3a8a26;
}

.bg-blue-900\/20 {
  background-color: #1e3a8a33;
}

.bg-blue-900\/25 {
  background-color: #1e3a8a40;
}

.bg-blue-900\/30 {
  background-color: #1e3a8a4d;
}

.bg-blue-900\/35 {
  background-color: #1e3a8a59;
}

.bg-blue-900\/40 {
  background-color: #1e3a8a66;
}

.bg-blue-900\/45 {
  background-color: #1e3a8a73;
}

.bg-blue-900\/5 {
  background-color: #1e3a8a0d;
}

.bg-blue-900\/50 {
  background-color: #1e3a8a80;
}

.bg-blue-900\/55 {
  background-color: #1e3a8a8c;
}

.bg-blue-900\/60 {
  background-color: #1e3a8a99;
}

.bg-blue-900\/65 {
  background-color: #1e3a8aa6;
}

.bg-blue-900\/70 {
  background-color: #1e3a8ab3;
}

.bg-blue-900\/75 {
  background-color: #1e3a8abf;
}

.bg-blue-900\/80 {
  background-color: #1e3a8acc;
}

.bg-blue-900\/85 {
  background-color: #1e3a8ad9;
}

.bg-blue-900\/90 {
  background-color: #1e3a8ae6;
}

.bg-blue-900\/95 {
  background-color: #1e3a8af2;
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity, 1));
}

.bg-cyan-100\/0 {
  background-color: #cffafe00;
}

.bg-cyan-100\/10 {
  background-color: #cffafe1a;
}

.bg-cyan-100\/100 {
  background-color: #cffafe;
}

.bg-cyan-100\/15 {
  background-color: #cffafe26;
}

.bg-cyan-100\/20 {
  background-color: #cffafe33;
}

.bg-cyan-100\/25 {
  background-color: #cffafe40;
}

.bg-cyan-100\/30 {
  background-color: #cffafe4d;
}

.bg-cyan-100\/35 {
  background-color: #cffafe59;
}

.bg-cyan-100\/40 {
  background-color: #cffafe66;
}

.bg-cyan-100\/45 {
  background-color: #cffafe73;
}

.bg-cyan-100\/5 {
  background-color: #cffafe0d;
}

.bg-cyan-100\/50 {
  background-color: #cffafe80;
}

.bg-cyan-100\/55 {
  background-color: #cffafe8c;
}

.bg-cyan-100\/60 {
  background-color: #cffafe99;
}

.bg-cyan-100\/65 {
  background-color: #cffafea6;
}

.bg-cyan-100\/70 {
  background-color: #cffafeb3;
}

.bg-cyan-100\/75 {
  background-color: #cffafebf;
}

.bg-cyan-100\/80 {
  background-color: #cffafecc;
}

.bg-cyan-100\/85 {
  background-color: #cffafed9;
}

.bg-cyan-100\/90 {
  background-color: #cffafee6;
}

.bg-cyan-100\/95 {
  background-color: #cffafef2;
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity, 1));
}

.bg-cyan-200\/0 {
  background-color: #a5f3fc00;
}

.bg-cyan-200\/10 {
  background-color: #a5f3fc1a;
}

.bg-cyan-200\/100 {
  background-color: #a5f3fc;
}

.bg-cyan-200\/15 {
  background-color: #a5f3fc26;
}

.bg-cyan-200\/20 {
  background-color: #a5f3fc33;
}

.bg-cyan-200\/25 {
  background-color: #a5f3fc40;
}

.bg-cyan-200\/30 {
  background-color: #a5f3fc4d;
}

.bg-cyan-200\/35 {
  background-color: #a5f3fc59;
}

.bg-cyan-200\/40 {
  background-color: #a5f3fc66;
}

.bg-cyan-200\/45 {
  background-color: #a5f3fc73;
}

.bg-cyan-200\/5 {
  background-color: #a5f3fc0d;
}

.bg-cyan-200\/50 {
  background-color: #a5f3fc80;
}

.bg-cyan-200\/55 {
  background-color: #a5f3fc8c;
}

.bg-cyan-200\/60 {
  background-color: #a5f3fc99;
}

.bg-cyan-200\/65 {
  background-color: #a5f3fca6;
}

.bg-cyan-200\/70 {
  background-color: #a5f3fcb3;
}

.bg-cyan-200\/75 {
  background-color: #a5f3fcbf;
}

.bg-cyan-200\/80 {
  background-color: #a5f3fccc;
}

.bg-cyan-200\/85 {
  background-color: #a5f3fcd9;
}

.bg-cyan-200\/90 {
  background-color: #a5f3fce6;
}

.bg-cyan-200\/95 {
  background-color: #a5f3fcf2;
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity, 1));
}

.bg-cyan-500\/0 {
  background-color: #06b6d400;
}

.bg-cyan-500\/10 {
  background-color: #06b6d41a;
}

.bg-cyan-500\/100 {
  background-color: #06b6d4;
}

.bg-cyan-500\/15 {
  background-color: #06b6d426;
}

.bg-cyan-500\/20 {
  background-color: #06b6d433;
}

.bg-cyan-500\/25 {
  background-color: #06b6d440;
}

.bg-cyan-500\/30 {
  background-color: #06b6d44d;
}

.bg-cyan-500\/35 {
  background-color: #06b6d459;
}

.bg-cyan-500\/40 {
  background-color: #06b6d466;
}

.bg-cyan-500\/45 {
  background-color: #06b6d473;
}

.bg-cyan-500\/5 {
  background-color: #06b6d40d;
}

.bg-cyan-500\/50 {
  background-color: #06b6d480;
}

.bg-cyan-500\/55 {
  background-color: #06b6d48c;
}

.bg-cyan-500\/60 {
  background-color: #06b6d499;
}

.bg-cyan-500\/65 {
  background-color: #06b6d4a6;
}

.bg-cyan-500\/70 {
  background-color: #06b6d4b3;
}

.bg-cyan-500\/75 {
  background-color: #06b6d4bf;
}

.bg-cyan-500\/80 {
  background-color: #06b6d4cc;
}

.bg-cyan-500\/85 {
  background-color: #06b6d4d9;
}

.bg-cyan-500\/90 {
  background-color: #06b6d4e6;
}

.bg-cyan-500\/95 {
  background-color: #06b6d4f2;
}

.bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity, 1));
}

.bg-cyan-800\/0 {
  background-color: #155e7500;
}

.bg-cyan-800\/10 {
  background-color: #155e751a;
}

.bg-cyan-800\/100 {
  background-color: #155e75;
}

.bg-cyan-800\/15 {
  background-color: #155e7526;
}

.bg-cyan-800\/20 {
  background-color: #155e7533;
}

.bg-cyan-800\/25 {
  background-color: #155e7540;
}

.bg-cyan-800\/30 {
  background-color: #155e754d;
}

.bg-cyan-800\/35 {
  background-color: #155e7559;
}

.bg-cyan-800\/40 {
  background-color: #155e7566;
}

.bg-cyan-800\/45 {
  background-color: #155e7573;
}

.bg-cyan-800\/5 {
  background-color: #155e750d;
}

.bg-cyan-800\/50 {
  background-color: #155e7580;
}

.bg-cyan-800\/55 {
  background-color: #155e758c;
}

.bg-cyan-800\/60 {
  background-color: #155e7599;
}

.bg-cyan-800\/65 {
  background-color: #155e75a6;
}

.bg-cyan-800\/70 {
  background-color: #155e75b3;
}

.bg-cyan-800\/75 {
  background-color: #155e75bf;
}

.bg-cyan-800\/80 {
  background-color: #155e75cc;
}

.bg-cyan-800\/85 {
  background-color: #155e75d9;
}

.bg-cyan-800\/90 {
  background-color: #155e75e6;
}

.bg-cyan-800\/95 {
  background-color: #155e75f2;
}

.bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity, 1));
}

.bg-cyan-900\/0 {
  background-color: #164e6300;
}

.bg-cyan-900\/10 {
  background-color: #164e631a;
}

.bg-cyan-900\/100 {
  background-color: #164e63;
}

.bg-cyan-900\/15 {
  background-color: #164e6326;
}

.bg-cyan-900\/20 {
  background-color: #164e6333;
}

.bg-cyan-900\/25 {
  background-color: #164e6340;
}

.bg-cyan-900\/30 {
  background-color: #164e634d;
}

.bg-cyan-900\/35 {
  background-color: #164e6359;
}

.bg-cyan-900\/40 {
  background-color: #164e6366;
}

.bg-cyan-900\/45 {
  background-color: #164e6373;
}

.bg-cyan-900\/5 {
  background-color: #164e630d;
}

.bg-cyan-900\/50 {
  background-color: #164e6380;
}

.bg-cyan-900\/55 {
  background-color: #164e638c;
}

.bg-cyan-900\/60 {
  background-color: #164e6399;
}

.bg-cyan-900\/65 {
  background-color: #164e63a6;
}

.bg-cyan-900\/70 {
  background-color: #164e63b3;
}

.bg-cyan-900\/75 {
  background-color: #164e63bf;
}

.bg-cyan-900\/80 {
  background-color: #164e63cc;
}

.bg-cyan-900\/85 {
  background-color: #164e63d9;
}

.bg-cyan-900\/90 {
  background-color: #164e63e6;
}

.bg-cyan-900\/95 {
  background-color: #164e63f2;
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity, 1));
}

.bg-emerald-100\/0 {
  background-color: #d1fae500;
}

.bg-emerald-100\/10 {
  background-color: #d1fae51a;
}

.bg-emerald-100\/100 {
  background-color: #d1fae5;
}

.bg-emerald-100\/15 {
  background-color: #d1fae526;
}

.bg-emerald-100\/20 {
  background-color: #d1fae533;
}

.bg-emerald-100\/25 {
  background-color: #d1fae540;
}

.bg-emerald-100\/30 {
  background-color: #d1fae54d;
}

.bg-emerald-100\/35 {
  background-color: #d1fae559;
}

.bg-emerald-100\/40 {
  background-color: #d1fae566;
}

.bg-emerald-100\/45 {
  background-color: #d1fae573;
}

.bg-emerald-100\/5 {
  background-color: #d1fae50d;
}

.bg-emerald-100\/50 {
  background-color: #d1fae580;
}

.bg-emerald-100\/55 {
  background-color: #d1fae58c;
}

.bg-emerald-100\/60 {
  background-color: #d1fae599;
}

.bg-emerald-100\/65 {
  background-color: #d1fae5a6;
}

.bg-emerald-100\/70 {
  background-color: #d1fae5b3;
}

.bg-emerald-100\/75 {
  background-color: #d1fae5bf;
}

.bg-emerald-100\/80 {
  background-color: #d1fae5cc;
}

.bg-emerald-100\/85 {
  background-color: #d1fae5d9;
}

.bg-emerald-100\/90 {
  background-color: #d1fae5e6;
}

.bg-emerald-100\/95 {
  background-color: #d1fae5f2;
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity, 1));
}

.bg-emerald-200\/0 {
  background-color: #a7f3d000;
}

.bg-emerald-200\/10 {
  background-color: #a7f3d01a;
}

.bg-emerald-200\/100 {
  background-color: #a7f3d0;
}

.bg-emerald-200\/15 {
  background-color: #a7f3d026;
}

.bg-emerald-200\/20 {
  background-color: #a7f3d033;
}

.bg-emerald-200\/25 {
  background-color: #a7f3d040;
}

.bg-emerald-200\/30 {
  background-color: #a7f3d04d;
}

.bg-emerald-200\/35 {
  background-color: #a7f3d059;
}

.bg-emerald-200\/40 {
  background-color: #a7f3d066;
}

.bg-emerald-200\/45 {
  background-color: #a7f3d073;
}

.bg-emerald-200\/5 {
  background-color: #a7f3d00d;
}

.bg-emerald-200\/50 {
  background-color: #a7f3d080;
}

.bg-emerald-200\/55 {
  background-color: #a7f3d08c;
}

.bg-emerald-200\/60 {
  background-color: #a7f3d099;
}

.bg-emerald-200\/65 {
  background-color: #a7f3d0a6;
}

.bg-emerald-200\/70 {
  background-color: #a7f3d0b3;
}

.bg-emerald-200\/75 {
  background-color: #a7f3d0bf;
}

.bg-emerald-200\/80 {
  background-color: #a7f3d0cc;
}

.bg-emerald-200\/85 {
  background-color: #a7f3d0d9;
}

.bg-emerald-200\/90 {
  background-color: #a7f3d0e6;
}

.bg-emerald-200\/95 {
  background-color: #a7f3d0f2;
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity, 1));
}

.bg-emerald-500\/0 {
  background-color: #10b98100;
}

.bg-emerald-500\/10 {
  background-color: #10b9811a;
}

.bg-emerald-500\/100 {
  background-color: #10b981;
}

.bg-emerald-500\/15 {
  background-color: #10b98126;
}

.bg-emerald-500\/20 {
  background-color: #10b98133;
}

.bg-emerald-500\/25 {
  background-color: #10b98140;
}

.bg-emerald-500\/30 {
  background-color: #10b9814d;
}

.bg-emerald-500\/35 {
  background-color: #10b98159;
}

.bg-emerald-500\/40 {
  background-color: #10b98166;
}

.bg-emerald-500\/45 {
  background-color: #10b98173;
}

.bg-emerald-500\/5 {
  background-color: #10b9810d;
}

.bg-emerald-500\/50 {
  background-color: #10b98180;
}

.bg-emerald-500\/55 {
  background-color: #10b9818c;
}

.bg-emerald-500\/60 {
  background-color: #10b98199;
}

.bg-emerald-500\/65 {
  background-color: #10b981a6;
}

.bg-emerald-500\/70 {
  background-color: #10b981b3;
}

.bg-emerald-500\/75 {
  background-color: #10b981bf;
}

.bg-emerald-500\/80 {
  background-color: #10b981cc;
}

.bg-emerald-500\/85 {
  background-color: #10b981d9;
}

.bg-emerald-500\/90 {
  background-color: #10b981e6;
}

.bg-emerald-500\/95 {
  background-color: #10b981f2;
}

.bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity, 1));
}

.bg-emerald-800\/0 {
  background-color: #065f4600;
}

.bg-emerald-800\/10 {
  background-color: #065f461a;
}

.bg-emerald-800\/100 {
  background-color: #065f46;
}

.bg-emerald-800\/15 {
  background-color: #065f4626;
}

.bg-emerald-800\/20 {
  background-color: #065f4633;
}

.bg-emerald-800\/25 {
  background-color: #065f4640;
}

.bg-emerald-800\/30 {
  background-color: #065f464d;
}

.bg-emerald-800\/35 {
  background-color: #065f4659;
}

.bg-emerald-800\/40 {
  background-color: #065f4666;
}

.bg-emerald-800\/45 {
  background-color: #065f4673;
}

.bg-emerald-800\/5 {
  background-color: #065f460d;
}

.bg-emerald-800\/50 {
  background-color: #065f4680;
}

.bg-emerald-800\/55 {
  background-color: #065f468c;
}

.bg-emerald-800\/60 {
  background-color: #065f4699;
}

.bg-emerald-800\/65 {
  background-color: #065f46a6;
}

.bg-emerald-800\/70 {
  background-color: #065f46b3;
}

.bg-emerald-800\/75 {
  background-color: #065f46bf;
}

.bg-emerald-800\/80 {
  background-color: #065f46cc;
}

.bg-emerald-800\/85 {
  background-color: #065f46d9;
}

.bg-emerald-800\/90 {
  background-color: #065f46e6;
}

.bg-emerald-800\/95 {
  background-color: #065f46f2;
}

.bg-emerald-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity, 1));
}

.bg-emerald-900\/0 {
  background-color: #064e3b00;
}

.bg-emerald-900\/10 {
  background-color: #064e3b1a;
}

.bg-emerald-900\/100 {
  background-color: #064e3b;
}

.bg-emerald-900\/15 {
  background-color: #064e3b26;
}

.bg-emerald-900\/20 {
  background-color: #064e3b33;
}

.bg-emerald-900\/25 {
  background-color: #064e3b40;
}

.bg-emerald-900\/30 {
  background-color: #064e3b4d;
}

.bg-emerald-900\/35 {
  background-color: #064e3b59;
}

.bg-emerald-900\/40 {
  background-color: #064e3b66;
}

.bg-emerald-900\/45 {
  background-color: #064e3b73;
}

.bg-emerald-900\/5 {
  background-color: #064e3b0d;
}

.bg-emerald-900\/50 {
  background-color: #064e3b80;
}

.bg-emerald-900\/55 {
  background-color: #064e3b8c;
}

.bg-emerald-900\/60 {
  background-color: #064e3b99;
}

.bg-emerald-900\/65 {
  background-color: #064e3ba6;
}

.bg-emerald-900\/70 {
  background-color: #064e3bb3;
}

.bg-emerald-900\/75 {
  background-color: #064e3bbf;
}

.bg-emerald-900\/80 {
  background-color: #064e3bcc;
}

.bg-emerald-900\/85 {
  background-color: #064e3bd9;
}

.bg-emerald-900\/90 {
  background-color: #064e3be6;
}

.bg-emerald-900\/95 {
  background-color: #064e3bf2;
}

.bg-fuchsia-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity, 1));
}

.bg-fuchsia-100\/0 {
  background-color: #fae8ff00;
}

.bg-fuchsia-100\/10 {
  background-color: #fae8ff1a;
}

.bg-fuchsia-100\/100 {
  background-color: #fae8ff;
}

.bg-fuchsia-100\/15 {
  background-color: #fae8ff26;
}

.bg-fuchsia-100\/20 {
  background-color: #fae8ff33;
}

.bg-fuchsia-100\/25 {
  background-color: #fae8ff40;
}

.bg-fuchsia-100\/30 {
  background-color: #fae8ff4d;
}

.bg-fuchsia-100\/35 {
  background-color: #fae8ff59;
}

.bg-fuchsia-100\/40 {
  background-color: #fae8ff66;
}

.bg-fuchsia-100\/45 {
  background-color: #fae8ff73;
}

.bg-fuchsia-100\/5 {
  background-color: #fae8ff0d;
}

.bg-fuchsia-100\/50 {
  background-color: #fae8ff80;
}

.bg-fuchsia-100\/55 {
  background-color: #fae8ff8c;
}

.bg-fuchsia-100\/60 {
  background-color: #fae8ff99;
}

.bg-fuchsia-100\/65 {
  background-color: #fae8ffa6;
}

.bg-fuchsia-100\/70 {
  background-color: #fae8ffb3;
}

.bg-fuchsia-100\/75 {
  background-color: #fae8ffbf;
}

.bg-fuchsia-100\/80 {
  background-color: #fae8ffcc;
}

.bg-fuchsia-100\/85 {
  background-color: #fae8ffd9;
}

.bg-fuchsia-100\/90 {
  background-color: #fae8ffe6;
}

.bg-fuchsia-100\/95 {
  background-color: #fae8fff2;
}

.bg-fuchsia-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity, 1));
}

.bg-fuchsia-200\/0 {
  background-color: #f5d0fe00;
}

.bg-fuchsia-200\/10 {
  background-color: #f5d0fe1a;
}

.bg-fuchsia-200\/100 {
  background-color: #f5d0fe;
}

.bg-fuchsia-200\/15 {
  background-color: #f5d0fe26;
}

.bg-fuchsia-200\/20 {
  background-color: #f5d0fe33;
}

.bg-fuchsia-200\/25 {
  background-color: #f5d0fe40;
}

.bg-fuchsia-200\/30 {
  background-color: #f5d0fe4d;
}

.bg-fuchsia-200\/35 {
  background-color: #f5d0fe59;
}

.bg-fuchsia-200\/40 {
  background-color: #f5d0fe66;
}

.bg-fuchsia-200\/45 {
  background-color: #f5d0fe73;
}

.bg-fuchsia-200\/5 {
  background-color: #f5d0fe0d;
}

.bg-fuchsia-200\/50 {
  background-color: #f5d0fe80;
}

.bg-fuchsia-200\/55 {
  background-color: #f5d0fe8c;
}

.bg-fuchsia-200\/60 {
  background-color: #f5d0fe99;
}

.bg-fuchsia-200\/65 {
  background-color: #f5d0fea6;
}

.bg-fuchsia-200\/70 {
  background-color: #f5d0feb3;
}

.bg-fuchsia-200\/75 {
  background-color: #f5d0febf;
}

.bg-fuchsia-200\/80 {
  background-color: #f5d0fecc;
}

.bg-fuchsia-200\/85 {
  background-color: #f5d0fed9;
}

.bg-fuchsia-200\/90 {
  background-color: #f5d0fee6;
}

.bg-fuchsia-200\/95 {
  background-color: #f5d0fef2;
}

.bg-fuchsia-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity, 1));
}

.bg-fuchsia-500\/0 {
  background-color: #d946ef00;
}

.bg-fuchsia-500\/10 {
  background-color: #d946ef1a;
}

.bg-fuchsia-500\/100 {
  background-color: #d946ef;
}

.bg-fuchsia-500\/15 {
  background-color: #d946ef26;
}

.bg-fuchsia-500\/20 {
  background-color: #d946ef33;
}

.bg-fuchsia-500\/25 {
  background-color: #d946ef40;
}

.bg-fuchsia-500\/30 {
  background-color: #d946ef4d;
}

.bg-fuchsia-500\/35 {
  background-color: #d946ef59;
}

.bg-fuchsia-500\/40 {
  background-color: #d946ef66;
}

.bg-fuchsia-500\/45 {
  background-color: #d946ef73;
}

.bg-fuchsia-500\/5 {
  background-color: #d946ef0d;
}

.bg-fuchsia-500\/50 {
  background-color: #d946ef80;
}

.bg-fuchsia-500\/55 {
  background-color: #d946ef8c;
}

.bg-fuchsia-500\/60 {
  background-color: #d946ef99;
}

.bg-fuchsia-500\/65 {
  background-color: #d946efa6;
}

.bg-fuchsia-500\/70 {
  background-color: #d946efb3;
}

.bg-fuchsia-500\/75 {
  background-color: #d946efbf;
}

.bg-fuchsia-500\/80 {
  background-color: #d946efcc;
}

.bg-fuchsia-500\/85 {
  background-color: #d946efd9;
}

.bg-fuchsia-500\/90 {
  background-color: #d946efe6;
}

.bg-fuchsia-500\/95 {
  background-color: #d946eff2;
}

.bg-fuchsia-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 25 143 / var(--tw-bg-opacity, 1));
}

.bg-fuchsia-800\/0 {
  background-color: #86198f00;
}

.bg-fuchsia-800\/10 {
  background-color: #86198f1a;
}

.bg-fuchsia-800\/100 {
  background-color: #86198f;
}

.bg-fuchsia-800\/15 {
  background-color: #86198f26;
}

.bg-fuchsia-800\/20 {
  background-color: #86198f33;
}

.bg-fuchsia-800\/25 {
  background-color: #86198f40;
}

.bg-fuchsia-800\/30 {
  background-color: #86198f4d;
}

.bg-fuchsia-800\/35 {
  background-color: #86198f59;
}

.bg-fuchsia-800\/40 {
  background-color: #86198f66;
}

.bg-fuchsia-800\/45 {
  background-color: #86198f73;
}

.bg-fuchsia-800\/5 {
  background-color: #86198f0d;
}

.bg-fuchsia-800\/50 {
  background-color: #86198f80;
}

.bg-fuchsia-800\/55 {
  background-color: #86198f8c;
}

.bg-fuchsia-800\/60 {
  background-color: #86198f99;
}

.bg-fuchsia-800\/65 {
  background-color: #86198fa6;
}

.bg-fuchsia-800\/70 {
  background-color: #86198fb3;
}

.bg-fuchsia-800\/75 {
  background-color: #86198fbf;
}

.bg-fuchsia-800\/80 {
  background-color: #86198fcc;
}

.bg-fuchsia-800\/85 {
  background-color: #86198fd9;
}

.bg-fuchsia-800\/90 {
  background-color: #86198fe6;
}

.bg-fuchsia-800\/95 {
  background-color: #86198ff2;
}

.bg-fuchsia-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity, 1));
}

.bg-fuchsia-900\/0 {
  background-color: #701a7500;
}

.bg-fuchsia-900\/10 {
  background-color: #701a751a;
}

.bg-fuchsia-900\/100 {
  background-color: #701a75;
}

.bg-fuchsia-900\/15 {
  background-color: #701a7526;
}

.bg-fuchsia-900\/20 {
  background-color: #701a7533;
}

.bg-fuchsia-900\/25 {
  background-color: #701a7540;
}

.bg-fuchsia-900\/30 {
  background-color: #701a754d;
}

.bg-fuchsia-900\/35 {
  background-color: #701a7559;
}

.bg-fuchsia-900\/40 {
  background-color: #701a7566;
}

.bg-fuchsia-900\/45 {
  background-color: #701a7573;
}

.bg-fuchsia-900\/5 {
  background-color: #701a750d;
}

.bg-fuchsia-900\/50 {
  background-color: #701a7580;
}

.bg-fuchsia-900\/55 {
  background-color: #701a758c;
}

.bg-fuchsia-900\/60 {
  background-color: #701a7599;
}

.bg-fuchsia-900\/65 {
  background-color: #701a75a6;
}

.bg-fuchsia-900\/70 {
  background-color: #701a75b3;
}

.bg-fuchsia-900\/75 {
  background-color: #701a75bf;
}

.bg-fuchsia-900\/80 {
  background-color: #701a75cc;
}

.bg-fuchsia-900\/85 {
  background-color: #701a75d9;
}

.bg-fuchsia-900\/90 {
  background-color: #701a75e6;
}

.bg-fuchsia-900\/95 {
  background-color: #701a75f2;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-gray-100\/0 {
  background-color: #f3f4f600;
}

.bg-gray-100\/10 {
  background-color: #f3f4f61a;
}

.bg-gray-100\/100 {
  background-color: #f3f4f6;
}

.bg-gray-100\/15 {
  background-color: #f3f4f626;
}

.bg-gray-100\/20 {
  background-color: #f3f4f633;
}

.bg-gray-100\/25 {
  background-color: #f3f4f640;
}

.bg-gray-100\/30 {
  background-color: #f3f4f64d;
}

.bg-gray-100\/35 {
  background-color: #f3f4f659;
}

.bg-gray-100\/40 {
  background-color: #f3f4f666;
}

.bg-gray-100\/45 {
  background-color: #f3f4f673;
}

.bg-gray-100\/5 {
  background-color: #f3f4f60d;
}

.bg-gray-100\/50 {
  background-color: #f3f4f680;
}

.bg-gray-100\/55 {
  background-color: #f3f4f68c;
}

.bg-gray-100\/60 {
  background-color: #f3f4f699;
}

.bg-gray-100\/65 {
  background-color: #f3f4f6a6;
}

.bg-gray-100\/70 {
  background-color: #f3f4f6b3;
}

.bg-gray-100\/75 {
  background-color: #f3f4f6bf;
}

.bg-gray-100\/80 {
  background-color: #f3f4f6cc;
}

.bg-gray-100\/85 {
  background-color: #f3f4f6d9;
}

.bg-gray-100\/90 {
  background-color: #f3f4f6e6;
}

.bg-gray-100\/95 {
  background-color: #f3f4f6f2;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-200\/0 {
  background-color: #e5e7eb00;
}

.bg-gray-200\/10 {
  background-color: #e5e7eb1a;
}

.bg-gray-200\/100 {
  background-color: #e5e7eb;
}

.bg-gray-200\/15 {
  background-color: #e5e7eb26;
}

.bg-gray-200\/20 {
  background-color: #e5e7eb33;
}

.bg-gray-200\/25 {
  background-color: #e5e7eb40;
}

.bg-gray-200\/30 {
  background-color: #e5e7eb4d;
}

.bg-gray-200\/35 {
  background-color: #e5e7eb59;
}

.bg-gray-200\/40 {
  background-color: #e5e7eb66;
}

.bg-gray-200\/45 {
  background-color: #e5e7eb73;
}

.bg-gray-200\/5 {
  background-color: #e5e7eb0d;
}

.bg-gray-200\/50 {
  background-color: #e5e7eb80;
}

.bg-gray-200\/55 {
  background-color: #e5e7eb8c;
}

.bg-gray-200\/60 {
  background-color: #e5e7eb99;
}

.bg-gray-200\/65 {
  background-color: #e5e7eba6;
}

.bg-gray-200\/70 {
  background-color: #e5e7ebb3;
}

.bg-gray-200\/75 {
  background-color: #e5e7ebbf;
}

.bg-gray-200\/80 {
  background-color: #e5e7ebcc;
}

.bg-gray-200\/85 {
  background-color: #e5e7ebd9;
}

.bg-gray-200\/90 {
  background-color: #e5e7ebe6;
}

.bg-gray-200\/95 {
  background-color: #e5e7ebf2;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.bg-gray-500\/0 {
  background-color: #6b728000;
}

.bg-gray-500\/10 {
  background-color: #6b72801a;
}

.bg-gray-500\/100 {
  background-color: #6b7280;
}

.bg-gray-500\/15 {
  background-color: #6b728026;
}

.bg-gray-500\/20 {
  background-color: #6b728033;
}

.bg-gray-500\/25 {
  background-color: #6b728040;
}

.bg-gray-500\/30 {
  background-color: #6b72804d;
}

.bg-gray-500\/35 {
  background-color: #6b728059;
}

.bg-gray-500\/40 {
  background-color: #6b728066;
}

.bg-gray-500\/45 {
  background-color: #6b728073;
}

.bg-gray-500\/5 {
  background-color: #6b72800d;
}

.bg-gray-500\/50 {
  background-color: #6b728080;
}

.bg-gray-500\/55 {
  background-color: #6b72808c;
}

.bg-gray-500\/60 {
  background-color: #6b728099;
}

.bg-gray-500\/65 {
  background-color: #6b7280a6;
}

.bg-gray-500\/70 {
  background-color: #6b7280b3;
}

.bg-gray-500\/75 {
  background-color: #6b7280bf;
}

.bg-gray-500\/80 {
  background-color: #6b7280cc;
}

.bg-gray-500\/85 {
  background-color: #6b7280d9;
}

.bg-gray-500\/90 {
  background-color: #6b7280e6;
}

.bg-gray-500\/95 {
  background-color: #6b7280f2;
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.bg-gray-800\/0 {
  background-color: #1f293700;
}

.bg-gray-800\/10 {
  background-color: #1f29371a;
}

.bg-gray-800\/100 {
  background-color: #1f2937;
}

.bg-gray-800\/15 {
  background-color: #1f293726;
}

.bg-gray-800\/20 {
  background-color: #1f293733;
}

.bg-gray-800\/25 {
  background-color: #1f293740;
}

.bg-gray-800\/30 {
  background-color: #1f29374d;
}

.bg-gray-800\/35 {
  background-color: #1f293759;
}

.bg-gray-800\/40 {
  background-color: #1f293766;
}

.bg-gray-800\/45 {
  background-color: #1f293773;
}

.bg-gray-800\/5 {
  background-color: #1f29370d;
}

.bg-gray-800\/50 {
  background-color: #1f293780;
}

.bg-gray-800\/55 {
  background-color: #1f29378c;
}

.bg-gray-800\/60 {
  background-color: #1f293799;
}

.bg-gray-800\/65 {
  background-color: #1f2937a6;
}

.bg-gray-800\/70 {
  background-color: #1f2937b3;
}

.bg-gray-800\/75 {
  background-color: #1f2937bf;
}

.bg-gray-800\/80 {
  background-color: #1f2937cc;
}

.bg-gray-800\/85 {
  background-color: #1f2937d9;
}

.bg-gray-800\/90 {
  background-color: #1f2937e6;
}

.bg-gray-800\/95 {
  background-color: #1f2937f2;
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.bg-gray-900\/0 {
  background-color: #11182700;
}

.bg-gray-900\/10 {
  background-color: #1118271a;
}

.bg-gray-900\/100 {
  background-color: #111827;
}

.bg-gray-900\/15 {
  background-color: #11182726;
}

.bg-gray-900\/20 {
  background-color: #11182733;
}

.bg-gray-900\/25 {
  background-color: #11182740;
}

.bg-gray-900\/30 {
  background-color: #1118274d;
}

.bg-gray-900\/35 {
  background-color: #11182759;
}

.bg-gray-900\/40 {
  background-color: #11182766;
}

.bg-gray-900\/45 {
  background-color: #11182773;
}

.bg-gray-900\/5 {
  background-color: #1118270d;
}

.bg-gray-900\/50 {
  background-color: #11182780;
}

.bg-gray-900\/55 {
  background-color: #1118278c;
}

.bg-gray-900\/60 {
  background-color: #11182799;
}

.bg-gray-900\/65 {
  background-color: #111827a6;
}

.bg-gray-900\/70 {
  background-color: #111827b3;
}

.bg-gray-900\/75 {
  background-color: #111827bf;
}

.bg-gray-900\/80 {
  background-color: #111827cc;
}

.bg-gray-900\/85 {
  background-color: #111827d9;
}

.bg-gray-900\/90 {
  background-color: #111827e6;
}

.bg-gray-900\/95 {
  background-color: #111827f2;
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity, 1));
}

.bg-green-100\/0 {
  background-color: #dcfce700;
}

.bg-green-100\/10 {
  background-color: #dcfce71a;
}

.bg-green-100\/100 {
  background-color: #dcfce7;
}

.bg-green-100\/15 {
  background-color: #dcfce726;
}

.bg-green-100\/20 {
  background-color: #dcfce733;
}

.bg-green-100\/25 {
  background-color: #dcfce740;
}

.bg-green-100\/30 {
  background-color: #dcfce74d;
}

.bg-green-100\/35 {
  background-color: #dcfce759;
}

.bg-green-100\/40 {
  background-color: #dcfce766;
}

.bg-green-100\/45 {
  background-color: #dcfce773;
}

.bg-green-100\/5 {
  background-color: #dcfce70d;
}

.bg-green-100\/50 {
  background-color: #dcfce780;
}

.bg-green-100\/55 {
  background-color: #dcfce78c;
}

.bg-green-100\/60 {
  background-color: #dcfce799;
}

.bg-green-100\/65 {
  background-color: #dcfce7a6;
}

.bg-green-100\/70 {
  background-color: #dcfce7b3;
}

.bg-green-100\/75 {
  background-color: #dcfce7bf;
}

.bg-green-100\/80 {
  background-color: #dcfce7cc;
}

.bg-green-100\/85 {
  background-color: #dcfce7d9;
}

.bg-green-100\/90 {
  background-color: #dcfce7e6;
}

.bg-green-100\/95 {
  background-color: #dcfce7f2;
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity, 1));
}

.bg-green-200\/0 {
  background-color: #bbf7d000;
}

.bg-green-200\/10 {
  background-color: #bbf7d01a;
}

.bg-green-200\/100 {
  background-color: #bbf7d0;
}

.bg-green-200\/15 {
  background-color: #bbf7d026;
}

.bg-green-200\/20 {
  background-color: #bbf7d033;
}

.bg-green-200\/25 {
  background-color: #bbf7d040;
}

.bg-green-200\/30 {
  background-color: #bbf7d04d;
}

.bg-green-200\/35 {
  background-color: #bbf7d059;
}

.bg-green-200\/40 {
  background-color: #bbf7d066;
}

.bg-green-200\/45 {
  background-color: #bbf7d073;
}

.bg-green-200\/5 {
  background-color: #bbf7d00d;
}

.bg-green-200\/50 {
  background-color: #bbf7d080;
}

.bg-green-200\/55 {
  background-color: #bbf7d08c;
}

.bg-green-200\/60 {
  background-color: #bbf7d099;
}

.bg-green-200\/65 {
  background-color: #bbf7d0a6;
}

.bg-green-200\/70 {
  background-color: #bbf7d0b3;
}

.bg-green-200\/75 {
  background-color: #bbf7d0bf;
}

.bg-green-200\/80 {
  background-color: #bbf7d0cc;
}

.bg-green-200\/85 {
  background-color: #bbf7d0d9;
}

.bg-green-200\/90 {
  background-color: #bbf7d0e6;
}

.bg-green-200\/95 {
  background-color: #bbf7d0f2;
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}

.bg-green-500\/0 {
  background-color: #22c55e00;
}

.bg-green-500\/10 {
  background-color: #22c55e1a;
}

.bg-green-500\/100 {
  background-color: #22c55e;
}

.bg-green-500\/15 {
  background-color: #22c55e26;
}

.bg-green-500\/20 {
  background-color: #22c55e33;
}

.bg-green-500\/25 {
  background-color: #22c55e40;
}

.bg-green-500\/30 {
  background-color: #22c55e4d;
}

.bg-green-500\/35 {
  background-color: #22c55e59;
}

.bg-green-500\/40 {
  background-color: #22c55e66;
}

.bg-green-500\/45 {
  background-color: #22c55e73;
}

.bg-green-500\/5 {
  background-color: #22c55e0d;
}

.bg-green-500\/50 {
  background-color: #22c55e80;
}

.bg-green-500\/55 {
  background-color: #22c55e8c;
}

.bg-green-500\/60 {
  background-color: #22c55e99;
}

.bg-green-500\/65 {
  background-color: #22c55ea6;
}

.bg-green-500\/70 {
  background-color: #22c55eb3;
}

.bg-green-500\/75 {
  background-color: #22c55ebf;
}

.bg-green-500\/80 {
  background-color: #22c55ecc;
}

.bg-green-500\/85 {
  background-color: #22c55ed9;
}

.bg-green-500\/90 {
  background-color: #22c55ee6;
}

.bg-green-500\/95 {
  background-color: #22c55ef2;
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity, 1));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity, 1));
}

.bg-green-800\/0 {
  background-color: #16653400;
}

.bg-green-800\/10 {
  background-color: #1665341a;
}

.bg-green-800\/100 {
  background-color: #166534;
}

.bg-green-800\/15 {
  background-color: #16653426;
}

.bg-green-800\/20 {
  background-color: #16653433;
}

.bg-green-800\/25 {
  background-color: #16653440;
}

.bg-green-800\/30 {
  background-color: #1665344d;
}

.bg-green-800\/35 {
  background-color: #16653459;
}

.bg-green-800\/40 {
  background-color: #16653466;
}

.bg-green-800\/45 {
  background-color: #16653473;
}

.bg-green-800\/5 {
  background-color: #1665340d;
}

.bg-green-800\/50 {
  background-color: #16653480;
}

.bg-green-800\/55 {
  background-color: #1665348c;
}

.bg-green-800\/60 {
  background-color: #16653499;
}

.bg-green-800\/65 {
  background-color: #166534a6;
}

.bg-green-800\/70 {
  background-color: #166534b3;
}

.bg-green-800\/75 {
  background-color: #166534bf;
}

.bg-green-800\/80 {
  background-color: #166534cc;
}

.bg-green-800\/85 {
  background-color: #166534d9;
}

.bg-green-800\/90 {
  background-color: #166534e6;
}

.bg-green-800\/95 {
  background-color: #166534f2;
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity, 1));
}

.bg-green-900\/0 {
  background-color: #14532d00;
}

.bg-green-900\/10 {
  background-color: #14532d1a;
}

.bg-green-900\/100 {
  background-color: #14532d;
}

.bg-green-900\/15 {
  background-color: #14532d26;
}

.bg-green-900\/20 {
  background-color: #14532d33;
}

.bg-green-900\/25 {
  background-color: #14532d40;
}

.bg-green-900\/30 {
  background-color: #14532d4d;
}

.bg-green-900\/35 {
  background-color: #14532d59;
}

.bg-green-900\/40 {
  background-color: #14532d66;
}

.bg-green-900\/45 {
  background-color: #14532d73;
}

.bg-green-900\/5 {
  background-color: #14532d0d;
}

.bg-green-900\/50 {
  background-color: #14532d80;
}

.bg-green-900\/55 {
  background-color: #14532d8c;
}

.bg-green-900\/60 {
  background-color: #14532d99;
}

.bg-green-900\/65 {
  background-color: #14532da6;
}

.bg-green-900\/70 {
  background-color: #14532db3;
}

.bg-green-900\/75 {
  background-color: #14532dbf;
}

.bg-green-900\/80 {
  background-color: #14532dcc;
}

.bg-green-900\/85 {
  background-color: #14532dd9;
}

.bg-green-900\/90 {
  background-color: #14532de6;
}

.bg-green-900\/95 {
  background-color: #14532df2;
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity, 1));
}

.bg-indigo-100\/0 {
  background-color: #e0e7ff00;
}

.bg-indigo-100\/10 {
  background-color: #e0e7ff1a;
}

.bg-indigo-100\/100 {
  background-color: #e0e7ff;
}

.bg-indigo-100\/15 {
  background-color: #e0e7ff26;
}

.bg-indigo-100\/20 {
  background-color: #e0e7ff33;
}

.bg-indigo-100\/25 {
  background-color: #e0e7ff40;
}

.bg-indigo-100\/30 {
  background-color: #e0e7ff4d;
}

.bg-indigo-100\/35 {
  background-color: #e0e7ff59;
}

.bg-indigo-100\/40 {
  background-color: #e0e7ff66;
}

.bg-indigo-100\/45 {
  background-color: #e0e7ff73;
}

.bg-indigo-100\/5 {
  background-color: #e0e7ff0d;
}

.bg-indigo-100\/50 {
  background-color: #e0e7ff80;
}

.bg-indigo-100\/55 {
  background-color: #e0e7ff8c;
}

.bg-indigo-100\/60 {
  background-color: #e0e7ff99;
}

.bg-indigo-100\/65 {
  background-color: #e0e7ffa6;
}

.bg-indigo-100\/70 {
  background-color: #e0e7ffb3;
}

.bg-indigo-100\/75 {
  background-color: #e0e7ffbf;
}

.bg-indigo-100\/80 {
  background-color: #e0e7ffcc;
}

.bg-indigo-100\/85 {
  background-color: #e0e7ffd9;
}

.bg-indigo-100\/90 {
  background-color: #e0e7ffe6;
}

.bg-indigo-100\/95 {
  background-color: #e0e7fff2;
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity, 1));
}

.bg-indigo-200\/0 {
  background-color: #c7d2fe00;
}

.bg-indigo-200\/10 {
  background-color: #c7d2fe1a;
}

.bg-indigo-200\/100 {
  background-color: #c7d2fe;
}

.bg-indigo-200\/15 {
  background-color: #c7d2fe26;
}

.bg-indigo-200\/20 {
  background-color: #c7d2fe33;
}

.bg-indigo-200\/25 {
  background-color: #c7d2fe40;
}

.bg-indigo-200\/30 {
  background-color: #c7d2fe4d;
}

.bg-indigo-200\/35 {
  background-color: #c7d2fe59;
}

.bg-indigo-200\/40 {
  background-color: #c7d2fe66;
}

.bg-indigo-200\/45 {
  background-color: #c7d2fe73;
}

.bg-indigo-200\/5 {
  background-color: #c7d2fe0d;
}

.bg-indigo-200\/50 {
  background-color: #c7d2fe80;
}

.bg-indigo-200\/55 {
  background-color: #c7d2fe8c;
}

.bg-indigo-200\/60 {
  background-color: #c7d2fe99;
}

.bg-indigo-200\/65 {
  background-color: #c7d2fea6;
}

.bg-indigo-200\/70 {
  background-color: #c7d2feb3;
}

.bg-indigo-200\/75 {
  background-color: #c7d2febf;
}

.bg-indigo-200\/80 {
  background-color: #c7d2fecc;
}

.bg-indigo-200\/85 {
  background-color: #c7d2fed9;
}

.bg-indigo-200\/90 {
  background-color: #c7d2fee6;
}

.bg-indigo-200\/95 {
  background-color: #c7d2fef2;
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity, 1));
}

.bg-indigo-500\/0 {
  background-color: #6366f100;
}

.bg-indigo-500\/10 {
  background-color: #6366f11a;
}

.bg-indigo-500\/100 {
  background-color: #6366f1;
}

.bg-indigo-500\/15 {
  background-color: #6366f126;
}

.bg-indigo-500\/20 {
  background-color: #6366f133;
}

.bg-indigo-500\/25 {
  background-color: #6366f140;
}

.bg-indigo-500\/30 {
  background-color: #6366f14d;
}

.bg-indigo-500\/35 {
  background-color: #6366f159;
}

.bg-indigo-500\/40 {
  background-color: #6366f166;
}

.bg-indigo-500\/45 {
  background-color: #6366f173;
}

.bg-indigo-500\/5 {
  background-color: #6366f10d;
}

.bg-indigo-500\/50 {
  background-color: #6366f180;
}

.bg-indigo-500\/55 {
  background-color: #6366f18c;
}

.bg-indigo-500\/60 {
  background-color: #6366f199;
}

.bg-indigo-500\/65 {
  background-color: #6366f1a6;
}

.bg-indigo-500\/70 {
  background-color: #6366f1b3;
}

.bg-indigo-500\/75 {
  background-color: #6366f1bf;
}

.bg-indigo-500\/80 {
  background-color: #6366f1cc;
}

.bg-indigo-500\/85 {
  background-color: #6366f1d9;
}

.bg-indigo-500\/90 {
  background-color: #6366f1e6;
}

.bg-indigo-500\/95 {
  background-color: #6366f1f2;
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity, 1));
}

.bg-indigo-800\/0 {
  background-color: #3730a300;
}

.bg-indigo-800\/10 {
  background-color: #3730a31a;
}

.bg-indigo-800\/100 {
  background-color: #3730a3;
}

.bg-indigo-800\/15 {
  background-color: #3730a326;
}

.bg-indigo-800\/20 {
  background-color: #3730a333;
}

.bg-indigo-800\/25 {
  background-color: #3730a340;
}

.bg-indigo-800\/30 {
  background-color: #3730a34d;
}

.bg-indigo-800\/35 {
  background-color: #3730a359;
}

.bg-indigo-800\/40 {
  background-color: #3730a366;
}

.bg-indigo-800\/45 {
  background-color: #3730a373;
}

.bg-indigo-800\/5 {
  background-color: #3730a30d;
}

.bg-indigo-800\/50 {
  background-color: #3730a380;
}

.bg-indigo-800\/55 {
  background-color: #3730a38c;
}

.bg-indigo-800\/60 {
  background-color: #3730a399;
}

.bg-indigo-800\/65 {
  background-color: #3730a3a6;
}

.bg-indigo-800\/70 {
  background-color: #3730a3b3;
}

.bg-indigo-800\/75 {
  background-color: #3730a3bf;
}

.bg-indigo-800\/80 {
  background-color: #3730a3cc;
}

.bg-indigo-800\/85 {
  background-color: #3730a3d9;
}

.bg-indigo-800\/90 {
  background-color: #3730a3e6;
}

.bg-indigo-800\/95 {
  background-color: #3730a3f2;
}

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity, 1));
}

.bg-indigo-900\/0 {
  background-color: #312e8100;
}

.bg-indigo-900\/10 {
  background-color: #312e811a;
}

.bg-indigo-900\/100 {
  background-color: #312e81;
}

.bg-indigo-900\/15 {
  background-color: #312e8126;
}

.bg-indigo-900\/20 {
  background-color: #312e8133;
}

.bg-indigo-900\/25 {
  background-color: #312e8140;
}

.bg-indigo-900\/30 {
  background-color: #312e814d;
}

.bg-indigo-900\/35 {
  background-color: #312e8159;
}

.bg-indigo-900\/40 {
  background-color: #312e8166;
}

.bg-indigo-900\/45 {
  background-color: #312e8173;
}

.bg-indigo-900\/5 {
  background-color: #312e810d;
}

.bg-indigo-900\/50 {
  background-color: #312e8180;
}

.bg-indigo-900\/55 {
  background-color: #312e818c;
}

.bg-indigo-900\/60 {
  background-color: #312e8199;
}

.bg-indigo-900\/65 {
  background-color: #312e81a6;
}

.bg-indigo-900\/70 {
  background-color: #312e81b3;
}

.bg-indigo-900\/75 {
  background-color: #312e81bf;
}

.bg-indigo-900\/80 {
  background-color: #312e81cc;
}

.bg-indigo-900\/85 {
  background-color: #312e81d9;
}

.bg-indigo-900\/90 {
  background-color: #312e81e6;
}

.bg-indigo-900\/95 {
  background-color: #312e81f2;
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity, 1));
}

.bg-lime-100\/0 {
  background-color: #ecfccb00;
}

.bg-lime-100\/10 {
  background-color: #ecfccb1a;
}

.bg-lime-100\/100 {
  background-color: #ecfccb;
}

.bg-lime-100\/15 {
  background-color: #ecfccb26;
}

.bg-lime-100\/20 {
  background-color: #ecfccb33;
}

.bg-lime-100\/25 {
  background-color: #ecfccb40;
}

.bg-lime-100\/30 {
  background-color: #ecfccb4d;
}

.bg-lime-100\/35 {
  background-color: #ecfccb59;
}

.bg-lime-100\/40 {
  background-color: #ecfccb66;
}

.bg-lime-100\/45 {
  background-color: #ecfccb73;
}

.bg-lime-100\/5 {
  background-color: #ecfccb0d;
}

.bg-lime-100\/50 {
  background-color: #ecfccb80;
}

.bg-lime-100\/55 {
  background-color: #ecfccb8c;
}

.bg-lime-100\/60 {
  background-color: #ecfccb99;
}

.bg-lime-100\/65 {
  background-color: #ecfccba6;
}

.bg-lime-100\/70 {
  background-color: #ecfccbb3;
}

.bg-lime-100\/75 {
  background-color: #ecfccbbf;
}

.bg-lime-100\/80 {
  background-color: #ecfccbcc;
}

.bg-lime-100\/85 {
  background-color: #ecfccbd9;
}

.bg-lime-100\/90 {
  background-color: #ecfccbe6;
}

.bg-lime-100\/95 {
  background-color: #ecfccbf2;
}

.bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity, 1));
}

.bg-lime-200\/0 {
  background-color: #d9f99d00;
}

.bg-lime-200\/10 {
  background-color: #d9f99d1a;
}

.bg-lime-200\/100 {
  background-color: #d9f99d;
}

.bg-lime-200\/15 {
  background-color: #d9f99d26;
}

.bg-lime-200\/20 {
  background-color: #d9f99d33;
}

.bg-lime-200\/25 {
  background-color: #d9f99d40;
}

.bg-lime-200\/30 {
  background-color: #d9f99d4d;
}

.bg-lime-200\/35 {
  background-color: #d9f99d59;
}

.bg-lime-200\/40 {
  background-color: #d9f99d66;
}

.bg-lime-200\/45 {
  background-color: #d9f99d73;
}

.bg-lime-200\/5 {
  background-color: #d9f99d0d;
}

.bg-lime-200\/50 {
  background-color: #d9f99d80;
}

.bg-lime-200\/55 {
  background-color: #d9f99d8c;
}

.bg-lime-200\/60 {
  background-color: #d9f99d99;
}

.bg-lime-200\/65 {
  background-color: #d9f99da6;
}

.bg-lime-200\/70 {
  background-color: #d9f99db3;
}

.bg-lime-200\/75 {
  background-color: #d9f99dbf;
}

.bg-lime-200\/80 {
  background-color: #d9f99dcc;
}

.bg-lime-200\/85 {
  background-color: #d9f99dd9;
}

.bg-lime-200\/90 {
  background-color: #d9f99de6;
}

.bg-lime-200\/95 {
  background-color: #d9f99df2;
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity, 1));
}

.bg-lime-500\/0 {
  background-color: #84cc1600;
}

.bg-lime-500\/10 {
  background-color: #84cc161a;
}

.bg-lime-500\/100 {
  background-color: #84cc16;
}

.bg-lime-500\/15 {
  background-color: #84cc1626;
}

.bg-lime-500\/20 {
  background-color: #84cc1633;
}

.bg-lime-500\/25 {
  background-color: #84cc1640;
}

.bg-lime-500\/30 {
  background-color: #84cc164d;
}

.bg-lime-500\/35 {
  background-color: #84cc1659;
}

.bg-lime-500\/40 {
  background-color: #84cc1666;
}

.bg-lime-500\/45 {
  background-color: #84cc1673;
}

.bg-lime-500\/5 {
  background-color: #84cc160d;
}

.bg-lime-500\/50 {
  background-color: #84cc1680;
}

.bg-lime-500\/55 {
  background-color: #84cc168c;
}

.bg-lime-500\/60 {
  background-color: #84cc1699;
}

.bg-lime-500\/65 {
  background-color: #84cc16a6;
}

.bg-lime-500\/70 {
  background-color: #84cc16b3;
}

.bg-lime-500\/75 {
  background-color: #84cc16bf;
}

.bg-lime-500\/80 {
  background-color: #84cc16cc;
}

.bg-lime-500\/85 {
  background-color: #84cc16d9;
}

.bg-lime-500\/90 {
  background-color: #84cc16e6;
}

.bg-lime-500\/95 {
  background-color: #84cc16f2;
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity, 1));
}

.bg-lime-800\/0 {
  background-color: #3f621200;
}

.bg-lime-800\/10 {
  background-color: #3f62121a;
}

.bg-lime-800\/100 {
  background-color: #3f6212;
}

.bg-lime-800\/15 {
  background-color: #3f621226;
}

.bg-lime-800\/20 {
  background-color: #3f621233;
}

.bg-lime-800\/25 {
  background-color: #3f621240;
}

.bg-lime-800\/30 {
  background-color: #3f62124d;
}

.bg-lime-800\/35 {
  background-color: #3f621259;
}

.bg-lime-800\/40 {
  background-color: #3f621266;
}

.bg-lime-800\/45 {
  background-color: #3f621273;
}

.bg-lime-800\/5 {
  background-color: #3f62120d;
}

.bg-lime-800\/50 {
  background-color: #3f621280;
}

.bg-lime-800\/55 {
  background-color: #3f62128c;
}

.bg-lime-800\/60 {
  background-color: #3f621299;
}

.bg-lime-800\/65 {
  background-color: #3f6212a6;
}

.bg-lime-800\/70 {
  background-color: #3f6212b3;
}

.bg-lime-800\/75 {
  background-color: #3f6212bf;
}

.bg-lime-800\/80 {
  background-color: #3f6212cc;
}

.bg-lime-800\/85 {
  background-color: #3f6212d9;
}

.bg-lime-800\/90 {
  background-color: #3f6212e6;
}

.bg-lime-800\/95 {
  background-color: #3f6212f2;
}

.bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity, 1));
}

.bg-lime-900\/0 {
  background-color: #36531400;
}

.bg-lime-900\/10 {
  background-color: #3653141a;
}

.bg-lime-900\/100 {
  background-color: #365314;
}

.bg-lime-900\/15 {
  background-color: #36531426;
}

.bg-lime-900\/20 {
  background-color: #36531433;
}

.bg-lime-900\/25 {
  background-color: #36531440;
}

.bg-lime-900\/30 {
  background-color: #3653144d;
}

.bg-lime-900\/35 {
  background-color: #36531459;
}

.bg-lime-900\/40 {
  background-color: #36531466;
}

.bg-lime-900\/45 {
  background-color: #36531473;
}

.bg-lime-900\/5 {
  background-color: #3653140d;
}

.bg-lime-900\/50 {
  background-color: #36531480;
}

.bg-lime-900\/55 {
  background-color: #3653148c;
}

.bg-lime-900\/60 {
  background-color: #36531499;
}

.bg-lime-900\/65 {
  background-color: #365314a6;
}

.bg-lime-900\/70 {
  background-color: #365314b3;
}

.bg-lime-900\/75 {
  background-color: #365314bf;
}

.bg-lime-900\/80 {
  background-color: #365314cc;
}

.bg-lime-900\/85 {
  background-color: #365314d9;
}

.bg-lime-900\/90 {
  background-color: #365314e6;
}

.bg-lime-900\/95 {
  background-color: #365314f2;
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity, 1));
}

.bg-orange-100\/0 {
  background-color: #ffedd500;
}

.bg-orange-100\/10 {
  background-color: #ffedd51a;
}

.bg-orange-100\/100 {
  background-color: #ffedd5;
}

.bg-orange-100\/15 {
  background-color: #ffedd526;
}

.bg-orange-100\/20 {
  background-color: #ffedd533;
}

.bg-orange-100\/25 {
  background-color: #ffedd540;
}

.bg-orange-100\/30 {
  background-color: #ffedd54d;
}

.bg-orange-100\/35 {
  background-color: #ffedd559;
}

.bg-orange-100\/40 {
  background-color: #ffedd566;
}

.bg-orange-100\/45 {
  background-color: #ffedd573;
}

.bg-orange-100\/5 {
  background-color: #ffedd50d;
}

.bg-orange-100\/50 {
  background-color: #ffedd580;
}

.bg-orange-100\/55 {
  background-color: #ffedd58c;
}

.bg-orange-100\/60 {
  background-color: #ffedd599;
}

.bg-orange-100\/65 {
  background-color: #ffedd5a6;
}

.bg-orange-100\/70 {
  background-color: #ffedd5b3;
}

.bg-orange-100\/75 {
  background-color: #ffedd5bf;
}

.bg-orange-100\/80 {
  background-color: #ffedd5cc;
}

.bg-orange-100\/85 {
  background-color: #ffedd5d9;
}

.bg-orange-100\/90 {
  background-color: #ffedd5e6;
}

.bg-orange-100\/95 {
  background-color: #ffedd5f2;
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity, 1));
}

.bg-orange-200\/0 {
  background-color: #fed7aa00;
}

.bg-orange-200\/10 {
  background-color: #fed7aa1a;
}

.bg-orange-200\/100 {
  background-color: #fed7aa;
}

.bg-orange-200\/15 {
  background-color: #fed7aa26;
}

.bg-orange-200\/20 {
  background-color: #fed7aa33;
}

.bg-orange-200\/25 {
  background-color: #fed7aa40;
}

.bg-orange-200\/30 {
  background-color: #fed7aa4d;
}

.bg-orange-200\/35 {
  background-color: #fed7aa59;
}

.bg-orange-200\/40 {
  background-color: #fed7aa66;
}

.bg-orange-200\/45 {
  background-color: #fed7aa73;
}

.bg-orange-200\/5 {
  background-color: #fed7aa0d;
}

.bg-orange-200\/50 {
  background-color: #fed7aa80;
}

.bg-orange-200\/55 {
  background-color: #fed7aa8c;
}

.bg-orange-200\/60 {
  background-color: #fed7aa99;
}

.bg-orange-200\/65 {
  background-color: #fed7aaa6;
}

.bg-orange-200\/70 {
  background-color: #fed7aab3;
}

.bg-orange-200\/75 {
  background-color: #fed7aabf;
}

.bg-orange-200\/80 {
  background-color: #fed7aacc;
}

.bg-orange-200\/85 {
  background-color: #fed7aad9;
}

.bg-orange-200\/90 {
  background-color: #fed7aae6;
}

.bg-orange-200\/95 {
  background-color: #fed7aaf2;
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity, 1));
}

.bg-orange-500\/0 {
  background-color: #f9731600;
}

.bg-orange-500\/10 {
  background-color: #f973161a;
}

.bg-orange-500\/100 {
  background-color: #f97316;
}

.bg-orange-500\/15 {
  background-color: #f9731626;
}

.bg-orange-500\/20 {
  background-color: #f9731633;
}

.bg-orange-500\/25 {
  background-color: #f9731640;
}

.bg-orange-500\/30 {
  background-color: #f973164d;
}

.bg-orange-500\/35 {
  background-color: #f9731659;
}

.bg-orange-500\/40 {
  background-color: #f9731666;
}

.bg-orange-500\/45 {
  background-color: #f9731673;
}

.bg-orange-500\/5 {
  background-color: #f973160d;
}

.bg-orange-500\/50 {
  background-color: #f9731680;
}

.bg-orange-500\/55 {
  background-color: #f973168c;
}

.bg-orange-500\/60 {
  background-color: #f9731699;
}

.bg-orange-500\/65 {
  background-color: #f97316a6;
}

.bg-orange-500\/70 {
  background-color: #f97316b3;
}

.bg-orange-500\/75 {
  background-color: #f97316bf;
}

.bg-orange-500\/80 {
  background-color: #f97316cc;
}

.bg-orange-500\/85 {
  background-color: #f97316d9;
}

.bg-orange-500\/90 {
  background-color: #f97316e6;
}

.bg-orange-500\/95 {
  background-color: #f97316f2;
}

.bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(154 52 18 / var(--tw-bg-opacity, 1));
}

.bg-orange-800\/0 {
  background-color: #9a341200;
}

.bg-orange-800\/10 {
  background-color: #9a34121a;
}

.bg-orange-800\/100 {
  background-color: #9a3412;
}

.bg-orange-800\/15 {
  background-color: #9a341226;
}

.bg-orange-800\/20 {
  background-color: #9a341233;
}

.bg-orange-800\/25 {
  background-color: #9a341240;
}

.bg-orange-800\/30 {
  background-color: #9a34124d;
}

.bg-orange-800\/35 {
  background-color: #9a341259;
}

.bg-orange-800\/40 {
  background-color: #9a341266;
}

.bg-orange-800\/45 {
  background-color: #9a341273;
}

.bg-orange-800\/5 {
  background-color: #9a34120d;
}

.bg-orange-800\/50 {
  background-color: #9a341280;
}

.bg-orange-800\/55 {
  background-color: #9a34128c;
}

.bg-orange-800\/60 {
  background-color: #9a341299;
}

.bg-orange-800\/65 {
  background-color: #9a3412a6;
}

.bg-orange-800\/70 {
  background-color: #9a3412b3;
}

.bg-orange-800\/75 {
  background-color: #9a3412bf;
}

.bg-orange-800\/80 {
  background-color: #9a3412cc;
}

.bg-orange-800\/85 {
  background-color: #9a3412d9;
}

.bg-orange-800\/90 {
  background-color: #9a3412e6;
}

.bg-orange-800\/95 {
  background-color: #9a3412f2;
}

.bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 45 18 / var(--tw-bg-opacity, 1));
}

.bg-orange-900\/0 {
  background-color: #7c2d1200;
}

.bg-orange-900\/10 {
  background-color: #7c2d121a;
}

.bg-orange-900\/100 {
  background-color: #7c2d12;
}

.bg-orange-900\/15 {
  background-color: #7c2d1226;
}

.bg-orange-900\/20 {
  background-color: #7c2d1233;
}

.bg-orange-900\/25 {
  background-color: #7c2d1240;
}

.bg-orange-900\/30 {
  background-color: #7c2d124d;
}

.bg-orange-900\/35 {
  background-color: #7c2d1259;
}

.bg-orange-900\/40 {
  background-color: #7c2d1266;
}

.bg-orange-900\/45 {
  background-color: #7c2d1273;
}

.bg-orange-900\/5 {
  background-color: #7c2d120d;
}

.bg-orange-900\/50 {
  background-color: #7c2d1280;
}

.bg-orange-900\/55 {
  background-color: #7c2d128c;
}

.bg-orange-900\/60 {
  background-color: #7c2d1299;
}

.bg-orange-900\/65 {
  background-color: #7c2d12a6;
}

.bg-orange-900\/70 {
  background-color: #7c2d12b3;
}

.bg-orange-900\/75 {
  background-color: #7c2d12bf;
}

.bg-orange-900\/80 {
  background-color: #7c2d12cc;
}

.bg-orange-900\/85 {
  background-color: #7c2d12d9;
}

.bg-orange-900\/90 {
  background-color: #7c2d12e6;
}

.bg-orange-900\/95 {
  background-color: #7c2d12f2;
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity, 1));
}

.bg-pink-100\/0 {
  background-color: #fce7f300;
}

.bg-pink-100\/10 {
  background-color: #fce7f31a;
}

.bg-pink-100\/100 {
  background-color: #fce7f3;
}

.bg-pink-100\/15 {
  background-color: #fce7f326;
}

.bg-pink-100\/20 {
  background-color: #fce7f333;
}

.bg-pink-100\/25 {
  background-color: #fce7f340;
}

.bg-pink-100\/30 {
  background-color: #fce7f34d;
}

.bg-pink-100\/35 {
  background-color: #fce7f359;
}

.bg-pink-100\/40 {
  background-color: #fce7f366;
}

.bg-pink-100\/45 {
  background-color: #fce7f373;
}

.bg-pink-100\/5 {
  background-color: #fce7f30d;
}

.bg-pink-100\/50 {
  background-color: #fce7f380;
}

.bg-pink-100\/55 {
  background-color: #fce7f38c;
}

.bg-pink-100\/60 {
  background-color: #fce7f399;
}

.bg-pink-100\/65 {
  background-color: #fce7f3a6;
}

.bg-pink-100\/70 {
  background-color: #fce7f3b3;
}

.bg-pink-100\/75 {
  background-color: #fce7f3bf;
}

.bg-pink-100\/80 {
  background-color: #fce7f3cc;
}

.bg-pink-100\/85 {
  background-color: #fce7f3d9;
}

.bg-pink-100\/90 {
  background-color: #fce7f3e6;
}

.bg-pink-100\/95 {
  background-color: #fce7f3f2;
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity, 1));
}

.bg-pink-200\/0 {
  background-color: #fbcfe800;
}

.bg-pink-200\/10 {
  background-color: #fbcfe81a;
}

.bg-pink-200\/100 {
  background-color: #fbcfe8;
}

.bg-pink-200\/15 {
  background-color: #fbcfe826;
}

.bg-pink-200\/20 {
  background-color: #fbcfe833;
}

.bg-pink-200\/25 {
  background-color: #fbcfe840;
}

.bg-pink-200\/30 {
  background-color: #fbcfe84d;
}

.bg-pink-200\/35 {
  background-color: #fbcfe859;
}

.bg-pink-200\/40 {
  background-color: #fbcfe866;
}

.bg-pink-200\/45 {
  background-color: #fbcfe873;
}

.bg-pink-200\/5 {
  background-color: #fbcfe80d;
}

.bg-pink-200\/50 {
  background-color: #fbcfe880;
}

.bg-pink-200\/55 {
  background-color: #fbcfe88c;
}

.bg-pink-200\/60 {
  background-color: #fbcfe899;
}

.bg-pink-200\/65 {
  background-color: #fbcfe8a6;
}

.bg-pink-200\/70 {
  background-color: #fbcfe8b3;
}

.bg-pink-200\/75 {
  background-color: #fbcfe8bf;
}

.bg-pink-200\/80 {
  background-color: #fbcfe8cc;
}

.bg-pink-200\/85 {
  background-color: #fbcfe8d9;
}

.bg-pink-200\/90 {
  background-color: #fbcfe8e6;
}

.bg-pink-200\/95 {
  background-color: #fbcfe8f2;
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity, 1));
}

.bg-pink-500\/0 {
  background-color: #ec489900;
}

.bg-pink-500\/10 {
  background-color: #ec48991a;
}

.bg-pink-500\/100 {
  background-color: #ec4899;
}

.bg-pink-500\/15 {
  background-color: #ec489926;
}

.bg-pink-500\/20 {
  background-color: #ec489933;
}

.bg-pink-500\/25 {
  background-color: #ec489940;
}

.bg-pink-500\/30 {
  background-color: #ec48994d;
}

.bg-pink-500\/35 {
  background-color: #ec489959;
}

.bg-pink-500\/40 {
  background-color: #ec489966;
}

.bg-pink-500\/45 {
  background-color: #ec489973;
}

.bg-pink-500\/5 {
  background-color: #ec48990d;
}

.bg-pink-500\/50 {
  background-color: #ec489980;
}

.bg-pink-500\/55 {
  background-color: #ec48998c;
}

.bg-pink-500\/60 {
  background-color: #ec489999;
}

.bg-pink-500\/65 {
  background-color: #ec4899a6;
}

.bg-pink-500\/70 {
  background-color: #ec4899b3;
}

.bg-pink-500\/75 {
  background-color: #ec4899bf;
}

.bg-pink-500\/80 {
  background-color: #ec4899cc;
}

.bg-pink-500\/85 {
  background-color: #ec4899d9;
}

.bg-pink-500\/90 {
  background-color: #ec4899e6;
}

.bg-pink-500\/95 {
  background-color: #ec4899f2;
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity, 1));
}

.bg-pink-800\/0 {
  background-color: #9d174d00;
}

.bg-pink-800\/10 {
  background-color: #9d174d1a;
}

.bg-pink-800\/100 {
  background-color: #9d174d;
}

.bg-pink-800\/15 {
  background-color: #9d174d26;
}

.bg-pink-800\/20 {
  background-color: #9d174d33;
}

.bg-pink-800\/25 {
  background-color: #9d174d40;
}

.bg-pink-800\/30 {
  background-color: #9d174d4d;
}

.bg-pink-800\/35 {
  background-color: #9d174d59;
}

.bg-pink-800\/40 {
  background-color: #9d174d66;
}

.bg-pink-800\/45 {
  background-color: #9d174d73;
}

.bg-pink-800\/5 {
  background-color: #9d174d0d;
}

.bg-pink-800\/50 {
  background-color: #9d174d80;
}

.bg-pink-800\/55 {
  background-color: #9d174d8c;
}

.bg-pink-800\/60 {
  background-color: #9d174d99;
}

.bg-pink-800\/65 {
  background-color: #9d174da6;
}

.bg-pink-800\/70 {
  background-color: #9d174db3;
}

.bg-pink-800\/75 {
  background-color: #9d174dbf;
}

.bg-pink-800\/80 {
  background-color: #9d174dcc;
}

.bg-pink-800\/85 {
  background-color: #9d174dd9;
}

.bg-pink-800\/90 {
  background-color: #9d174de6;
}

.bg-pink-800\/95 {
  background-color: #9d174df2;
}

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity, 1));
}

.bg-pink-900\/0 {
  background-color: #83184300;
}

.bg-pink-900\/10 {
  background-color: #8318431a;
}

.bg-pink-900\/100 {
  background-color: #831843;
}

.bg-pink-900\/15 {
  background-color: #83184326;
}

.bg-pink-900\/20 {
  background-color: #83184333;
}

.bg-pink-900\/25 {
  background-color: #83184340;
}

.bg-pink-900\/30 {
  background-color: #8318434d;
}

.bg-pink-900\/35 {
  background-color: #83184359;
}

.bg-pink-900\/40 {
  background-color: #83184366;
}

.bg-pink-900\/45 {
  background-color: #83184373;
}

.bg-pink-900\/5 {
  background-color: #8318430d;
}

.bg-pink-900\/50 {
  background-color: #83184380;
}

.bg-pink-900\/55 {
  background-color: #8318438c;
}

.bg-pink-900\/60 {
  background-color: #83184399;
}

.bg-pink-900\/65 {
  background-color: #831843a6;
}

.bg-pink-900\/70 {
  background-color: #831843b3;
}

.bg-pink-900\/75 {
  background-color: #831843bf;
}

.bg-pink-900\/80 {
  background-color: #831843cc;
}

.bg-pink-900\/85 {
  background-color: #831843d9;
}

.bg-pink-900\/90 {
  background-color: #831843e6;
}

.bg-pink-900\/95 {
  background-color: #831843f2;
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-100\/0 {
  background-color: #f3e8ff00;
}

.bg-purple-100\/10 {
  background-color: #f3e8ff1a;
}

.bg-purple-100\/100 {
  background-color: #f3e8ff;
}

.bg-purple-100\/15 {
  background-color: #f3e8ff26;
}

.bg-purple-100\/20 {
  background-color: #f3e8ff33;
}

.bg-purple-100\/25 {
  background-color: #f3e8ff40;
}

.bg-purple-100\/30 {
  background-color: #f3e8ff4d;
}

.bg-purple-100\/35 {
  background-color: #f3e8ff59;
}

.bg-purple-100\/40 {
  background-color: #f3e8ff66;
}

.bg-purple-100\/45 {
  background-color: #f3e8ff73;
}

.bg-purple-100\/5 {
  background-color: #f3e8ff0d;
}

.bg-purple-100\/50 {
  background-color: #f3e8ff80;
}

.bg-purple-100\/55 {
  background-color: #f3e8ff8c;
}

.bg-purple-100\/60 {
  background-color: #f3e8ff99;
}

.bg-purple-100\/65 {
  background-color: #f3e8ffa6;
}

.bg-purple-100\/70 {
  background-color: #f3e8ffb3;
}

.bg-purple-100\/75 {
  background-color: #f3e8ffbf;
}

.bg-purple-100\/80 {
  background-color: #f3e8ffcc;
}

.bg-purple-100\/85 {
  background-color: #f3e8ffd9;
}

.bg-purple-100\/90 {
  background-color: #f3e8ffe6;
}

.bg-purple-100\/95 {
  background-color: #f3e8fff2;
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity, 1));
}

.bg-purple-200\/0 {
  background-color: #e9d5ff00;
}

.bg-purple-200\/10 {
  background-color: #e9d5ff1a;
}

.bg-purple-200\/100 {
  background-color: #e9d5ff;
}

.bg-purple-200\/15 {
  background-color: #e9d5ff26;
}

.bg-purple-200\/20 {
  background-color: #e9d5ff33;
}

.bg-purple-200\/25 {
  background-color: #e9d5ff40;
}

.bg-purple-200\/30 {
  background-color: #e9d5ff4d;
}

.bg-purple-200\/35 {
  background-color: #e9d5ff59;
}

.bg-purple-200\/40 {
  background-color: #e9d5ff66;
}

.bg-purple-200\/45 {
  background-color: #e9d5ff73;
}

.bg-purple-200\/5 {
  background-color: #e9d5ff0d;
}

.bg-purple-200\/50 {
  background-color: #e9d5ff80;
}

.bg-purple-200\/55 {
  background-color: #e9d5ff8c;
}

.bg-purple-200\/60 {
  background-color: #e9d5ff99;
}

.bg-purple-200\/65 {
  background-color: #e9d5ffa6;
}

.bg-purple-200\/70 {
  background-color: #e9d5ffb3;
}

.bg-purple-200\/75 {
  background-color: #e9d5ffbf;
}

.bg-purple-200\/80 {
  background-color: #e9d5ffcc;
}

.bg-purple-200\/85 {
  background-color: #e9d5ffd9;
}

.bg-purple-200\/90 {
  background-color: #e9d5ffe6;
}

.bg-purple-200\/95 {
  background-color: #e9d5fff2;
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity, 1));
}

.bg-purple-500\/0 {
  background-color: #a855f700;
}

.bg-purple-500\/10 {
  background-color: #a855f71a;
}

.bg-purple-500\/100 {
  background-color: #a855f7;
}

.bg-purple-500\/15 {
  background-color: #a855f726;
}

.bg-purple-500\/20 {
  background-color: #a855f733;
}

.bg-purple-500\/25 {
  background-color: #a855f740;
}

.bg-purple-500\/30 {
  background-color: #a855f74d;
}

.bg-purple-500\/35 {
  background-color: #a855f759;
}

.bg-purple-500\/40 {
  background-color: #a855f766;
}

.bg-purple-500\/45 {
  background-color: #a855f773;
}

.bg-purple-500\/5 {
  background-color: #a855f70d;
}

.bg-purple-500\/50 {
  background-color: #a855f780;
}

.bg-purple-500\/55 {
  background-color: #a855f78c;
}

.bg-purple-500\/60 {
  background-color: #a855f799;
}

.bg-purple-500\/65 {
  background-color: #a855f7a6;
}

.bg-purple-500\/70 {
  background-color: #a855f7b3;
}

.bg-purple-500\/75 {
  background-color: #a855f7bf;
}

.bg-purple-500\/80 {
  background-color: #a855f7cc;
}

.bg-purple-500\/85 {
  background-color: #a855f7d9;
}

.bg-purple-500\/90 {
  background-color: #a855f7e6;
}

.bg-purple-500\/95 {
  background-color: #a855f7f2;
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity, 1));
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity, 1));
}

.bg-purple-800\/0 {
  background-color: #6b21a800;
}

.bg-purple-800\/10 {
  background-color: #6b21a81a;
}

.bg-purple-800\/100 {
  background-color: #6b21a8;
}

.bg-purple-800\/15 {
  background-color: #6b21a826;
}

.bg-purple-800\/20 {
  background-color: #6b21a833;
}

.bg-purple-800\/25 {
  background-color: #6b21a840;
}

.bg-purple-800\/30 {
  background-color: #6b21a84d;
}

.bg-purple-800\/35 {
  background-color: #6b21a859;
}

.bg-purple-800\/40 {
  background-color: #6b21a866;
}

.bg-purple-800\/45 {
  background-color: #6b21a873;
}

.bg-purple-800\/5 {
  background-color: #6b21a80d;
}

.bg-purple-800\/50 {
  background-color: #6b21a880;
}

.bg-purple-800\/55 {
  background-color: #6b21a88c;
}

.bg-purple-800\/60 {
  background-color: #6b21a899;
}

.bg-purple-800\/65 {
  background-color: #6b21a8a6;
}

.bg-purple-800\/70 {
  background-color: #6b21a8b3;
}

.bg-purple-800\/75 {
  background-color: #6b21a8bf;
}

.bg-purple-800\/80 {
  background-color: #6b21a8cc;
}

.bg-purple-800\/85 {
  background-color: #6b21a8d9;
}

.bg-purple-800\/90 {
  background-color: #6b21a8e6;
}

.bg-purple-800\/95 {
  background-color: #6b21a8f2;
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity, 1));
}

.bg-purple-900\/0 {
  background-color: #581c8700;
}

.bg-purple-900\/10 {
  background-color: #581c871a;
}

.bg-purple-900\/100 {
  background-color: #581c87;
}

.bg-purple-900\/15 {
  background-color: #581c8726;
}

.bg-purple-900\/20 {
  background-color: #581c8733;
}

.bg-purple-900\/25 {
  background-color: #581c8740;
}

.bg-purple-900\/30 {
  background-color: #581c874d;
}

.bg-purple-900\/35 {
  background-color: #581c8759;
}

.bg-purple-900\/40 {
  background-color: #581c8766;
}

.bg-purple-900\/45 {
  background-color: #581c8773;
}

.bg-purple-900\/5 {
  background-color: #581c870d;
}

.bg-purple-900\/50 {
  background-color: #581c8780;
}

.bg-purple-900\/55 {
  background-color: #581c878c;
}

.bg-purple-900\/60 {
  background-color: #581c8799;
}

.bg-purple-900\/65 {
  background-color: #581c87a6;
}

.bg-purple-900\/70 {
  background-color: #581c87b3;
}

.bg-purple-900\/75 {
  background-color: #581c87bf;
}

.bg-purple-900\/80 {
  background-color: #581c87cc;
}

.bg-purple-900\/85 {
  background-color: #581c87d9;
}

.bg-purple-900\/90 {
  background-color: #581c87e6;
}

.bg-purple-900\/95 {
  background-color: #581c87f2;
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}

.bg-red-100\/0 {
  background-color: #fee2e200;
}

.bg-red-100\/10 {
  background-color: #fee2e21a;
}

.bg-red-100\/100 {
  background-color: #fee2e2;
}

.bg-red-100\/15 {
  background-color: #fee2e226;
}

.bg-red-100\/20 {
  background-color: #fee2e233;
}

.bg-red-100\/25 {
  background-color: #fee2e240;
}

.bg-red-100\/30 {
  background-color: #fee2e24d;
}

.bg-red-100\/35 {
  background-color: #fee2e259;
}

.bg-red-100\/40 {
  background-color: #fee2e266;
}

.bg-red-100\/45 {
  background-color: #fee2e273;
}

.bg-red-100\/5 {
  background-color: #fee2e20d;
}

.bg-red-100\/50 {
  background-color: #fee2e280;
}

.bg-red-100\/55 {
  background-color: #fee2e28c;
}

.bg-red-100\/60 {
  background-color: #fee2e299;
}

.bg-red-100\/65 {
  background-color: #fee2e2a6;
}

.bg-red-100\/70 {
  background-color: #fee2e2b3;
}

.bg-red-100\/75 {
  background-color: #fee2e2bf;
}

.bg-red-100\/80 {
  background-color: #fee2e2cc;
}

.bg-red-100\/85 {
  background-color: #fee2e2d9;
}

.bg-red-100\/90 {
  background-color: #fee2e2e6;
}

.bg-red-100\/95 {
  background-color: #fee2e2f2;
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity, 1));
}

.bg-red-200\/0 {
  background-color: #fecaca00;
}

.bg-red-200\/10 {
  background-color: #fecaca1a;
}

.bg-red-200\/100 {
  background-color: #fecaca;
}

.bg-red-200\/15 {
  background-color: #fecaca26;
}

.bg-red-200\/20 {
  background-color: #fecaca33;
}

.bg-red-200\/25 {
  background-color: #fecaca40;
}

.bg-red-200\/30 {
  background-color: #fecaca4d;
}

.bg-red-200\/35 {
  background-color: #fecaca59;
}

.bg-red-200\/40 {
  background-color: #fecaca66;
}

.bg-red-200\/45 {
  background-color: #fecaca73;
}

.bg-red-200\/5 {
  background-color: #fecaca0d;
}

.bg-red-200\/50 {
  background-color: #fecaca80;
}

.bg-red-200\/55 {
  background-color: #fecaca8c;
}

.bg-red-200\/60 {
  background-color: #fecaca99;
}

.bg-red-200\/65 {
  background-color: #fecacaa6;
}

.bg-red-200\/70 {
  background-color: #fecacab3;
}

.bg-red-200\/75 {
  background-color: #fecacabf;
}

.bg-red-200\/80 {
  background-color: #fecacacc;
}

.bg-red-200\/85 {
  background-color: #fecacad9;
}

.bg-red-200\/90 {
  background-color: #fecacae6;
}

.bg-red-200\/95 {
  background-color: #fecacaf2;
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity, 1));
}

.bg-red-500\/0 {
  background-color: #ef444400;
}

.bg-red-500\/10 {
  background-color: #ef44441a;
}

.bg-red-500\/100 {
  background-color: #ef4444;
}

.bg-red-500\/15 {
  background-color: #ef444426;
}

.bg-red-500\/20 {
  background-color: #ef444433;
}

.bg-red-500\/25 {
  background-color: #ef444440;
}

.bg-red-500\/30 {
  background-color: #ef44444d;
}

.bg-red-500\/35 {
  background-color: #ef444459;
}

.bg-red-500\/40 {
  background-color: #ef444466;
}

.bg-red-500\/45 {
  background-color: #ef444473;
}

.bg-red-500\/5 {
  background-color: #ef44440d;
}

.bg-red-500\/50 {
  background-color: #ef444480;
}

.bg-red-500\/55 {
  background-color: #ef44448c;
}

.bg-red-500\/60 {
  background-color: #ef444499;
}

.bg-red-500\/65 {
  background-color: #ef4444a6;
}

.bg-red-500\/70 {
  background-color: #ef4444b3;
}

.bg-red-500\/75 {
  background-color: #ef4444bf;
}

.bg-red-500\/80 {
  background-color: #ef4444cc;
}

.bg-red-500\/85 {
  background-color: #ef4444d9;
}

.bg-red-500\/90 {
  background-color: #ef4444e6;
}

.bg-red-500\/95 {
  background-color: #ef4444f2;
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity, 1));
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity, 1));
}

.bg-red-800\/0 {
  background-color: #991b1b00;
}

.bg-red-800\/10 {
  background-color: #991b1b1a;
}

.bg-red-800\/100 {
  background-color: #991b1b;
}

.bg-red-800\/15 {
  background-color: #991b1b26;
}

.bg-red-800\/20 {
  background-color: #991b1b33;
}

.bg-red-800\/25 {
  background-color: #991b1b40;
}

.bg-red-800\/30 {
  background-color: #991b1b4d;
}

.bg-red-800\/35 {
  background-color: #991b1b59;
}

.bg-red-800\/40 {
  background-color: #991b1b66;
}

.bg-red-800\/45 {
  background-color: #991b1b73;
}

.bg-red-800\/5 {
  background-color: #991b1b0d;
}

.bg-red-800\/50 {
  background-color: #991b1b80;
}

.bg-red-800\/55 {
  background-color: #991b1b8c;
}

.bg-red-800\/60 {
  background-color: #991b1b99;
}

.bg-red-800\/65 {
  background-color: #991b1ba6;
}

.bg-red-800\/70 {
  background-color: #991b1bb3;
}

.bg-red-800\/75 {
  background-color: #991b1bbf;
}

.bg-red-800\/80 {
  background-color: #991b1bcc;
}

.bg-red-800\/85 {
  background-color: #991b1bd9;
}

.bg-red-800\/90 {
  background-color: #991b1be6;
}

.bg-red-800\/95 {
  background-color: #991b1bf2;
}

.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity, 1));
}

.bg-red-900\/0 {
  background-color: #7f1d1d00;
}

.bg-red-900\/10 {
  background-color: #7f1d1d1a;
}

.bg-red-900\/100 {
  background-color: #7f1d1d;
}

.bg-red-900\/15 {
  background-color: #7f1d1d26;
}

.bg-red-900\/20 {
  background-color: #7f1d1d33;
}

.bg-red-900\/25 {
  background-color: #7f1d1d40;
}

.bg-red-900\/30 {
  background-color: #7f1d1d4d;
}

.bg-red-900\/35 {
  background-color: #7f1d1d59;
}

.bg-red-900\/40 {
  background-color: #7f1d1d66;
}

.bg-red-900\/45 {
  background-color: #7f1d1d73;
}

.bg-red-900\/5 {
  background-color: #7f1d1d0d;
}

.bg-red-900\/50 {
  background-color: #7f1d1d80;
}

.bg-red-900\/55 {
  background-color: #7f1d1d8c;
}

.bg-red-900\/60 {
  background-color: #7f1d1d99;
}

.bg-red-900\/65 {
  background-color: #7f1d1da6;
}

.bg-red-900\/70 {
  background-color: #7f1d1db3;
}

.bg-red-900\/75 {
  background-color: #7f1d1dbf;
}

.bg-red-900\/80 {
  background-color: #7f1d1dcc;
}

.bg-red-900\/85 {
  background-color: #7f1d1dd9;
}

.bg-red-900\/90 {
  background-color: #7f1d1de6;
}

.bg-red-900\/95 {
  background-color: #7f1d1df2;
}

.bg-rose-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity, 1));
}

.bg-rose-100\/0 {
  background-color: #ffe4e600;
}

.bg-rose-100\/10 {
  background-color: #ffe4e61a;
}

.bg-rose-100\/100 {
  background-color: #ffe4e6;
}

.bg-rose-100\/15 {
  background-color: #ffe4e626;
}

.bg-rose-100\/20 {
  background-color: #ffe4e633;
}

.bg-rose-100\/25 {
  background-color: #ffe4e640;
}

.bg-rose-100\/30 {
  background-color: #ffe4e64d;
}

.bg-rose-100\/35 {
  background-color: #ffe4e659;
}

.bg-rose-100\/40 {
  background-color: #ffe4e666;
}

.bg-rose-100\/45 {
  background-color: #ffe4e673;
}

.bg-rose-100\/5 {
  background-color: #ffe4e60d;
}

.bg-rose-100\/50 {
  background-color: #ffe4e680;
}

.bg-rose-100\/55 {
  background-color: #ffe4e68c;
}

.bg-rose-100\/60 {
  background-color: #ffe4e699;
}

.bg-rose-100\/65 {
  background-color: #ffe4e6a6;
}

.bg-rose-100\/70 {
  background-color: #ffe4e6b3;
}

.bg-rose-100\/75 {
  background-color: #ffe4e6bf;
}

.bg-rose-100\/80 {
  background-color: #ffe4e6cc;
}

.bg-rose-100\/85 {
  background-color: #ffe4e6d9;
}

.bg-rose-100\/90 {
  background-color: #ffe4e6e6;
}

.bg-rose-100\/95 {
  background-color: #ffe4e6f2;
}

.bg-rose-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity, 1));
}

.bg-rose-200\/0 {
  background-color: #fecdd300;
}

.bg-rose-200\/10 {
  background-color: #fecdd31a;
}

.bg-rose-200\/100 {
  background-color: #fecdd3;
}

.bg-rose-200\/15 {
  background-color: #fecdd326;
}

.bg-rose-200\/20 {
  background-color: #fecdd333;
}

.bg-rose-200\/25 {
  background-color: #fecdd340;
}

.bg-rose-200\/30 {
  background-color: #fecdd34d;
}

.bg-rose-200\/35 {
  background-color: #fecdd359;
}

.bg-rose-200\/40 {
  background-color: #fecdd366;
}

.bg-rose-200\/45 {
  background-color: #fecdd373;
}

.bg-rose-200\/5 {
  background-color: #fecdd30d;
}

.bg-rose-200\/50 {
  background-color: #fecdd380;
}

.bg-rose-200\/55 {
  background-color: #fecdd38c;
}

.bg-rose-200\/60 {
  background-color: #fecdd399;
}

.bg-rose-200\/65 {
  background-color: #fecdd3a6;
}

.bg-rose-200\/70 {
  background-color: #fecdd3b3;
}

.bg-rose-200\/75 {
  background-color: #fecdd3bf;
}

.bg-rose-200\/80 {
  background-color: #fecdd3cc;
}

.bg-rose-200\/85 {
  background-color: #fecdd3d9;
}

.bg-rose-200\/90 {
  background-color: #fecdd3e6;
}

.bg-rose-200\/95 {
  background-color: #fecdd3f2;
}

.bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity, 1));
}

.bg-rose-500\/0 {
  background-color: #f43f5e00;
}

.bg-rose-500\/10 {
  background-color: #f43f5e1a;
}

.bg-rose-500\/100 {
  background-color: #f43f5e;
}

.bg-rose-500\/15 {
  background-color: #f43f5e26;
}

.bg-rose-500\/20 {
  background-color: #f43f5e33;
}

.bg-rose-500\/25 {
  background-color: #f43f5e40;
}

.bg-rose-500\/30 {
  background-color: #f43f5e4d;
}

.bg-rose-500\/35 {
  background-color: #f43f5e59;
}

.bg-rose-500\/40 {
  background-color: #f43f5e66;
}

.bg-rose-500\/45 {
  background-color: #f43f5e73;
}

.bg-rose-500\/5 {
  background-color: #f43f5e0d;
}

.bg-rose-500\/50 {
  background-color: #f43f5e80;
}

.bg-rose-500\/55 {
  background-color: #f43f5e8c;
}

.bg-rose-500\/60 {
  background-color: #f43f5e99;
}

.bg-rose-500\/65 {
  background-color: #f43f5ea6;
}

.bg-rose-500\/70 {
  background-color: #f43f5eb3;
}

.bg-rose-500\/75 {
  background-color: #f43f5ebf;
}

.bg-rose-500\/80 {
  background-color: #f43f5ecc;
}

.bg-rose-500\/85 {
  background-color: #f43f5ed9;
}

.bg-rose-500\/90 {
  background-color: #f43f5ee6;
}

.bg-rose-500\/95 {
  background-color: #f43f5ef2;
}

.bg-rose-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 18 57 / var(--tw-bg-opacity, 1));
}

.bg-rose-800\/0 {
  background-color: #9f123900;
}

.bg-rose-800\/10 {
  background-color: #9f12391a;
}

.bg-rose-800\/100 {
  background-color: #9f1239;
}

.bg-rose-800\/15 {
  background-color: #9f123926;
}

.bg-rose-800\/20 {
  background-color: #9f123933;
}

.bg-rose-800\/25 {
  background-color: #9f123940;
}

.bg-rose-800\/30 {
  background-color: #9f12394d;
}

.bg-rose-800\/35 {
  background-color: #9f123959;
}

.bg-rose-800\/40 {
  background-color: #9f123966;
}

.bg-rose-800\/45 {
  background-color: #9f123973;
}

.bg-rose-800\/5 {
  background-color: #9f12390d;
}

.bg-rose-800\/50 {
  background-color: #9f123980;
}

.bg-rose-800\/55 {
  background-color: #9f12398c;
}

.bg-rose-800\/60 {
  background-color: #9f123999;
}

.bg-rose-800\/65 {
  background-color: #9f1239a6;
}

.bg-rose-800\/70 {
  background-color: #9f1239b3;
}

.bg-rose-800\/75 {
  background-color: #9f1239bf;
}

.bg-rose-800\/80 {
  background-color: #9f1239cc;
}

.bg-rose-800\/85 {
  background-color: #9f1239d9;
}

.bg-rose-800\/90 {
  background-color: #9f1239e6;
}

.bg-rose-800\/95 {
  background-color: #9f1239f2;
}

.bg-rose-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(136 19 55 / var(--tw-bg-opacity, 1));
}

.bg-rose-900\/0 {
  background-color: #88133700;
}

.bg-rose-900\/10 {
  background-color: #8813371a;
}

.bg-rose-900\/100 {
  background-color: #881337;
}

.bg-rose-900\/15 {
  background-color: #88133726;
}

.bg-rose-900\/20 {
  background-color: #88133733;
}

.bg-rose-900\/25 {
  background-color: #88133740;
}

.bg-rose-900\/30 {
  background-color: #8813374d;
}

.bg-rose-900\/35 {
  background-color: #88133759;
}

.bg-rose-900\/40 {
  background-color: #88133766;
}

.bg-rose-900\/45 {
  background-color: #88133773;
}

.bg-rose-900\/5 {
  background-color: #8813370d;
}

.bg-rose-900\/50 {
  background-color: #88133780;
}

.bg-rose-900\/55 {
  background-color: #8813378c;
}

.bg-rose-900\/60 {
  background-color: #88133799;
}

.bg-rose-900\/65 {
  background-color: #881337a6;
}

.bg-rose-900\/70 {
  background-color: #881337b3;
}

.bg-rose-900\/75 {
  background-color: #881337bf;
}

.bg-rose-900\/80 {
  background-color: #881337cc;
}

.bg-rose-900\/85 {
  background-color: #881337d9;
}

.bg-rose-900\/90 {
  background-color: #881337e6;
}

.bg-rose-900\/95 {
  background-color: #881337f2;
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.bg-sky-100\/0 {
  background-color: #e0f2fe00;
}

.bg-sky-100\/10 {
  background-color: #e0f2fe1a;
}

.bg-sky-100\/100 {
  background-color: #e0f2fe;
}

.bg-sky-100\/15 {
  background-color: #e0f2fe26;
}

.bg-sky-100\/20 {
  background-color: #e0f2fe33;
}

.bg-sky-100\/25 {
  background-color: #e0f2fe40;
}

.bg-sky-100\/30 {
  background-color: #e0f2fe4d;
}

.bg-sky-100\/35 {
  background-color: #e0f2fe59;
}

.bg-sky-100\/40 {
  background-color: #e0f2fe66;
}

.bg-sky-100\/45 {
  background-color: #e0f2fe73;
}

.bg-sky-100\/5 {
  background-color: #e0f2fe0d;
}

.bg-sky-100\/50 {
  background-color: #e0f2fe80;
}

.bg-sky-100\/55 {
  background-color: #e0f2fe8c;
}

.bg-sky-100\/60 {
  background-color: #e0f2fe99;
}

.bg-sky-100\/65 {
  background-color: #e0f2fea6;
}

.bg-sky-100\/70 {
  background-color: #e0f2feb3;
}

.bg-sky-100\/75 {
  background-color: #e0f2febf;
}

.bg-sky-100\/80 {
  background-color: #e0f2fecc;
}

.bg-sky-100\/85 {
  background-color: #e0f2fed9;
}

.bg-sky-100\/90 {
  background-color: #e0f2fee6;
}

.bg-sky-100\/95 {
  background-color: #e0f2fef2;
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity, 1));
}

.bg-sky-200\/0 {
  background-color: #bae6fd00;
}

.bg-sky-200\/10 {
  background-color: #bae6fd1a;
}

.bg-sky-200\/100 {
  background-color: #bae6fd;
}

.bg-sky-200\/15 {
  background-color: #bae6fd26;
}

.bg-sky-200\/20 {
  background-color: #bae6fd33;
}

.bg-sky-200\/25 {
  background-color: #bae6fd40;
}

.bg-sky-200\/30 {
  background-color: #bae6fd4d;
}

.bg-sky-200\/35 {
  background-color: #bae6fd59;
}

.bg-sky-200\/40 {
  background-color: #bae6fd66;
}

.bg-sky-200\/45 {
  background-color: #bae6fd73;
}

.bg-sky-200\/5 {
  background-color: #bae6fd0d;
}

.bg-sky-200\/50 {
  background-color: #bae6fd80;
}

.bg-sky-200\/55 {
  background-color: #bae6fd8c;
}

.bg-sky-200\/60 {
  background-color: #bae6fd99;
}

.bg-sky-200\/65 {
  background-color: #bae6fda6;
}

.bg-sky-200\/70 {
  background-color: #bae6fdb3;
}

.bg-sky-200\/75 {
  background-color: #bae6fdbf;
}

.bg-sky-200\/80 {
  background-color: #bae6fdcc;
}

.bg-sky-200\/85 {
  background-color: #bae6fdd9;
}

.bg-sky-200\/90 {
  background-color: #bae6fde6;
}

.bg-sky-200\/95 {
  background-color: #bae6fdf2;
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity, 1));
}

.bg-sky-500\/0 {
  background-color: #0ea5e900;
}

.bg-sky-500\/10 {
  background-color: #0ea5e91a;
}

.bg-sky-500\/100 {
  background-color: #0ea5e9;
}

.bg-sky-500\/15 {
  background-color: #0ea5e926;
}

.bg-sky-500\/20 {
  background-color: #0ea5e933;
}

.bg-sky-500\/25 {
  background-color: #0ea5e940;
}

.bg-sky-500\/30 {
  background-color: #0ea5e94d;
}

.bg-sky-500\/35 {
  background-color: #0ea5e959;
}

.bg-sky-500\/40 {
  background-color: #0ea5e966;
}

.bg-sky-500\/45 {
  background-color: #0ea5e973;
}

.bg-sky-500\/5 {
  background-color: #0ea5e90d;
}

.bg-sky-500\/50 {
  background-color: #0ea5e980;
}

.bg-sky-500\/55 {
  background-color: #0ea5e98c;
}

.bg-sky-500\/60 {
  background-color: #0ea5e999;
}

.bg-sky-500\/65 {
  background-color: #0ea5e9a6;
}

.bg-sky-500\/70 {
  background-color: #0ea5e9b3;
}

.bg-sky-500\/75 {
  background-color: #0ea5e9bf;
}

.bg-sky-500\/80 {
  background-color: #0ea5e9cc;
}

.bg-sky-500\/85 {
  background-color: #0ea5e9d9;
}

.bg-sky-500\/90 {
  background-color: #0ea5e9e6;
}

.bg-sky-500\/95 {
  background-color: #0ea5e9f2;
}

.bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity, 1));
}

.bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity, 1));
}

.bg-sky-800\/0 {
  background-color: #07598500;
}

.bg-sky-800\/10 {
  background-color: #0759851a;
}

.bg-sky-800\/100 {
  background-color: #075985;
}

.bg-sky-800\/15 {
  background-color: #07598526;
}

.bg-sky-800\/20 {
  background-color: #07598533;
}

.bg-sky-800\/25 {
  background-color: #07598540;
}

.bg-sky-800\/30 {
  background-color: #0759854d;
}

.bg-sky-800\/35 {
  background-color: #07598559;
}

.bg-sky-800\/40 {
  background-color: #07598566;
}

.bg-sky-800\/45 {
  background-color: #07598573;
}

.bg-sky-800\/5 {
  background-color: #0759850d;
}

.bg-sky-800\/50 {
  background-color: #07598580;
}

.bg-sky-800\/55 {
  background-color: #0759858c;
}

.bg-sky-800\/60 {
  background-color: #07598599;
}

.bg-sky-800\/65 {
  background-color: #075985a6;
}

.bg-sky-800\/70 {
  background-color: #075985b3;
}

.bg-sky-800\/75 {
  background-color: #075985bf;
}

.bg-sky-800\/80 {
  background-color: #075985cc;
}

.bg-sky-800\/85 {
  background-color: #075985d9;
}

.bg-sky-800\/90 {
  background-color: #075985e6;
}

.bg-sky-800\/95 {
  background-color: #075985f2;
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity, 1));
}

.bg-sky-900\/0 {
  background-color: #0c4a6e00;
}

.bg-sky-900\/10 {
  background-color: #0c4a6e1a;
}

.bg-sky-900\/100 {
  background-color: #0c4a6e;
}

.bg-sky-900\/15 {
  background-color: #0c4a6e26;
}

.bg-sky-900\/20 {
  background-color: #0c4a6e33;
}

.bg-sky-900\/25 {
  background-color: #0c4a6e40;
}

.bg-sky-900\/30 {
  background-color: #0c4a6e4d;
}

.bg-sky-900\/35 {
  background-color: #0c4a6e59;
}

.bg-sky-900\/40 {
  background-color: #0c4a6e66;
}

.bg-sky-900\/45 {
  background-color: #0c4a6e73;
}

.bg-sky-900\/5 {
  background-color: #0c4a6e0d;
}

.bg-sky-900\/50 {
  background-color: #0c4a6e80;
}

.bg-sky-900\/55 {
  background-color: #0c4a6e8c;
}

.bg-sky-900\/60 {
  background-color: #0c4a6e99;
}

.bg-sky-900\/65 {
  background-color: #0c4a6ea6;
}

.bg-sky-900\/70 {
  background-color: #0c4a6eb3;
}

.bg-sky-900\/75 {
  background-color: #0c4a6ebf;
}

.bg-sky-900\/80 {
  background-color: #0c4a6ecc;
}

.bg-sky-900\/85 {
  background-color: #0c4a6ed9;
}

.bg-sky-900\/90 {
  background-color: #0c4a6ee6;
}

.bg-sky-900\/95 {
  background-color: #0c4a6ef2;
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity, 1));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity, 1));
}

.bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity, 1));
}

.bg-stone-100\/0 {
  background-color: #f5f5f400;
}

.bg-stone-100\/10 {
  background-color: #f5f5f41a;
}

.bg-stone-100\/100 {
  background-color: #f5f5f4;
}

.bg-stone-100\/15 {
  background-color: #f5f5f426;
}

.bg-stone-100\/20 {
  background-color: #f5f5f433;
}

.bg-stone-100\/25 {
  background-color: #f5f5f440;
}

.bg-stone-100\/30 {
  background-color: #f5f5f44d;
}

.bg-stone-100\/35 {
  background-color: #f5f5f459;
}

.bg-stone-100\/40 {
  background-color: #f5f5f466;
}

.bg-stone-100\/45 {
  background-color: #f5f5f473;
}

.bg-stone-100\/5 {
  background-color: #f5f5f40d;
}

.bg-stone-100\/50 {
  background-color: #f5f5f480;
}

.bg-stone-100\/55 {
  background-color: #f5f5f48c;
}

.bg-stone-100\/60 {
  background-color: #f5f5f499;
}

.bg-stone-100\/65 {
  background-color: #f5f5f4a6;
}

.bg-stone-100\/70 {
  background-color: #f5f5f4b3;
}

.bg-stone-100\/75 {
  background-color: #f5f5f4bf;
}

.bg-stone-100\/80 {
  background-color: #f5f5f4cc;
}

.bg-stone-100\/85 {
  background-color: #f5f5f4d9;
}

.bg-stone-100\/90 {
  background-color: #f5f5f4e6;
}

.bg-stone-100\/95 {
  background-color: #f5f5f4f2;
}

.bg-stone-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity, 1));
}

.bg-stone-200\/0 {
  background-color: #e7e5e400;
}

.bg-stone-200\/10 {
  background-color: #e7e5e41a;
}

.bg-stone-200\/100 {
  background-color: #e7e5e4;
}

.bg-stone-200\/15 {
  background-color: #e7e5e426;
}

.bg-stone-200\/20 {
  background-color: #e7e5e433;
}

.bg-stone-200\/25 {
  background-color: #e7e5e440;
}

.bg-stone-200\/30 {
  background-color: #e7e5e44d;
}

.bg-stone-200\/35 {
  background-color: #e7e5e459;
}

.bg-stone-200\/40 {
  background-color: #e7e5e466;
}

.bg-stone-200\/45 {
  background-color: #e7e5e473;
}

.bg-stone-200\/5 {
  background-color: #e7e5e40d;
}

.bg-stone-200\/50 {
  background-color: #e7e5e480;
}

.bg-stone-200\/55 {
  background-color: #e7e5e48c;
}

.bg-stone-200\/60 {
  background-color: #e7e5e499;
}

.bg-stone-200\/65 {
  background-color: #e7e5e4a6;
}

.bg-stone-200\/70 {
  background-color: #e7e5e4b3;
}

.bg-stone-200\/75 {
  background-color: #e7e5e4bf;
}

.bg-stone-200\/80 {
  background-color: #e7e5e4cc;
}

.bg-stone-200\/85 {
  background-color: #e7e5e4d9;
}

.bg-stone-200\/90 {
  background-color: #e7e5e4e6;
}

.bg-stone-200\/95 {
  background-color: #e7e5e4f2;
}

.bg-stone-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity, 1));
}

.bg-stone-500\/0 {
  background-color: #78716c00;
}

.bg-stone-500\/10 {
  background-color: #78716c1a;
}

.bg-stone-500\/100 {
  background-color: #78716c;
}

.bg-stone-500\/15 {
  background-color: #78716c26;
}

.bg-stone-500\/20 {
  background-color: #78716c33;
}

.bg-stone-500\/25 {
  background-color: #78716c40;
}

.bg-stone-500\/30 {
  background-color: #78716c4d;
}

.bg-stone-500\/35 {
  background-color: #78716c59;
}

.bg-stone-500\/40 {
  background-color: #78716c66;
}

.bg-stone-500\/45 {
  background-color: #78716c73;
}

.bg-stone-500\/5 {
  background-color: #78716c0d;
}

.bg-stone-500\/50 {
  background-color: #78716c80;
}

.bg-stone-500\/55 {
  background-color: #78716c8c;
}

.bg-stone-500\/60 {
  background-color: #78716c99;
}

.bg-stone-500\/65 {
  background-color: #78716ca6;
}

.bg-stone-500\/70 {
  background-color: #78716cb3;
}

.bg-stone-500\/75 {
  background-color: #78716cbf;
}

.bg-stone-500\/80 {
  background-color: #78716ccc;
}

.bg-stone-500\/85 {
  background-color: #78716cd9;
}

.bg-stone-500\/90 {
  background-color: #78716ce6;
}

.bg-stone-500\/95 {
  background-color: #78716cf2;
}

.bg-stone-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity, 1));
}

.bg-stone-800\/0 {
  background-color: #29252400;
}

.bg-stone-800\/10 {
  background-color: #2925241a;
}

.bg-stone-800\/100 {
  background-color: #292524;
}

.bg-stone-800\/15 {
  background-color: #29252426;
}

.bg-stone-800\/20 {
  background-color: #29252433;
}

.bg-stone-800\/25 {
  background-color: #29252440;
}

.bg-stone-800\/30 {
  background-color: #2925244d;
}

.bg-stone-800\/35 {
  background-color: #29252459;
}

.bg-stone-800\/40 {
  background-color: #29252466;
}

.bg-stone-800\/45 {
  background-color: #29252473;
}

.bg-stone-800\/5 {
  background-color: #2925240d;
}

.bg-stone-800\/50 {
  background-color: #29252480;
}

.bg-stone-800\/55 {
  background-color: #2925248c;
}

.bg-stone-800\/60 {
  background-color: #29252499;
}

.bg-stone-800\/65 {
  background-color: #292524a6;
}

.bg-stone-800\/70 {
  background-color: #292524b3;
}

.bg-stone-800\/75 {
  background-color: #292524bf;
}

.bg-stone-800\/80 {
  background-color: #292524cc;
}

.bg-stone-800\/85 {
  background-color: #292524d9;
}

.bg-stone-800\/90 {
  background-color: #292524e6;
}

.bg-stone-800\/95 {
  background-color: #292524f2;
}

.bg-stone-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity, 1));
}

.bg-stone-900\/0 {
  background-color: #1c191700;
}

.bg-stone-900\/10 {
  background-color: #1c19171a;
}

.bg-stone-900\/100 {
  background-color: #1c1917;
}

.bg-stone-900\/15 {
  background-color: #1c191726;
}

.bg-stone-900\/20 {
  background-color: #1c191733;
}

.bg-stone-900\/25 {
  background-color: #1c191740;
}

.bg-stone-900\/30 {
  background-color: #1c19174d;
}

.bg-stone-900\/35 {
  background-color: #1c191759;
}

.bg-stone-900\/40 {
  background-color: #1c191766;
}

.bg-stone-900\/45 {
  background-color: #1c191773;
}

.bg-stone-900\/5 {
  background-color: #1c19170d;
}

.bg-stone-900\/50 {
  background-color: #1c191780;
}

.bg-stone-900\/55 {
  background-color: #1c19178c;
}

.bg-stone-900\/60 {
  background-color: #1c191799;
}

.bg-stone-900\/65 {
  background-color: #1c1917a6;
}

.bg-stone-900\/70 {
  background-color: #1c1917b3;
}

.bg-stone-900\/75 {
  background-color: #1c1917bf;
}

.bg-stone-900\/80 {
  background-color: #1c1917cc;
}

.bg-stone-900\/85 {
  background-color: #1c1917d9;
}

.bg-stone-900\/90 {
  background-color: #1c1917e6;
}

.bg-stone-900\/95 {
  background-color: #1c1917f2;
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity, 1));
}

.bg-teal-100\/0 {
  background-color: #ccfbf100;
}

.bg-teal-100\/10 {
  background-color: #ccfbf11a;
}

.bg-teal-100\/100 {
  background-color: #ccfbf1;
}

.bg-teal-100\/15 {
  background-color: #ccfbf126;
}

.bg-teal-100\/20 {
  background-color: #ccfbf133;
}

.bg-teal-100\/25 {
  background-color: #ccfbf140;
}

.bg-teal-100\/30 {
  background-color: #ccfbf14d;
}

.bg-teal-100\/35 {
  background-color: #ccfbf159;
}

.bg-teal-100\/40 {
  background-color: #ccfbf166;
}

.bg-teal-100\/45 {
  background-color: #ccfbf173;
}

.bg-teal-100\/5 {
  background-color: #ccfbf10d;
}

.bg-teal-100\/50 {
  background-color: #ccfbf180;
}

.bg-teal-100\/55 {
  background-color: #ccfbf18c;
}

.bg-teal-100\/60 {
  background-color: #ccfbf199;
}

.bg-teal-100\/65 {
  background-color: #ccfbf1a6;
}

.bg-teal-100\/70 {
  background-color: #ccfbf1b3;
}

.bg-teal-100\/75 {
  background-color: #ccfbf1bf;
}

.bg-teal-100\/80 {
  background-color: #ccfbf1cc;
}

.bg-teal-100\/85 {
  background-color: #ccfbf1d9;
}

.bg-teal-100\/90 {
  background-color: #ccfbf1e6;
}

.bg-teal-100\/95 {
  background-color: #ccfbf1f2;
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity, 1));
}

.bg-teal-200\/0 {
  background-color: #99f6e400;
}

.bg-teal-200\/10 {
  background-color: #99f6e41a;
}

.bg-teal-200\/100 {
  background-color: #99f6e4;
}

.bg-teal-200\/15 {
  background-color: #99f6e426;
}

.bg-teal-200\/20 {
  background-color: #99f6e433;
}

.bg-teal-200\/25 {
  background-color: #99f6e440;
}

.bg-teal-200\/30 {
  background-color: #99f6e44d;
}

.bg-teal-200\/35 {
  background-color: #99f6e459;
}

.bg-teal-200\/40 {
  background-color: #99f6e466;
}

.bg-teal-200\/45 {
  background-color: #99f6e473;
}

.bg-teal-200\/5 {
  background-color: #99f6e40d;
}

.bg-teal-200\/50 {
  background-color: #99f6e480;
}

.bg-teal-200\/55 {
  background-color: #99f6e48c;
}

.bg-teal-200\/60 {
  background-color: #99f6e499;
}

.bg-teal-200\/65 {
  background-color: #99f6e4a6;
}

.bg-teal-200\/70 {
  background-color: #99f6e4b3;
}

.bg-teal-200\/75 {
  background-color: #99f6e4bf;
}

.bg-teal-200\/80 {
  background-color: #99f6e4cc;
}

.bg-teal-200\/85 {
  background-color: #99f6e4d9;
}

.bg-teal-200\/90 {
  background-color: #99f6e4e6;
}

.bg-teal-200\/95 {
  background-color: #99f6e4f2;
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity, 1));
}

.bg-teal-500\/0 {
  background-color: #14b8a600;
}

.bg-teal-500\/10 {
  background-color: #14b8a61a;
}

.bg-teal-500\/100 {
  background-color: #14b8a6;
}

.bg-teal-500\/15 {
  background-color: #14b8a626;
}

.bg-teal-500\/20 {
  background-color: #14b8a633;
}

.bg-teal-500\/25 {
  background-color: #14b8a640;
}

.bg-teal-500\/30 {
  background-color: #14b8a64d;
}

.bg-teal-500\/35 {
  background-color: #14b8a659;
}

.bg-teal-500\/40 {
  background-color: #14b8a666;
}

.bg-teal-500\/45 {
  background-color: #14b8a673;
}

.bg-teal-500\/5 {
  background-color: #14b8a60d;
}

.bg-teal-500\/50 {
  background-color: #14b8a680;
}

.bg-teal-500\/55 {
  background-color: #14b8a68c;
}

.bg-teal-500\/60 {
  background-color: #14b8a699;
}

.bg-teal-500\/65 {
  background-color: #14b8a6a6;
}

.bg-teal-500\/70 {
  background-color: #14b8a6b3;
}

.bg-teal-500\/75 {
  background-color: #14b8a6bf;
}

.bg-teal-500\/80 {
  background-color: #14b8a6cc;
}

.bg-teal-500\/85 {
  background-color: #14b8a6d9;
}

.bg-teal-500\/90 {
  background-color: #14b8a6e6;
}

.bg-teal-500\/95 {
  background-color: #14b8a6f2;
}

.bg-teal-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 94 89 / var(--tw-bg-opacity, 1));
}

.bg-teal-800\/0 {
  background-color: #115e5900;
}

.bg-teal-800\/10 {
  background-color: #115e591a;
}

.bg-teal-800\/100 {
  background-color: #115e59;
}

.bg-teal-800\/15 {
  background-color: #115e5926;
}

.bg-teal-800\/20 {
  background-color: #115e5933;
}

.bg-teal-800\/25 {
  background-color: #115e5940;
}

.bg-teal-800\/30 {
  background-color: #115e594d;
}

.bg-teal-800\/35 {
  background-color: #115e5959;
}

.bg-teal-800\/40 {
  background-color: #115e5966;
}

.bg-teal-800\/45 {
  background-color: #115e5973;
}

.bg-teal-800\/5 {
  background-color: #115e590d;
}

.bg-teal-800\/50 {
  background-color: #115e5980;
}

.bg-teal-800\/55 {
  background-color: #115e598c;
}

.bg-teal-800\/60 {
  background-color: #115e5999;
}

.bg-teal-800\/65 {
  background-color: #115e59a6;
}

.bg-teal-800\/70 {
  background-color: #115e59b3;
}

.bg-teal-800\/75 {
  background-color: #115e59bf;
}

.bg-teal-800\/80 {
  background-color: #115e59cc;
}

.bg-teal-800\/85 {
  background-color: #115e59d9;
}

.bg-teal-800\/90 {
  background-color: #115e59e6;
}

.bg-teal-800\/95 {
  background-color: #115e59f2;
}

.bg-teal-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(19 78 74 / var(--tw-bg-opacity, 1));
}

.bg-teal-900\/0 {
  background-color: #134e4a00;
}

.bg-teal-900\/10 {
  background-color: #134e4a1a;
}

.bg-teal-900\/100 {
  background-color: #134e4a;
}

.bg-teal-900\/15 {
  background-color: #134e4a26;
}

.bg-teal-900\/20 {
  background-color: #134e4a33;
}

.bg-teal-900\/25 {
  background-color: #134e4a40;
}

.bg-teal-900\/30 {
  background-color: #134e4a4d;
}

.bg-teal-900\/35 {
  background-color: #134e4a59;
}

.bg-teal-900\/40 {
  background-color: #134e4a66;
}

.bg-teal-900\/45 {
  background-color: #134e4a73;
}

.bg-teal-900\/5 {
  background-color: #134e4a0d;
}

.bg-teal-900\/50 {
  background-color: #134e4a80;
}

.bg-teal-900\/55 {
  background-color: #134e4a8c;
}

.bg-teal-900\/60 {
  background-color: #134e4a99;
}

.bg-teal-900\/65 {
  background-color: #134e4aa6;
}

.bg-teal-900\/70 {
  background-color: #134e4ab3;
}

.bg-teal-900\/75 {
  background-color: #134e4abf;
}

.bg-teal-900\/80 {
  background-color: #134e4acc;
}

.bg-teal-900\/85 {
  background-color: #134e4ad9;
}

.bg-teal-900\/90 {
  background-color: #134e4ae6;
}

.bg-teal-900\/95 {
  background-color: #134e4af2;
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity, 1));
}

.bg-violet-100\/0 {
  background-color: #ede9fe00;
}

.bg-violet-100\/10 {
  background-color: #ede9fe1a;
}

.bg-violet-100\/100 {
  background-color: #ede9fe;
}

.bg-violet-100\/15 {
  background-color: #ede9fe26;
}

.bg-violet-100\/20 {
  background-color: #ede9fe33;
}

.bg-violet-100\/25 {
  background-color: #ede9fe40;
}

.bg-violet-100\/30 {
  background-color: #ede9fe4d;
}

.bg-violet-100\/35 {
  background-color: #ede9fe59;
}

.bg-violet-100\/40 {
  background-color: #ede9fe66;
}

.bg-violet-100\/45 {
  background-color: #ede9fe73;
}

.bg-violet-100\/5 {
  background-color: #ede9fe0d;
}

.bg-violet-100\/50 {
  background-color: #ede9fe80;
}

.bg-violet-100\/55 {
  background-color: #ede9fe8c;
}

.bg-violet-100\/60 {
  background-color: #ede9fe99;
}

.bg-violet-100\/65 {
  background-color: #ede9fea6;
}

.bg-violet-100\/70 {
  background-color: #ede9feb3;
}

.bg-violet-100\/75 {
  background-color: #ede9febf;
}

.bg-violet-100\/80 {
  background-color: #ede9fecc;
}

.bg-violet-100\/85 {
  background-color: #ede9fed9;
}

.bg-violet-100\/90 {
  background-color: #ede9fee6;
}

.bg-violet-100\/95 {
  background-color: #ede9fef2;
}

.bg-violet-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity, 1));
}

.bg-violet-200\/0 {
  background-color: #ddd6fe00;
}

.bg-violet-200\/10 {
  background-color: #ddd6fe1a;
}

.bg-violet-200\/100 {
  background-color: #ddd6fe;
}

.bg-violet-200\/15 {
  background-color: #ddd6fe26;
}

.bg-violet-200\/20 {
  background-color: #ddd6fe33;
}

.bg-violet-200\/25 {
  background-color: #ddd6fe40;
}

.bg-violet-200\/30 {
  background-color: #ddd6fe4d;
}

.bg-violet-200\/35 {
  background-color: #ddd6fe59;
}

.bg-violet-200\/40 {
  background-color: #ddd6fe66;
}

.bg-violet-200\/45 {
  background-color: #ddd6fe73;
}

.bg-violet-200\/5 {
  background-color: #ddd6fe0d;
}

.bg-violet-200\/50 {
  background-color: #ddd6fe80;
}

.bg-violet-200\/55 {
  background-color: #ddd6fe8c;
}

.bg-violet-200\/60 {
  background-color: #ddd6fe99;
}

.bg-violet-200\/65 {
  background-color: #ddd6fea6;
}

.bg-violet-200\/70 {
  background-color: #ddd6feb3;
}

.bg-violet-200\/75 {
  background-color: #ddd6febf;
}

.bg-violet-200\/80 {
  background-color: #ddd6fecc;
}

.bg-violet-200\/85 {
  background-color: #ddd6fed9;
}

.bg-violet-200\/90 {
  background-color: #ddd6fee6;
}

.bg-violet-200\/95 {
  background-color: #ddd6fef2;
}

.bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity, 1));
}

.bg-violet-500\/0 {
  background-color: #8b5cf600;
}

.bg-violet-500\/10 {
  background-color: #8b5cf61a;
}

.bg-violet-500\/100 {
  background-color: #8b5cf6;
}

.bg-violet-500\/15 {
  background-color: #8b5cf626;
}

.bg-violet-500\/20 {
  background-color: #8b5cf633;
}

.bg-violet-500\/25 {
  background-color: #8b5cf640;
}

.bg-violet-500\/30 {
  background-color: #8b5cf64d;
}

.bg-violet-500\/35 {
  background-color: #8b5cf659;
}

.bg-violet-500\/40 {
  background-color: #8b5cf666;
}

.bg-violet-500\/45 {
  background-color: #8b5cf673;
}

.bg-violet-500\/5 {
  background-color: #8b5cf60d;
}

.bg-violet-500\/50 {
  background-color: #8b5cf680;
}

.bg-violet-500\/55 {
  background-color: #8b5cf68c;
}

.bg-violet-500\/60 {
  background-color: #8b5cf699;
}

.bg-violet-500\/65 {
  background-color: #8b5cf6a6;
}

.bg-violet-500\/70 {
  background-color: #8b5cf6b3;
}

.bg-violet-500\/75 {
  background-color: #8b5cf6bf;
}

.bg-violet-500\/80 {
  background-color: #8b5cf6cc;
}

.bg-violet-500\/85 {
  background-color: #8b5cf6d9;
}

.bg-violet-500\/90 {
  background-color: #8b5cf6e6;
}

.bg-violet-500\/95 {
  background-color: #8b5cf6f2;
}

.bg-violet-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity, 1));
}

.bg-violet-800\/0 {
  background-color: #5b21b600;
}

.bg-violet-800\/10 {
  background-color: #5b21b61a;
}

.bg-violet-800\/100 {
  background-color: #5b21b6;
}

.bg-violet-800\/15 {
  background-color: #5b21b626;
}

.bg-violet-800\/20 {
  background-color: #5b21b633;
}

.bg-violet-800\/25 {
  background-color: #5b21b640;
}

.bg-violet-800\/30 {
  background-color: #5b21b64d;
}

.bg-violet-800\/35 {
  background-color: #5b21b659;
}

.bg-violet-800\/40 {
  background-color: #5b21b666;
}

.bg-violet-800\/45 {
  background-color: #5b21b673;
}

.bg-violet-800\/5 {
  background-color: #5b21b60d;
}

.bg-violet-800\/50 {
  background-color: #5b21b680;
}

.bg-violet-800\/55 {
  background-color: #5b21b68c;
}

.bg-violet-800\/60 {
  background-color: #5b21b699;
}

.bg-violet-800\/65 {
  background-color: #5b21b6a6;
}

.bg-violet-800\/70 {
  background-color: #5b21b6b3;
}

.bg-violet-800\/75 {
  background-color: #5b21b6bf;
}

.bg-violet-800\/80 {
  background-color: #5b21b6cc;
}

.bg-violet-800\/85 {
  background-color: #5b21b6d9;
}

.bg-violet-800\/90 {
  background-color: #5b21b6e6;
}

.bg-violet-800\/95 {
  background-color: #5b21b6f2;
}

.bg-violet-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity, 1));
}

.bg-violet-900\/0 {
  background-color: #4c1d9500;
}

.bg-violet-900\/10 {
  background-color: #4c1d951a;
}

.bg-violet-900\/100 {
  background-color: #4c1d95;
}

.bg-violet-900\/15 {
  background-color: #4c1d9526;
}

.bg-violet-900\/20 {
  background-color: #4c1d9533;
}

.bg-violet-900\/25 {
  background-color: #4c1d9540;
}

.bg-violet-900\/30 {
  background-color: #4c1d954d;
}

.bg-violet-900\/35 {
  background-color: #4c1d9559;
}

.bg-violet-900\/40 {
  background-color: #4c1d9566;
}

.bg-violet-900\/45 {
  background-color: #4c1d9573;
}

.bg-violet-900\/5 {
  background-color: #4c1d950d;
}

.bg-violet-900\/50 {
  background-color: #4c1d9580;
}

.bg-violet-900\/55 {
  background-color: #4c1d958c;
}

.bg-violet-900\/60 {
  background-color: #4c1d9599;
}

.bg-violet-900\/65 {
  background-color: #4c1d95a6;
}

.bg-violet-900\/70 {
  background-color: #4c1d95b3;
}

.bg-violet-900\/75 {
  background-color: #4c1d95bf;
}

.bg-violet-900\/80 {
  background-color: #4c1d95cc;
}

.bg-violet-900\/85 {
  background-color: #4c1d95d9;
}

.bg-violet-900\/90 {
  background-color: #4c1d95e6;
}

.bg-violet-900\/95 {
  background-color: #4c1d95f2;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity, 1));
}

.bg-yellow-100\/0 {
  background-color: #fef9c300;
}

.bg-yellow-100\/10 {
  background-color: #fef9c31a;
}

.bg-yellow-100\/100 {
  background-color: #fef9c3;
}

.bg-yellow-100\/15 {
  background-color: #fef9c326;
}

.bg-yellow-100\/20 {
  background-color: #fef9c333;
}

.bg-yellow-100\/25 {
  background-color: #fef9c340;
}

.bg-yellow-100\/30 {
  background-color: #fef9c34d;
}

.bg-yellow-100\/35 {
  background-color: #fef9c359;
}

.bg-yellow-100\/40 {
  background-color: #fef9c366;
}

.bg-yellow-100\/45 {
  background-color: #fef9c373;
}

.bg-yellow-100\/5 {
  background-color: #fef9c30d;
}

.bg-yellow-100\/50 {
  background-color: #fef9c380;
}

.bg-yellow-100\/55 {
  background-color: #fef9c38c;
}

.bg-yellow-100\/60 {
  background-color: #fef9c399;
}

.bg-yellow-100\/65 {
  background-color: #fef9c3a6;
}

.bg-yellow-100\/70 {
  background-color: #fef9c3b3;
}

.bg-yellow-100\/75 {
  background-color: #fef9c3bf;
}

.bg-yellow-100\/80 {
  background-color: #fef9c3cc;
}

.bg-yellow-100\/85 {
  background-color: #fef9c3d9;
}

.bg-yellow-100\/90 {
  background-color: #fef9c3e6;
}

.bg-yellow-100\/95 {
  background-color: #fef9c3f2;
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity, 1));
}

.bg-yellow-200\/0 {
  background-color: #fef08a00;
}

.bg-yellow-200\/10 {
  background-color: #fef08a1a;
}

.bg-yellow-200\/100 {
  background-color: #fef08a;
}

.bg-yellow-200\/15 {
  background-color: #fef08a26;
}

.bg-yellow-200\/20 {
  background-color: #fef08a33;
}

.bg-yellow-200\/25 {
  background-color: #fef08a40;
}

.bg-yellow-200\/30 {
  background-color: #fef08a4d;
}

.bg-yellow-200\/35 {
  background-color: #fef08a59;
}

.bg-yellow-200\/40 {
  background-color: #fef08a66;
}

.bg-yellow-200\/45 {
  background-color: #fef08a73;
}

.bg-yellow-200\/5 {
  background-color: #fef08a0d;
}

.bg-yellow-200\/50 {
  background-color: #fef08a80;
}

.bg-yellow-200\/55 {
  background-color: #fef08a8c;
}

.bg-yellow-200\/60 {
  background-color: #fef08a99;
}

.bg-yellow-200\/65 {
  background-color: #fef08aa6;
}

.bg-yellow-200\/70 {
  background-color: #fef08ab3;
}

.bg-yellow-200\/75 {
  background-color: #fef08abf;
}

.bg-yellow-200\/80 {
  background-color: #fef08acc;
}

.bg-yellow-200\/85 {
  background-color: #fef08ad9;
}

.bg-yellow-200\/90 {
  background-color: #fef08ae6;
}

.bg-yellow-200\/95 {
  background-color: #fef08af2;
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
}

.bg-yellow-500\/0 {
  background-color: #eab30800;
}

.bg-yellow-500\/10 {
  background-color: #eab3081a;
}

.bg-yellow-500\/100 {
  background-color: #eab308;
}

.bg-yellow-500\/15 {
  background-color: #eab30826;
}

.bg-yellow-500\/20 {
  background-color: #eab30833;
}

.bg-yellow-500\/25 {
  background-color: #eab30840;
}

.bg-yellow-500\/30 {
  background-color: #eab3084d;
}

.bg-yellow-500\/35 {
  background-color: #eab30859;
}

.bg-yellow-500\/40 {
  background-color: #eab30866;
}

.bg-yellow-500\/45 {
  background-color: #eab30873;
}

.bg-yellow-500\/5 {
  background-color: #eab3080d;
}

.bg-yellow-500\/50 {
  background-color: #eab30880;
}

.bg-yellow-500\/55 {
  background-color: #eab3088c;
}

.bg-yellow-500\/60 {
  background-color: #eab30899;
}

.bg-yellow-500\/65 {
  background-color: #eab308a6;
}

.bg-yellow-500\/70 {
  background-color: #eab308b3;
}

.bg-yellow-500\/75 {
  background-color: #eab308bf;
}

.bg-yellow-500\/80 {
  background-color: #eab308cc;
}

.bg-yellow-500\/85 {
  background-color: #eab308d9;
}

.bg-yellow-500\/90 {
  background-color: #eab308e6;
}

.bg-yellow-500\/95 {
  background-color: #eab308f2;
}

.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(133 77 14 / var(--tw-bg-opacity, 1));
}

.bg-yellow-800\/0 {
  background-color: #854d0e00;
}

.bg-yellow-800\/10 {
  background-color: #854d0e1a;
}

.bg-yellow-800\/100 {
  background-color: #854d0e;
}

.bg-yellow-800\/15 {
  background-color: #854d0e26;
}

.bg-yellow-800\/20 {
  background-color: #854d0e33;
}

.bg-yellow-800\/25 {
  background-color: #854d0e40;
}

.bg-yellow-800\/30 {
  background-color: #854d0e4d;
}

.bg-yellow-800\/35 {
  background-color: #854d0e59;
}

.bg-yellow-800\/40 {
  background-color: #854d0e66;
}

.bg-yellow-800\/45 {
  background-color: #854d0e73;
}

.bg-yellow-800\/5 {
  background-color: #854d0e0d;
}

.bg-yellow-800\/50 {
  background-color: #854d0e80;
}

.bg-yellow-800\/55 {
  background-color: #854d0e8c;
}

.bg-yellow-800\/60 {
  background-color: #854d0e99;
}

.bg-yellow-800\/65 {
  background-color: #854d0ea6;
}

.bg-yellow-800\/70 {
  background-color: #854d0eb3;
}

.bg-yellow-800\/75 {
  background-color: #854d0ebf;
}

.bg-yellow-800\/80 {
  background-color: #854d0ecc;
}

.bg-yellow-800\/85 {
  background-color: #854d0ed9;
}

.bg-yellow-800\/90 {
  background-color: #854d0ee6;
}

.bg-yellow-800\/95 {
  background-color: #854d0ef2;
}

.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 63 18 / var(--tw-bg-opacity, 1));
}

.bg-yellow-900\/0 {
  background-color: #713f1200;
}

.bg-yellow-900\/10 {
  background-color: #713f121a;
}

.bg-yellow-900\/100 {
  background-color: #713f12;
}

.bg-yellow-900\/15 {
  background-color: #713f1226;
}

.bg-yellow-900\/20 {
  background-color: #713f1233;
}

.bg-yellow-900\/25 {
  background-color: #713f1240;
}

.bg-yellow-900\/30 {
  background-color: #713f124d;
}

.bg-yellow-900\/35 {
  background-color: #713f1259;
}

.bg-yellow-900\/40 {
  background-color: #713f1266;
}

.bg-yellow-900\/45 {
  background-color: #713f1273;
}

.bg-yellow-900\/5 {
  background-color: #713f120d;
}

.bg-yellow-900\/50 {
  background-color: #713f1280;
}

.bg-yellow-900\/55 {
  background-color: #713f128c;
}

.bg-yellow-900\/60 {
  background-color: #713f1299;
}

.bg-yellow-900\/65 {
  background-color: #713f12a6;
}

.bg-yellow-900\/70 {
  background-color: #713f12b3;
}

.bg-yellow-900\/75 {
  background-color: #713f12bf;
}

.bg-yellow-900\/80 {
  background-color: #713f12cc;
}

.bg-yellow-900\/85 {
  background-color: #713f12d9;
}

.bg-yellow-900\/90 {
  background-color: #713f12e6;
}

.bg-yellow-900\/95 {
  background-color: #713f12f2;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.stroke-white {
  stroke: #fff;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity, 1));
}

.text-amber-100\/0 {
  color: #fef3c700;
}

.text-amber-100\/10 {
  color: #fef3c71a;
}

.text-amber-100\/100 {
  color: #fef3c7;
}

.text-amber-100\/15 {
  color: #fef3c726;
}

.text-amber-100\/20 {
  color: #fef3c733;
}

.text-amber-100\/25 {
  color: #fef3c740;
}

.text-amber-100\/30 {
  color: #fef3c74d;
}

.text-amber-100\/35 {
  color: #fef3c759;
}

.text-amber-100\/40 {
  color: #fef3c766;
}

.text-amber-100\/45 {
  color: #fef3c773;
}

.text-amber-100\/5 {
  color: #fef3c70d;
}

.text-amber-100\/50 {
  color: #fef3c780;
}

.text-amber-100\/55 {
  color: #fef3c78c;
}

.text-amber-100\/60 {
  color: #fef3c799;
}

.text-amber-100\/65 {
  color: #fef3c7a6;
}

.text-amber-100\/70 {
  color: #fef3c7b3;
}

.text-amber-100\/75 {
  color: #fef3c7bf;
}

.text-amber-100\/80 {
  color: #fef3c7cc;
}

.text-amber-100\/85 {
  color: #fef3c7d9;
}

.text-amber-100\/90 {
  color: #fef3c7e6;
}

.text-amber-100\/95 {
  color: #fef3c7f2;
}

.text-amber-200 {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity, 1));
}

.text-amber-200\/0 {
  color: #fde68a00;
}

.text-amber-200\/10 {
  color: #fde68a1a;
}

.text-amber-200\/100 {
  color: #fde68a;
}

.text-amber-200\/15 {
  color: #fde68a26;
}

.text-amber-200\/20 {
  color: #fde68a33;
}

.text-amber-200\/25 {
  color: #fde68a40;
}

.text-amber-200\/30 {
  color: #fde68a4d;
}

.text-amber-200\/35 {
  color: #fde68a59;
}

.text-amber-200\/40 {
  color: #fde68a66;
}

.text-amber-200\/45 {
  color: #fde68a73;
}

.text-amber-200\/5 {
  color: #fde68a0d;
}

.text-amber-200\/50 {
  color: #fde68a80;
}

.text-amber-200\/55 {
  color: #fde68a8c;
}

.text-amber-200\/60 {
  color: #fde68a99;
}

.text-amber-200\/65 {
  color: #fde68aa6;
}

.text-amber-200\/70 {
  color: #fde68ab3;
}

.text-amber-200\/75 {
  color: #fde68abf;
}

.text-amber-200\/80 {
  color: #fde68acc;
}

.text-amber-200\/85 {
  color: #fde68ad9;
}

.text-amber-200\/90 {
  color: #fde68ae6;
}

.text-amber-200\/95 {
  color: #fde68af2;
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity, 1));
}

.text-amber-500\/0 {
  color: #f59e0b00;
}

.text-amber-500\/10 {
  color: #f59e0b1a;
}

.text-amber-500\/100 {
  color: #f59e0b;
}

.text-amber-500\/15 {
  color: #f59e0b26;
}

.text-amber-500\/20 {
  color: #f59e0b33;
}

.text-amber-500\/25 {
  color: #f59e0b40;
}

.text-amber-500\/30 {
  color: #f59e0b4d;
}

.text-amber-500\/35 {
  color: #f59e0b59;
}

.text-amber-500\/40 {
  color: #f59e0b66;
}

.text-amber-500\/45 {
  color: #f59e0b73;
}

.text-amber-500\/5 {
  color: #f59e0b0d;
}

.text-amber-500\/50 {
  color: #f59e0b80;
}

.text-amber-500\/55 {
  color: #f59e0b8c;
}

.text-amber-500\/60 {
  color: #f59e0b99;
}

.text-amber-500\/65 {
  color: #f59e0ba6;
}

.text-amber-500\/70 {
  color: #f59e0bb3;
}

.text-amber-500\/75 {
  color: #f59e0bbf;
}

.text-amber-500\/80 {
  color: #f59e0bcc;
}

.text-amber-500\/85 {
  color: #f59e0bd9;
}

.text-amber-500\/90 {
  color: #f59e0be6;
}

.text-amber-500\/95 {
  color: #f59e0bf2;
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity, 1));
}

.text-amber-800\/0 {
  color: #92400e00;
}

.text-amber-800\/10 {
  color: #92400e1a;
}

.text-amber-800\/100 {
  color: #92400e;
}

.text-amber-800\/15 {
  color: #92400e26;
}

.text-amber-800\/20 {
  color: #92400e33;
}

.text-amber-800\/25 {
  color: #92400e40;
}

.text-amber-800\/30 {
  color: #92400e4d;
}

.text-amber-800\/35 {
  color: #92400e59;
}

.text-amber-800\/40 {
  color: #92400e66;
}

.text-amber-800\/45 {
  color: #92400e73;
}

.text-amber-800\/5 {
  color: #92400e0d;
}

.text-amber-800\/50 {
  color: #92400e80;
}

.text-amber-800\/55 {
  color: #92400e8c;
}

.text-amber-800\/60 {
  color: #92400e99;
}

.text-amber-800\/65 {
  color: #92400ea6;
}

.text-amber-800\/70 {
  color: #92400eb3;
}

.text-amber-800\/75 {
  color: #92400ebf;
}

.text-amber-800\/80 {
  color: #92400ecc;
}

.text-amber-800\/85 {
  color: #92400ed9;
}

.text-amber-800\/90 {
  color: #92400ee6;
}

.text-amber-800\/95 {
  color: #92400ef2;
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity, 1));
}

.text-amber-900\/0 {
  color: #78350f00;
}

.text-amber-900\/10 {
  color: #78350f1a;
}

.text-amber-900\/100 {
  color: #78350f;
}

.text-amber-900\/15 {
  color: #78350f26;
}

.text-amber-900\/20 {
  color: #78350f33;
}

.text-amber-900\/25 {
  color: #78350f40;
}

.text-amber-900\/30 {
  color: #78350f4d;
}

.text-amber-900\/35 {
  color: #78350f59;
}

.text-amber-900\/40 {
  color: #78350f66;
}

.text-amber-900\/45 {
  color: #78350f73;
}

.text-amber-900\/5 {
  color: #78350f0d;
}

.text-amber-900\/50 {
  color: #78350f80;
}

.text-amber-900\/55 {
  color: #78350f8c;
}

.text-amber-900\/60 {
  color: #78350f99;
}

.text-amber-900\/65 {
  color: #78350fa6;
}

.text-amber-900\/70 {
  color: #78350fb3;
}

.text-amber-900\/75 {
  color: #78350fbf;
}

.text-amber-900\/80 {
  color: #78350fcc;
}

.text-amber-900\/85 {
  color: #78350fd9;
}

.text-amber-900\/90 {
  color: #78350fe6;
}

.text-amber-900\/95 {
  color: #78350ff2;
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity, 1));
}

.text-blue-100\/0 {
  color: #dbeafe00;
}

.text-blue-100\/10 {
  color: #dbeafe1a;
}

.text-blue-100\/100 {
  color: #dbeafe;
}

.text-blue-100\/15 {
  color: #dbeafe26;
}

.text-blue-100\/20 {
  color: #dbeafe33;
}

.text-blue-100\/25 {
  color: #dbeafe40;
}

.text-blue-100\/30 {
  color: #dbeafe4d;
}

.text-blue-100\/35 {
  color: #dbeafe59;
}

.text-blue-100\/40 {
  color: #dbeafe66;
}

.text-blue-100\/45 {
  color: #dbeafe73;
}

.text-blue-100\/5 {
  color: #dbeafe0d;
}

.text-blue-100\/50 {
  color: #dbeafe80;
}

.text-blue-100\/55 {
  color: #dbeafe8c;
}

.text-blue-100\/60 {
  color: #dbeafe99;
}

.text-blue-100\/65 {
  color: #dbeafea6;
}

.text-blue-100\/70 {
  color: #dbeafeb3;
}

.text-blue-100\/75 {
  color: #dbeafebf;
}

.text-blue-100\/80 {
  color: #dbeafecc;
}

.text-blue-100\/85 {
  color: #dbeafed9;
}

.text-blue-100\/90 {
  color: #dbeafee6;
}

.text-blue-100\/95 {
  color: #dbeafef2;
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity, 1));
}

.text-blue-200\/0 {
  color: #bfdbfe00;
}

.text-blue-200\/10 {
  color: #bfdbfe1a;
}

.text-blue-200\/100 {
  color: #bfdbfe;
}

.text-blue-200\/15 {
  color: #bfdbfe26;
}

.text-blue-200\/20 {
  color: #bfdbfe33;
}

.text-blue-200\/25 {
  color: #bfdbfe40;
}

.text-blue-200\/30 {
  color: #bfdbfe4d;
}

.text-blue-200\/35 {
  color: #bfdbfe59;
}

.text-blue-200\/40 {
  color: #bfdbfe66;
}

.text-blue-200\/45 {
  color: #bfdbfe73;
}

.text-blue-200\/5 {
  color: #bfdbfe0d;
}

.text-blue-200\/50 {
  color: #bfdbfe80;
}

.text-blue-200\/55 {
  color: #bfdbfe8c;
}

.text-blue-200\/60 {
  color: #bfdbfe99;
}

.text-blue-200\/65 {
  color: #bfdbfea6;
}

.text-blue-200\/70 {
  color: #bfdbfeb3;
}

.text-blue-200\/75 {
  color: #bfdbfebf;
}

.text-blue-200\/80 {
  color: #bfdbfecc;
}

.text-blue-200\/85 {
  color: #bfdbfed9;
}

.text-blue-200\/90 {
  color: #bfdbfee6;
}

.text-blue-200\/95 {
  color: #bfdbfef2;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}

.text-blue-500\/0 {
  color: #3b82f600;
}

.text-blue-500\/10 {
  color: #3b82f61a;
}

.text-blue-500\/100 {
  color: #3b82f6;
}

.text-blue-500\/15 {
  color: #3b82f626;
}

.text-blue-500\/20 {
  color: #3b82f633;
}

.text-blue-500\/25 {
  color: #3b82f640;
}

.text-blue-500\/30 {
  color: #3b82f64d;
}

.text-blue-500\/35 {
  color: #3b82f659;
}

.text-blue-500\/40 {
  color: #3b82f666;
}

.text-blue-500\/45 {
  color: #3b82f673;
}

.text-blue-500\/5 {
  color: #3b82f60d;
}

.text-blue-500\/50 {
  color: #3b82f680;
}

.text-blue-500\/55 {
  color: #3b82f68c;
}

.text-blue-500\/60 {
  color: #3b82f699;
}

.text-blue-500\/65 {
  color: #3b82f6a6;
}

.text-blue-500\/70 {
  color: #3b82f6b3;
}

.text-blue-500\/75 {
  color: #3b82f6bf;
}

.text-blue-500\/80 {
  color: #3b82f6cc;
}

.text-blue-500\/85 {
  color: #3b82f6d9;
}

.text-blue-500\/90 {
  color: #3b82f6e6;
}

.text-blue-500\/95 {
  color: #3b82f6f2;
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}

.text-blue-800\/0 {
  color: #1e40af00;
}

.text-blue-800\/10 {
  color: #1e40af1a;
}

.text-blue-800\/100 {
  color: #1e40af;
}

.text-blue-800\/15 {
  color: #1e40af26;
}

.text-blue-800\/20 {
  color: #1e40af33;
}

.text-blue-800\/25 {
  color: #1e40af40;
}

.text-blue-800\/30 {
  color: #1e40af4d;
}

.text-blue-800\/35 {
  color: #1e40af59;
}

.text-blue-800\/40 {
  color: #1e40af66;
}

.text-blue-800\/45 {
  color: #1e40af73;
}

.text-blue-800\/5 {
  color: #1e40af0d;
}

.text-blue-800\/50 {
  color: #1e40af80;
}

.text-blue-800\/55 {
  color: #1e40af8c;
}

.text-blue-800\/60 {
  color: #1e40af99;
}

.text-blue-800\/65 {
  color: #1e40afa6;
}

.text-blue-800\/70 {
  color: #1e40afb3;
}

.text-blue-800\/75 {
  color: #1e40afbf;
}

.text-blue-800\/80 {
  color: #1e40afcc;
}

.text-blue-800\/85 {
  color: #1e40afd9;
}

.text-blue-800\/90 {
  color: #1e40afe6;
}

.text-blue-800\/95 {
  color: #1e40aff2;
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}

.text-blue-900\/0 {
  color: #1e3a8a00;
}

.text-blue-900\/10 {
  color: #1e3a8a1a;
}

.text-blue-900\/100 {
  color: #1e3a8a;
}

.text-blue-900\/15 {
  color: #1e3a8a26;
}

.text-blue-900\/20 {
  color: #1e3a8a33;
}

.text-blue-900\/25 {
  color: #1e3a8a40;
}

.text-blue-900\/30 {
  color: #1e3a8a4d;
}

.text-blue-900\/35 {
  color: #1e3a8a59;
}

.text-blue-900\/40 {
  color: #1e3a8a66;
}

.text-blue-900\/45 {
  color: #1e3a8a73;
}

.text-blue-900\/5 {
  color: #1e3a8a0d;
}

.text-blue-900\/50 {
  color: #1e3a8a80;
}

.text-blue-900\/55 {
  color: #1e3a8a8c;
}

.text-blue-900\/60 {
  color: #1e3a8a99;
}

.text-blue-900\/65 {
  color: #1e3a8aa6;
}

.text-blue-900\/70 {
  color: #1e3a8ab3;
}

.text-blue-900\/75 {
  color: #1e3a8abf;
}

.text-blue-900\/80 {
  color: #1e3a8acc;
}

.text-blue-900\/85 {
  color: #1e3a8ad9;
}

.text-blue-900\/90 {
  color: #1e3a8ae6;
}

.text-blue-900\/95 {
  color: #1e3a8af2;
}

.text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity, 1));
}

.text-cyan-100\/0 {
  color: #cffafe00;
}

.text-cyan-100\/10 {
  color: #cffafe1a;
}

.text-cyan-100\/100 {
  color: #cffafe;
}

.text-cyan-100\/15 {
  color: #cffafe26;
}

.text-cyan-100\/20 {
  color: #cffafe33;
}

.text-cyan-100\/25 {
  color: #cffafe40;
}

.text-cyan-100\/30 {
  color: #cffafe4d;
}

.text-cyan-100\/35 {
  color: #cffafe59;
}

.text-cyan-100\/40 {
  color: #cffafe66;
}

.text-cyan-100\/45 {
  color: #cffafe73;
}

.text-cyan-100\/5 {
  color: #cffafe0d;
}

.text-cyan-100\/50 {
  color: #cffafe80;
}

.text-cyan-100\/55 {
  color: #cffafe8c;
}

.text-cyan-100\/60 {
  color: #cffafe99;
}

.text-cyan-100\/65 {
  color: #cffafea6;
}

.text-cyan-100\/70 {
  color: #cffafeb3;
}

.text-cyan-100\/75 {
  color: #cffafebf;
}

.text-cyan-100\/80 {
  color: #cffafecc;
}

.text-cyan-100\/85 {
  color: #cffafed9;
}

.text-cyan-100\/90 {
  color: #cffafee6;
}

.text-cyan-100\/95 {
  color: #cffafef2;
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity, 1));
}

.text-cyan-200\/0 {
  color: #a5f3fc00;
}

.text-cyan-200\/10 {
  color: #a5f3fc1a;
}

.text-cyan-200\/100 {
  color: #a5f3fc;
}

.text-cyan-200\/15 {
  color: #a5f3fc26;
}

.text-cyan-200\/20 {
  color: #a5f3fc33;
}

.text-cyan-200\/25 {
  color: #a5f3fc40;
}

.text-cyan-200\/30 {
  color: #a5f3fc4d;
}

.text-cyan-200\/35 {
  color: #a5f3fc59;
}

.text-cyan-200\/40 {
  color: #a5f3fc66;
}

.text-cyan-200\/45 {
  color: #a5f3fc73;
}

.text-cyan-200\/5 {
  color: #a5f3fc0d;
}

.text-cyan-200\/50 {
  color: #a5f3fc80;
}

.text-cyan-200\/55 {
  color: #a5f3fc8c;
}

.text-cyan-200\/60 {
  color: #a5f3fc99;
}

.text-cyan-200\/65 {
  color: #a5f3fca6;
}

.text-cyan-200\/70 {
  color: #a5f3fcb3;
}

.text-cyan-200\/75 {
  color: #a5f3fcbf;
}

.text-cyan-200\/80 {
  color: #a5f3fccc;
}

.text-cyan-200\/85 {
  color: #a5f3fcd9;
}

.text-cyan-200\/90 {
  color: #a5f3fce6;
}

.text-cyan-200\/95 {
  color: #a5f3fcf2;
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity, 1));
}

.text-cyan-500\/0 {
  color: #06b6d400;
}

.text-cyan-500\/10 {
  color: #06b6d41a;
}

.text-cyan-500\/100 {
  color: #06b6d4;
}

.text-cyan-500\/15 {
  color: #06b6d426;
}

.text-cyan-500\/20 {
  color: #06b6d433;
}

.text-cyan-500\/25 {
  color: #06b6d440;
}

.text-cyan-500\/30 {
  color: #06b6d44d;
}

.text-cyan-500\/35 {
  color: #06b6d459;
}

.text-cyan-500\/40 {
  color: #06b6d466;
}

.text-cyan-500\/45 {
  color: #06b6d473;
}

.text-cyan-500\/5 {
  color: #06b6d40d;
}

.text-cyan-500\/50 {
  color: #06b6d480;
}

.text-cyan-500\/55 {
  color: #06b6d48c;
}

.text-cyan-500\/60 {
  color: #06b6d499;
}

.text-cyan-500\/65 {
  color: #06b6d4a6;
}

.text-cyan-500\/70 {
  color: #06b6d4b3;
}

.text-cyan-500\/75 {
  color: #06b6d4bf;
}

.text-cyan-500\/80 {
  color: #06b6d4cc;
}

.text-cyan-500\/85 {
  color: #06b6d4d9;
}

.text-cyan-500\/90 {
  color: #06b6d4e6;
}

.text-cyan-500\/95 {
  color: #06b6d4f2;
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity, 1));
}

.text-cyan-800\/0 {
  color: #155e7500;
}

.text-cyan-800\/10 {
  color: #155e751a;
}

.text-cyan-800\/100 {
  color: #155e75;
}

.text-cyan-800\/15 {
  color: #155e7526;
}

.text-cyan-800\/20 {
  color: #155e7533;
}

.text-cyan-800\/25 {
  color: #155e7540;
}

.text-cyan-800\/30 {
  color: #155e754d;
}

.text-cyan-800\/35 {
  color: #155e7559;
}

.text-cyan-800\/40 {
  color: #155e7566;
}

.text-cyan-800\/45 {
  color: #155e7573;
}

.text-cyan-800\/5 {
  color: #155e750d;
}

.text-cyan-800\/50 {
  color: #155e7580;
}

.text-cyan-800\/55 {
  color: #155e758c;
}

.text-cyan-800\/60 {
  color: #155e7599;
}

.text-cyan-800\/65 {
  color: #155e75a6;
}

.text-cyan-800\/70 {
  color: #155e75b3;
}

.text-cyan-800\/75 {
  color: #155e75bf;
}

.text-cyan-800\/80 {
  color: #155e75cc;
}

.text-cyan-800\/85 {
  color: #155e75d9;
}

.text-cyan-800\/90 {
  color: #155e75e6;
}

.text-cyan-800\/95 {
  color: #155e75f2;
}

.text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity, 1));
}

.text-cyan-900\/0 {
  color: #164e6300;
}

.text-cyan-900\/10 {
  color: #164e631a;
}

.text-cyan-900\/100 {
  color: #164e63;
}

.text-cyan-900\/15 {
  color: #164e6326;
}

.text-cyan-900\/20 {
  color: #164e6333;
}

.text-cyan-900\/25 {
  color: #164e6340;
}

.text-cyan-900\/30 {
  color: #164e634d;
}

.text-cyan-900\/35 {
  color: #164e6359;
}

.text-cyan-900\/40 {
  color: #164e6366;
}

.text-cyan-900\/45 {
  color: #164e6373;
}

.text-cyan-900\/5 {
  color: #164e630d;
}

.text-cyan-900\/50 {
  color: #164e6380;
}

.text-cyan-900\/55 {
  color: #164e638c;
}

.text-cyan-900\/60 {
  color: #164e6399;
}

.text-cyan-900\/65 {
  color: #164e63a6;
}

.text-cyan-900\/70 {
  color: #164e63b3;
}

.text-cyan-900\/75 {
  color: #164e63bf;
}

.text-cyan-900\/80 {
  color: #164e63cc;
}

.text-cyan-900\/85 {
  color: #164e63d9;
}

.text-cyan-900\/90 {
  color: #164e63e6;
}

.text-cyan-900\/95 {
  color: #164e63f2;
}

.text-emerald-100 {
  --tw-text-opacity: 1;
  color: rgb(209 250 229 / var(--tw-text-opacity, 1));
}

.text-emerald-100\/0 {
  color: #d1fae500;
}

.text-emerald-100\/10 {
  color: #d1fae51a;
}

.text-emerald-100\/100 {
  color: #d1fae5;
}

.text-emerald-100\/15 {
  color: #d1fae526;
}

.text-emerald-100\/20 {
  color: #d1fae533;
}

.text-emerald-100\/25 {
  color: #d1fae540;
}

.text-emerald-100\/30 {
  color: #d1fae54d;
}

.text-emerald-100\/35 {
  color: #d1fae559;
}

.text-emerald-100\/40 {
  color: #d1fae566;
}

.text-emerald-100\/45 {
  color: #d1fae573;
}

.text-emerald-100\/5 {
  color: #d1fae50d;
}

.text-emerald-100\/50 {
  color: #d1fae580;
}

.text-emerald-100\/55 {
  color: #d1fae58c;
}

.text-emerald-100\/60 {
  color: #d1fae599;
}

.text-emerald-100\/65 {
  color: #d1fae5a6;
}

.text-emerald-100\/70 {
  color: #d1fae5b3;
}

.text-emerald-100\/75 {
  color: #d1fae5bf;
}

.text-emerald-100\/80 {
  color: #d1fae5cc;
}

.text-emerald-100\/85 {
  color: #d1fae5d9;
}

.text-emerald-100\/90 {
  color: #d1fae5e6;
}

.text-emerald-100\/95 {
  color: #d1fae5f2;
}

.text-emerald-200 {
  --tw-text-opacity: 1;
  color: rgb(167 243 208 / var(--tw-text-opacity, 1));
}

.text-emerald-200\/0 {
  color: #a7f3d000;
}

.text-emerald-200\/10 {
  color: #a7f3d01a;
}

.text-emerald-200\/100 {
  color: #a7f3d0;
}

.text-emerald-200\/15 {
  color: #a7f3d026;
}

.text-emerald-200\/20 {
  color: #a7f3d033;
}

.text-emerald-200\/25 {
  color: #a7f3d040;
}

.text-emerald-200\/30 {
  color: #a7f3d04d;
}

.text-emerald-200\/35 {
  color: #a7f3d059;
}

.text-emerald-200\/40 {
  color: #a7f3d066;
}

.text-emerald-200\/45 {
  color: #a7f3d073;
}

.text-emerald-200\/5 {
  color: #a7f3d00d;
}

.text-emerald-200\/50 {
  color: #a7f3d080;
}

.text-emerald-200\/55 {
  color: #a7f3d08c;
}

.text-emerald-200\/60 {
  color: #a7f3d099;
}

.text-emerald-200\/65 {
  color: #a7f3d0a6;
}

.text-emerald-200\/70 {
  color: #a7f3d0b3;
}

.text-emerald-200\/75 {
  color: #a7f3d0bf;
}

.text-emerald-200\/80 {
  color: #a7f3d0cc;
}

.text-emerald-200\/85 {
  color: #a7f3d0d9;
}

.text-emerald-200\/90 {
  color: #a7f3d0e6;
}

.text-emerald-200\/95 {
  color: #a7f3d0f2;
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity, 1));
}

.text-emerald-500\/0 {
  color: #10b98100;
}

.text-emerald-500\/10 {
  color: #10b9811a;
}

.text-emerald-500\/100 {
  color: #10b981;
}

.text-emerald-500\/15 {
  color: #10b98126;
}

.text-emerald-500\/20 {
  color: #10b98133;
}

.text-emerald-500\/25 {
  color: #10b98140;
}

.text-emerald-500\/30 {
  color: #10b9814d;
}

.text-emerald-500\/35 {
  color: #10b98159;
}

.text-emerald-500\/40 {
  color: #10b98166;
}

.text-emerald-500\/45 {
  color: #10b98173;
}

.text-emerald-500\/5 {
  color: #10b9810d;
}

.text-emerald-500\/50 {
  color: #10b98180;
}

.text-emerald-500\/55 {
  color: #10b9818c;
}

.text-emerald-500\/60 {
  color: #10b98199;
}

.text-emerald-500\/65 {
  color: #10b981a6;
}

.text-emerald-500\/70 {
  color: #10b981b3;
}

.text-emerald-500\/75 {
  color: #10b981bf;
}

.text-emerald-500\/80 {
  color: #10b981cc;
}

.text-emerald-500\/85 {
  color: #10b981d9;
}

.text-emerald-500\/90 {
  color: #10b981e6;
}

.text-emerald-500\/95 {
  color: #10b981f2;
}

.text-emerald-800 {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity, 1));
}

.text-emerald-800\/0 {
  color: #065f4600;
}

.text-emerald-800\/10 {
  color: #065f461a;
}

.text-emerald-800\/100 {
  color: #065f46;
}

.text-emerald-800\/15 {
  color: #065f4626;
}

.text-emerald-800\/20 {
  color: #065f4633;
}

.text-emerald-800\/25 {
  color: #065f4640;
}

.text-emerald-800\/30 {
  color: #065f464d;
}

.text-emerald-800\/35 {
  color: #065f4659;
}

.text-emerald-800\/40 {
  color: #065f4666;
}

.text-emerald-800\/45 {
  color: #065f4673;
}

.text-emerald-800\/5 {
  color: #065f460d;
}

.text-emerald-800\/50 {
  color: #065f4680;
}

.text-emerald-800\/55 {
  color: #065f468c;
}

.text-emerald-800\/60 {
  color: #065f4699;
}

.text-emerald-800\/65 {
  color: #065f46a6;
}

.text-emerald-800\/70 {
  color: #065f46b3;
}

.text-emerald-800\/75 {
  color: #065f46bf;
}

.text-emerald-800\/80 {
  color: #065f46cc;
}

.text-emerald-800\/85 {
  color: #065f46d9;
}

.text-emerald-800\/90 {
  color: #065f46e6;
}

.text-emerald-800\/95 {
  color: #065f46f2;
}

.text-emerald-900 {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity, 1));
}

.text-emerald-900\/0 {
  color: #064e3b00;
}

.text-emerald-900\/10 {
  color: #064e3b1a;
}

.text-emerald-900\/100 {
  color: #064e3b;
}

.text-emerald-900\/15 {
  color: #064e3b26;
}

.text-emerald-900\/20 {
  color: #064e3b33;
}

.text-emerald-900\/25 {
  color: #064e3b40;
}

.text-emerald-900\/30 {
  color: #064e3b4d;
}

.text-emerald-900\/35 {
  color: #064e3b59;
}

.text-emerald-900\/40 {
  color: #064e3b66;
}

.text-emerald-900\/45 {
  color: #064e3b73;
}

.text-emerald-900\/5 {
  color: #064e3b0d;
}

.text-emerald-900\/50 {
  color: #064e3b80;
}

.text-emerald-900\/55 {
  color: #064e3b8c;
}

.text-emerald-900\/60 {
  color: #064e3b99;
}

.text-emerald-900\/65 {
  color: #064e3ba6;
}

.text-emerald-900\/70 {
  color: #064e3bb3;
}

.text-emerald-900\/75 {
  color: #064e3bbf;
}

.text-emerald-900\/80 {
  color: #064e3bcc;
}

.text-emerald-900\/85 {
  color: #064e3bd9;
}

.text-emerald-900\/90 {
  color: #064e3be6;
}

.text-emerald-900\/95 {
  color: #064e3bf2;
}

.text-fuchsia-100 {
  --tw-text-opacity: 1;
  color: rgb(250 232 255 / var(--tw-text-opacity, 1));
}

.text-fuchsia-100\/0 {
  color: #fae8ff00;
}

.text-fuchsia-100\/10 {
  color: #fae8ff1a;
}

.text-fuchsia-100\/100 {
  color: #fae8ff;
}

.text-fuchsia-100\/15 {
  color: #fae8ff26;
}

.text-fuchsia-100\/20 {
  color: #fae8ff33;
}

.text-fuchsia-100\/25 {
  color: #fae8ff40;
}

.text-fuchsia-100\/30 {
  color: #fae8ff4d;
}

.text-fuchsia-100\/35 {
  color: #fae8ff59;
}

.text-fuchsia-100\/40 {
  color: #fae8ff66;
}

.text-fuchsia-100\/45 {
  color: #fae8ff73;
}

.text-fuchsia-100\/5 {
  color: #fae8ff0d;
}

.text-fuchsia-100\/50 {
  color: #fae8ff80;
}

.text-fuchsia-100\/55 {
  color: #fae8ff8c;
}

.text-fuchsia-100\/60 {
  color: #fae8ff99;
}

.text-fuchsia-100\/65 {
  color: #fae8ffa6;
}

.text-fuchsia-100\/70 {
  color: #fae8ffb3;
}

.text-fuchsia-100\/75 {
  color: #fae8ffbf;
}

.text-fuchsia-100\/80 {
  color: #fae8ffcc;
}

.text-fuchsia-100\/85 {
  color: #fae8ffd9;
}

.text-fuchsia-100\/90 {
  color: #fae8ffe6;
}

.text-fuchsia-100\/95 {
  color: #fae8fff2;
}

.text-fuchsia-200 {
  --tw-text-opacity: 1;
  color: rgb(245 208 254 / var(--tw-text-opacity, 1));
}

.text-fuchsia-200\/0 {
  color: #f5d0fe00;
}

.text-fuchsia-200\/10 {
  color: #f5d0fe1a;
}

.text-fuchsia-200\/100 {
  color: #f5d0fe;
}

.text-fuchsia-200\/15 {
  color: #f5d0fe26;
}

.text-fuchsia-200\/20 {
  color: #f5d0fe33;
}

.text-fuchsia-200\/25 {
  color: #f5d0fe40;
}

.text-fuchsia-200\/30 {
  color: #f5d0fe4d;
}

.text-fuchsia-200\/35 {
  color: #f5d0fe59;
}

.text-fuchsia-200\/40 {
  color: #f5d0fe66;
}

.text-fuchsia-200\/45 {
  color: #f5d0fe73;
}

.text-fuchsia-200\/5 {
  color: #f5d0fe0d;
}

.text-fuchsia-200\/50 {
  color: #f5d0fe80;
}

.text-fuchsia-200\/55 {
  color: #f5d0fe8c;
}

.text-fuchsia-200\/60 {
  color: #f5d0fe99;
}

.text-fuchsia-200\/65 {
  color: #f5d0fea6;
}

.text-fuchsia-200\/70 {
  color: #f5d0feb3;
}

.text-fuchsia-200\/75 {
  color: #f5d0febf;
}

.text-fuchsia-200\/80 {
  color: #f5d0fecc;
}

.text-fuchsia-200\/85 {
  color: #f5d0fed9;
}

.text-fuchsia-200\/90 {
  color: #f5d0fee6;
}

.text-fuchsia-200\/95 {
  color: #f5d0fef2;
}

.text-fuchsia-500 {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity, 1));
}

.text-fuchsia-500\/0 {
  color: #d946ef00;
}

.text-fuchsia-500\/10 {
  color: #d946ef1a;
}

.text-fuchsia-500\/100 {
  color: #d946ef;
}

.text-fuchsia-500\/15 {
  color: #d946ef26;
}

.text-fuchsia-500\/20 {
  color: #d946ef33;
}

.text-fuchsia-500\/25 {
  color: #d946ef40;
}

.text-fuchsia-500\/30 {
  color: #d946ef4d;
}

.text-fuchsia-500\/35 {
  color: #d946ef59;
}

.text-fuchsia-500\/40 {
  color: #d946ef66;
}

.text-fuchsia-500\/45 {
  color: #d946ef73;
}

.text-fuchsia-500\/5 {
  color: #d946ef0d;
}

.text-fuchsia-500\/50 {
  color: #d946ef80;
}

.text-fuchsia-500\/55 {
  color: #d946ef8c;
}

.text-fuchsia-500\/60 {
  color: #d946ef99;
}

.text-fuchsia-500\/65 {
  color: #d946efa6;
}

.text-fuchsia-500\/70 {
  color: #d946efb3;
}

.text-fuchsia-500\/75 {
  color: #d946efbf;
}

.text-fuchsia-500\/80 {
  color: #d946efcc;
}

.text-fuchsia-500\/85 {
  color: #d946efd9;
}

.text-fuchsia-500\/90 {
  color: #d946efe6;
}

.text-fuchsia-500\/95 {
  color: #d946eff2;
}

.text-fuchsia-800 {
  --tw-text-opacity: 1;
  color: rgb(134 25 143 / var(--tw-text-opacity, 1));
}

.text-fuchsia-800\/0 {
  color: #86198f00;
}

.text-fuchsia-800\/10 {
  color: #86198f1a;
}

.text-fuchsia-800\/100 {
  color: #86198f;
}

.text-fuchsia-800\/15 {
  color: #86198f26;
}

.text-fuchsia-800\/20 {
  color: #86198f33;
}

.text-fuchsia-800\/25 {
  color: #86198f40;
}

.text-fuchsia-800\/30 {
  color: #86198f4d;
}

.text-fuchsia-800\/35 {
  color: #86198f59;
}

.text-fuchsia-800\/40 {
  color: #86198f66;
}

.text-fuchsia-800\/45 {
  color: #86198f73;
}

.text-fuchsia-800\/5 {
  color: #86198f0d;
}

.text-fuchsia-800\/50 {
  color: #86198f80;
}

.text-fuchsia-800\/55 {
  color: #86198f8c;
}

.text-fuchsia-800\/60 {
  color: #86198f99;
}

.text-fuchsia-800\/65 {
  color: #86198fa6;
}

.text-fuchsia-800\/70 {
  color: #86198fb3;
}

.text-fuchsia-800\/75 {
  color: #86198fbf;
}

.text-fuchsia-800\/80 {
  color: #86198fcc;
}

.text-fuchsia-800\/85 {
  color: #86198fd9;
}

.text-fuchsia-800\/90 {
  color: #86198fe6;
}

.text-fuchsia-800\/95 {
  color: #86198ff2;
}

.text-fuchsia-900 {
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity, 1));
}

.text-fuchsia-900\/0 {
  color: #701a7500;
}

.text-fuchsia-900\/10 {
  color: #701a751a;
}

.text-fuchsia-900\/100 {
  color: #701a75;
}

.text-fuchsia-900\/15 {
  color: #701a7526;
}

.text-fuchsia-900\/20 {
  color: #701a7533;
}

.text-fuchsia-900\/25 {
  color: #701a7540;
}

.text-fuchsia-900\/30 {
  color: #701a754d;
}

.text-fuchsia-900\/35 {
  color: #701a7559;
}

.text-fuchsia-900\/40 {
  color: #701a7566;
}

.text-fuchsia-900\/45 {
  color: #701a7573;
}

.text-fuchsia-900\/5 {
  color: #701a750d;
}

.text-fuchsia-900\/50 {
  color: #701a7580;
}

.text-fuchsia-900\/55 {
  color: #701a758c;
}

.text-fuchsia-900\/60 {
  color: #701a7599;
}

.text-fuchsia-900\/65 {
  color: #701a75a6;
}

.text-fuchsia-900\/70 {
  color: #701a75b3;
}

.text-fuchsia-900\/75 {
  color: #701a75bf;
}

.text-fuchsia-900\/80 {
  color: #701a75cc;
}

.text-fuchsia-900\/85 {
  color: #701a75d9;
}

.text-fuchsia-900\/90 {
  color: #701a75e6;
}

.text-fuchsia-900\/95 {
  color: #701a75f2;
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity, 1));
}

.text-gray-100\/0 {
  color: #f3f4f600;
}

.text-gray-100\/10 {
  color: #f3f4f61a;
}

.text-gray-100\/100 {
  color: #f3f4f6;
}

.text-gray-100\/15 {
  color: #f3f4f626;
}

.text-gray-100\/20 {
  color: #f3f4f633;
}

.text-gray-100\/25 {
  color: #f3f4f640;
}

.text-gray-100\/30 {
  color: #f3f4f64d;
}

.text-gray-100\/35 {
  color: #f3f4f659;
}

.text-gray-100\/40 {
  color: #f3f4f666;
}

.text-gray-100\/45 {
  color: #f3f4f673;
}

.text-gray-100\/5 {
  color: #f3f4f60d;
}

.text-gray-100\/50 {
  color: #f3f4f680;
}

.text-gray-100\/55 {
  color: #f3f4f68c;
}

.text-gray-100\/60 {
  color: #f3f4f699;
}

.text-gray-100\/65 {
  color: #f3f4f6a6;
}

.text-gray-100\/70 {
  color: #f3f4f6b3;
}

.text-gray-100\/75 {
  color: #f3f4f6bf;
}

.text-gray-100\/80 {
  color: #f3f4f6cc;
}

.text-gray-100\/85 {
  color: #f3f4f6d9;
}

.text-gray-100\/90 {
  color: #f3f4f6e6;
}

.text-gray-100\/95 {
  color: #f3f4f6f2;
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.text-gray-200\/0 {
  color: #e5e7eb00;
}

.text-gray-200\/10 {
  color: #e5e7eb1a;
}

.text-gray-200\/100 {
  color: #e5e7eb;
}

.text-gray-200\/15 {
  color: #e5e7eb26;
}

.text-gray-200\/20 {
  color: #e5e7eb33;
}

.text-gray-200\/25 {
  color: #e5e7eb40;
}

.text-gray-200\/30 {
  color: #e5e7eb4d;
}

.text-gray-200\/35 {
  color: #e5e7eb59;
}

.text-gray-200\/40 {
  color: #e5e7eb66;
}

.text-gray-200\/45 {
  color: #e5e7eb73;
}

.text-gray-200\/5 {
  color: #e5e7eb0d;
}

.text-gray-200\/50 {
  color: #e5e7eb80;
}

.text-gray-200\/55 {
  color: #e5e7eb8c;
}

.text-gray-200\/60 {
  color: #e5e7eb99;
}

.text-gray-200\/65 {
  color: #e5e7eba6;
}

.text-gray-200\/70 {
  color: #e5e7ebb3;
}

.text-gray-200\/75 {
  color: #e5e7ebbf;
}

.text-gray-200\/80 {
  color: #e5e7ebcc;
}

.text-gray-200\/85 {
  color: #e5e7ebd9;
}

.text-gray-200\/90 {
  color: #e5e7ebe6;
}

.text-gray-200\/95 {
  color: #e5e7ebf2;
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-500\/0 {
  color: #6b728000;
}

.text-gray-500\/10 {
  color: #6b72801a;
}

.text-gray-500\/100 {
  color: #6b7280;
}

.text-gray-500\/15 {
  color: #6b728026;
}

.text-gray-500\/20 {
  color: #6b728033;
}

.text-gray-500\/25 {
  color: #6b728040;
}

.text-gray-500\/30 {
  color: #6b72804d;
}

.text-gray-500\/35 {
  color: #6b728059;
}

.text-gray-500\/40 {
  color: #6b728066;
}

.text-gray-500\/45 {
  color: #6b728073;
}

.text-gray-500\/5 {
  color: #6b72800d;
}

.text-gray-500\/50 {
  color: #6b728080;
}

.text-gray-500\/55 {
  color: #6b72808c;
}

.text-gray-500\/60 {
  color: #6b728099;
}

.text-gray-500\/65 {
  color: #6b7280a6;
}

.text-gray-500\/70 {
  color: #6b7280b3;
}

.text-gray-500\/75 {
  color: #6b7280bf;
}

.text-gray-500\/80 {
  color: #6b7280cc;
}

.text-gray-500\/85 {
  color: #6b7280d9;
}

.text-gray-500\/90 {
  color: #6b7280e6;
}

.text-gray-500\/95 {
  color: #6b7280f2;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
}

.text-gray-800\/0 {
  color: #1f293700;
}

.text-gray-800\/10 {
  color: #1f29371a;
}

.text-gray-800\/100 {
  color: #1f2937;
}

.text-gray-800\/15 {
  color: #1f293726;
}

.text-gray-800\/20 {
  color: #1f293733;
}

.text-gray-800\/25 {
  color: #1f293740;
}

.text-gray-800\/30 {
  color: #1f29374d;
}

.text-gray-800\/35 {
  color: #1f293759;
}

.text-gray-800\/40 {
  color: #1f293766;
}

.text-gray-800\/45 {
  color: #1f293773;
}

.text-gray-800\/5 {
  color: #1f29370d;
}

.text-gray-800\/50 {
  color: #1f293780;
}

.text-gray-800\/55 {
  color: #1f29378c;
}

.text-gray-800\/60 {
  color: #1f293799;
}

.text-gray-800\/65 {
  color: #1f2937a6;
}

.text-gray-800\/70 {
  color: #1f2937b3;
}

.text-gray-800\/75 {
  color: #1f2937bf;
}

.text-gray-800\/80 {
  color: #1f2937cc;
}

.text-gray-800\/85 {
  color: #1f2937d9;
}

.text-gray-800\/90 {
  color: #1f2937e6;
}

.text-gray-800\/95 {
  color: #1f2937f2;
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-gray-900\/0 {
  color: #11182700;
}

.text-gray-900\/10 {
  color: #1118271a;
}

.text-gray-900\/100 {
  color: #111827;
}

.text-gray-900\/15 {
  color: #11182726;
}

.text-gray-900\/20 {
  color: #11182733;
}

.text-gray-900\/25 {
  color: #11182740;
}

.text-gray-900\/30 {
  color: #1118274d;
}

.text-gray-900\/35 {
  color: #11182759;
}

.text-gray-900\/40 {
  color: #11182766;
}

.text-gray-900\/45 {
  color: #11182773;
}

.text-gray-900\/5 {
  color: #1118270d;
}

.text-gray-900\/50 {
  color: #11182780;
}

.text-gray-900\/55 {
  color: #1118278c;
}

.text-gray-900\/60 {
  color: #11182799;
}

.text-gray-900\/65 {
  color: #111827a6;
}

.text-gray-900\/70 {
  color: #111827b3;
}

.text-gray-900\/75 {
  color: #111827bf;
}

.text-gray-900\/80 {
  color: #111827cc;
}

.text-gray-900\/85 {
  color: #111827d9;
}

.text-gray-900\/90 {
  color: #111827e6;
}

.text-gray-900\/95 {
  color: #111827f2;
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(220 252 231 / var(--tw-text-opacity, 1));
}

.text-green-100\/0 {
  color: #dcfce700;
}

.text-green-100\/10 {
  color: #dcfce71a;
}

.text-green-100\/100 {
  color: #dcfce7;
}

.text-green-100\/15 {
  color: #dcfce726;
}

.text-green-100\/20 {
  color: #dcfce733;
}

.text-green-100\/25 {
  color: #dcfce740;
}

.text-green-100\/30 {
  color: #dcfce74d;
}

.text-green-100\/35 {
  color: #dcfce759;
}

.text-green-100\/40 {
  color: #dcfce766;
}

.text-green-100\/45 {
  color: #dcfce773;
}

.text-green-100\/5 {
  color: #dcfce70d;
}

.text-green-100\/50 {
  color: #dcfce780;
}

.text-green-100\/55 {
  color: #dcfce78c;
}

.text-green-100\/60 {
  color: #dcfce799;
}

.text-green-100\/65 {
  color: #dcfce7a6;
}

.text-green-100\/70 {
  color: #dcfce7b3;
}

.text-green-100\/75 {
  color: #dcfce7bf;
}

.text-green-100\/80 {
  color: #dcfce7cc;
}

.text-green-100\/85 {
  color: #dcfce7d9;
}

.text-green-100\/90 {
  color: #dcfce7e6;
}

.text-green-100\/95 {
  color: #dcfce7f2;
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgb(187 247 208 / var(--tw-text-opacity, 1));
}

.text-green-200\/0 {
  color: #bbf7d000;
}

.text-green-200\/10 {
  color: #bbf7d01a;
}

.text-green-200\/100 {
  color: #bbf7d0;
}

.text-green-200\/15 {
  color: #bbf7d026;
}

.text-green-200\/20 {
  color: #bbf7d033;
}

.text-green-200\/25 {
  color: #bbf7d040;
}

.text-green-200\/30 {
  color: #bbf7d04d;
}

.text-green-200\/35 {
  color: #bbf7d059;
}

.text-green-200\/40 {
  color: #bbf7d066;
}

.text-green-200\/45 {
  color: #bbf7d073;
}

.text-green-200\/5 {
  color: #bbf7d00d;
}

.text-green-200\/50 {
  color: #bbf7d080;
}

.text-green-200\/55 {
  color: #bbf7d08c;
}

.text-green-200\/60 {
  color: #bbf7d099;
}

.text-green-200\/65 {
  color: #bbf7d0a6;
}

.text-green-200\/70 {
  color: #bbf7d0b3;
}

.text-green-200\/75 {
  color: #bbf7d0bf;
}

.text-green-200\/80 {
  color: #bbf7d0cc;
}

.text-green-200\/85 {
  color: #bbf7d0d9;
}

.text-green-200\/90 {
  color: #bbf7d0e6;
}

.text-green-200\/95 {
  color: #bbf7d0f2;
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}

.text-green-500\/0 {
  color: #22c55e00;
}

.text-green-500\/10 {
  color: #22c55e1a;
}

.text-green-500\/100 {
  color: #22c55e;
}

.text-green-500\/15 {
  color: #22c55e26;
}

.text-green-500\/20 {
  color: #22c55e33;
}

.text-green-500\/25 {
  color: #22c55e40;
}

.text-green-500\/30 {
  color: #22c55e4d;
}

.text-green-500\/35 {
  color: #22c55e59;
}

.text-green-500\/40 {
  color: #22c55e66;
}

.text-green-500\/45 {
  color: #22c55e73;
}

.text-green-500\/5 {
  color: #22c55e0d;
}

.text-green-500\/50 {
  color: #22c55e80;
}

.text-green-500\/55 {
  color: #22c55e8c;
}

.text-green-500\/60 {
  color: #22c55e99;
}

.text-green-500\/65 {
  color: #22c55ea6;
}

.text-green-500\/70 {
  color: #22c55eb3;
}

.text-green-500\/75 {
  color: #22c55ebf;
}

.text-green-500\/80 {
  color: #22c55ecc;
}

.text-green-500\/85 {
  color: #22c55ed9;
}

.text-green-500\/90 {
  color: #22c55ee6;
}

.text-green-500\/95 {
  color: #22c55ef2;
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity, 1));
}

.text-green-800\/0 {
  color: #16653400;
}

.text-green-800\/10 {
  color: #1665341a;
}

.text-green-800\/100 {
  color: #166534;
}

.text-green-800\/15 {
  color: #16653426;
}

.text-green-800\/20 {
  color: #16653433;
}

.text-green-800\/25 {
  color: #16653440;
}

.text-green-800\/30 {
  color: #1665344d;
}

.text-green-800\/35 {
  color: #16653459;
}

.text-green-800\/40 {
  color: #16653466;
}

.text-green-800\/45 {
  color: #16653473;
}

.text-green-800\/5 {
  color: #1665340d;
}

.text-green-800\/50 {
  color: #16653480;
}

.text-green-800\/55 {
  color: #1665348c;
}

.text-green-800\/60 {
  color: #16653499;
}

.text-green-800\/65 {
  color: #166534a6;
}

.text-green-800\/70 {
  color: #166534b3;
}

.text-green-800\/75 {
  color: #166534bf;
}

.text-green-800\/80 {
  color: #166534cc;
}

.text-green-800\/85 {
  color: #166534d9;
}

.text-green-800\/90 {
  color: #166534e6;
}

.text-green-800\/95 {
  color: #166534f2;
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity, 1));
}

.text-green-900\/0 {
  color: #14532d00;
}

.text-green-900\/10 {
  color: #14532d1a;
}

.text-green-900\/100 {
  color: #14532d;
}

.text-green-900\/15 {
  color: #14532d26;
}

.text-green-900\/20 {
  color: #14532d33;
}

.text-green-900\/25 {
  color: #14532d40;
}

.text-green-900\/30 {
  color: #14532d4d;
}

.text-green-900\/35 {
  color: #14532d59;
}

.text-green-900\/40 {
  color: #14532d66;
}

.text-green-900\/45 {
  color: #14532d73;
}

.text-green-900\/5 {
  color: #14532d0d;
}

.text-green-900\/50 {
  color: #14532d80;
}

.text-green-900\/55 {
  color: #14532d8c;
}

.text-green-900\/60 {
  color: #14532d99;
}

.text-green-900\/65 {
  color: #14532da6;
}

.text-green-900\/70 {
  color: #14532db3;
}

.text-green-900\/75 {
  color: #14532dbf;
}

.text-green-900\/80 {
  color: #14532dcc;
}

.text-green-900\/85 {
  color: #14532dd9;
}

.text-green-900\/90 {
  color: #14532de6;
}

.text-green-900\/95 {
  color: #14532df2;
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity, 1));
}

.text-indigo-100\/0 {
  color: #e0e7ff00;
}

.text-indigo-100\/10 {
  color: #e0e7ff1a;
}

.text-indigo-100\/100 {
  color: #e0e7ff;
}

.text-indigo-100\/15 {
  color: #e0e7ff26;
}

.text-indigo-100\/20 {
  color: #e0e7ff33;
}

.text-indigo-100\/25 {
  color: #e0e7ff40;
}

.text-indigo-100\/30 {
  color: #e0e7ff4d;
}

.text-indigo-100\/35 {
  color: #e0e7ff59;
}

.text-indigo-100\/40 {
  color: #e0e7ff66;
}

.text-indigo-100\/45 {
  color: #e0e7ff73;
}

.text-indigo-100\/5 {
  color: #e0e7ff0d;
}

.text-indigo-100\/50 {
  color: #e0e7ff80;
}

.text-indigo-100\/55 {
  color: #e0e7ff8c;
}

.text-indigo-100\/60 {
  color: #e0e7ff99;
}

.text-indigo-100\/65 {
  color: #e0e7ffa6;
}

.text-indigo-100\/70 {
  color: #e0e7ffb3;
}

.text-indigo-100\/75 {
  color: #e0e7ffbf;
}

.text-indigo-100\/80 {
  color: #e0e7ffcc;
}

.text-indigo-100\/85 {
  color: #e0e7ffd9;
}

.text-indigo-100\/90 {
  color: #e0e7ffe6;
}

.text-indigo-100\/95 {
  color: #e0e7fff2;
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity, 1));
}

.text-indigo-200\/0 {
  color: #c7d2fe00;
}

.text-indigo-200\/10 {
  color: #c7d2fe1a;
}

.text-indigo-200\/100 {
  color: #c7d2fe;
}

.text-indigo-200\/15 {
  color: #c7d2fe26;
}

.text-indigo-200\/20 {
  color: #c7d2fe33;
}

.text-indigo-200\/25 {
  color: #c7d2fe40;
}

.text-indigo-200\/30 {
  color: #c7d2fe4d;
}

.text-indigo-200\/35 {
  color: #c7d2fe59;
}

.text-indigo-200\/40 {
  color: #c7d2fe66;
}

.text-indigo-200\/45 {
  color: #c7d2fe73;
}

.text-indigo-200\/5 {
  color: #c7d2fe0d;
}

.text-indigo-200\/50 {
  color: #c7d2fe80;
}

.text-indigo-200\/55 {
  color: #c7d2fe8c;
}

.text-indigo-200\/60 {
  color: #c7d2fe99;
}

.text-indigo-200\/65 {
  color: #c7d2fea6;
}

.text-indigo-200\/70 {
  color: #c7d2feb3;
}

.text-indigo-200\/75 {
  color: #c7d2febf;
}

.text-indigo-200\/80 {
  color: #c7d2fecc;
}

.text-indigo-200\/85 {
  color: #c7d2fed9;
}

.text-indigo-200\/90 {
  color: #c7d2fee6;
}

.text-indigo-200\/95 {
  color: #c7d2fef2;
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity, 1));
}

.text-indigo-500\/0 {
  color: #6366f100;
}

.text-indigo-500\/10 {
  color: #6366f11a;
}

.text-indigo-500\/100 {
  color: #6366f1;
}

.text-indigo-500\/15 {
  color: #6366f126;
}

.text-indigo-500\/20 {
  color: #6366f133;
}

.text-indigo-500\/25 {
  color: #6366f140;
}

.text-indigo-500\/30 {
  color: #6366f14d;
}

.text-indigo-500\/35 {
  color: #6366f159;
}

.text-indigo-500\/40 {
  color: #6366f166;
}

.text-indigo-500\/45 {
  color: #6366f173;
}

.text-indigo-500\/5 {
  color: #6366f10d;
}

.text-indigo-500\/50 {
  color: #6366f180;
}

.text-indigo-500\/55 {
  color: #6366f18c;
}

.text-indigo-500\/60 {
  color: #6366f199;
}

.text-indigo-500\/65 {
  color: #6366f1a6;
}

.text-indigo-500\/70 {
  color: #6366f1b3;
}

.text-indigo-500\/75 {
  color: #6366f1bf;
}

.text-indigo-500\/80 {
  color: #6366f1cc;
}

.text-indigo-500\/85 {
  color: #6366f1d9;
}

.text-indigo-500\/90 {
  color: #6366f1e6;
}

.text-indigo-500\/95 {
  color: #6366f1f2;
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity, 1));
}

.text-indigo-800\/0 {
  color: #3730a300;
}

.text-indigo-800\/10 {
  color: #3730a31a;
}

.text-indigo-800\/100 {
  color: #3730a3;
}

.text-indigo-800\/15 {
  color: #3730a326;
}

.text-indigo-800\/20 {
  color: #3730a333;
}

.text-indigo-800\/25 {
  color: #3730a340;
}

.text-indigo-800\/30 {
  color: #3730a34d;
}

.text-indigo-800\/35 {
  color: #3730a359;
}

.text-indigo-800\/40 {
  color: #3730a366;
}

.text-indigo-800\/45 {
  color: #3730a373;
}

.text-indigo-800\/5 {
  color: #3730a30d;
}

.text-indigo-800\/50 {
  color: #3730a380;
}

.text-indigo-800\/55 {
  color: #3730a38c;
}

.text-indigo-800\/60 {
  color: #3730a399;
}

.text-indigo-800\/65 {
  color: #3730a3a6;
}

.text-indigo-800\/70 {
  color: #3730a3b3;
}

.text-indigo-800\/75 {
  color: #3730a3bf;
}

.text-indigo-800\/80 {
  color: #3730a3cc;
}

.text-indigo-800\/85 {
  color: #3730a3d9;
}

.text-indigo-800\/90 {
  color: #3730a3e6;
}

.text-indigo-800\/95 {
  color: #3730a3f2;
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity, 1));
}

.text-indigo-900\/0 {
  color: #312e8100;
}

.text-indigo-900\/10 {
  color: #312e811a;
}

.text-indigo-900\/100 {
  color: #312e81;
}

.text-indigo-900\/15 {
  color: #312e8126;
}

.text-indigo-900\/20 {
  color: #312e8133;
}

.text-indigo-900\/25 {
  color: #312e8140;
}

.text-indigo-900\/30 {
  color: #312e814d;
}

.text-indigo-900\/35 {
  color: #312e8159;
}

.text-indigo-900\/40 {
  color: #312e8166;
}

.text-indigo-900\/45 {
  color: #312e8173;
}

.text-indigo-900\/5 {
  color: #312e810d;
}

.text-indigo-900\/50 {
  color: #312e8180;
}

.text-indigo-900\/55 {
  color: #312e818c;
}

.text-indigo-900\/60 {
  color: #312e8199;
}

.text-indigo-900\/65 {
  color: #312e81a6;
}

.text-indigo-900\/70 {
  color: #312e81b3;
}

.text-indigo-900\/75 {
  color: #312e81bf;
}

.text-indigo-900\/80 {
  color: #312e81cc;
}

.text-indigo-900\/85 {
  color: #312e81d9;
}

.text-indigo-900\/90 {
  color: #312e81e6;
}

.text-indigo-900\/95 {
  color: #312e81f2;
}

.text-lime-100 {
  --tw-text-opacity: 1;
  color: rgb(236 252 203 / var(--tw-text-opacity, 1));
}

.text-lime-100\/0 {
  color: #ecfccb00;
}

.text-lime-100\/10 {
  color: #ecfccb1a;
}

.text-lime-100\/100 {
  color: #ecfccb;
}

.text-lime-100\/15 {
  color: #ecfccb26;
}

.text-lime-100\/20 {
  color: #ecfccb33;
}

.text-lime-100\/25 {
  color: #ecfccb40;
}

.text-lime-100\/30 {
  color: #ecfccb4d;
}

.text-lime-100\/35 {
  color: #ecfccb59;
}

.text-lime-100\/40 {
  color: #ecfccb66;
}

.text-lime-100\/45 {
  color: #ecfccb73;
}

.text-lime-100\/5 {
  color: #ecfccb0d;
}

.text-lime-100\/50 {
  color: #ecfccb80;
}

.text-lime-100\/55 {
  color: #ecfccb8c;
}

.text-lime-100\/60 {
  color: #ecfccb99;
}

.text-lime-100\/65 {
  color: #ecfccba6;
}

.text-lime-100\/70 {
  color: #ecfccbb3;
}

.text-lime-100\/75 {
  color: #ecfccbbf;
}

.text-lime-100\/80 {
  color: #ecfccbcc;
}

.text-lime-100\/85 {
  color: #ecfccbd9;
}

.text-lime-100\/90 {
  color: #ecfccbe6;
}

.text-lime-100\/95 {
  color: #ecfccbf2;
}

.text-lime-200 {
  --tw-text-opacity: 1;
  color: rgb(217 249 157 / var(--tw-text-opacity, 1));
}

.text-lime-200\/0 {
  color: #d9f99d00;
}

.text-lime-200\/10 {
  color: #d9f99d1a;
}

.text-lime-200\/100 {
  color: #d9f99d;
}

.text-lime-200\/15 {
  color: #d9f99d26;
}

.text-lime-200\/20 {
  color: #d9f99d33;
}

.text-lime-200\/25 {
  color: #d9f99d40;
}

.text-lime-200\/30 {
  color: #d9f99d4d;
}

.text-lime-200\/35 {
  color: #d9f99d59;
}

.text-lime-200\/40 {
  color: #d9f99d66;
}

.text-lime-200\/45 {
  color: #d9f99d73;
}

.text-lime-200\/5 {
  color: #d9f99d0d;
}

.text-lime-200\/50 {
  color: #d9f99d80;
}

.text-lime-200\/55 {
  color: #d9f99d8c;
}

.text-lime-200\/60 {
  color: #d9f99d99;
}

.text-lime-200\/65 {
  color: #d9f99da6;
}

.text-lime-200\/70 {
  color: #d9f99db3;
}

.text-lime-200\/75 {
  color: #d9f99dbf;
}

.text-lime-200\/80 {
  color: #d9f99dcc;
}

.text-lime-200\/85 {
  color: #d9f99dd9;
}

.text-lime-200\/90 {
  color: #d9f99de6;
}

.text-lime-200\/95 {
  color: #d9f99df2;
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity, 1));
}

.text-lime-500\/0 {
  color: #84cc1600;
}

.text-lime-500\/10 {
  color: #84cc161a;
}

.text-lime-500\/100 {
  color: #84cc16;
}

.text-lime-500\/15 {
  color: #84cc1626;
}

.text-lime-500\/20 {
  color: #84cc1633;
}

.text-lime-500\/25 {
  color: #84cc1640;
}

.text-lime-500\/30 {
  color: #84cc164d;
}

.text-lime-500\/35 {
  color: #84cc1659;
}

.text-lime-500\/40 {
  color: #84cc1666;
}

.text-lime-500\/45 {
  color: #84cc1673;
}

.text-lime-500\/5 {
  color: #84cc160d;
}

.text-lime-500\/50 {
  color: #84cc1680;
}

.text-lime-500\/55 {
  color: #84cc168c;
}

.text-lime-500\/60 {
  color: #84cc1699;
}

.text-lime-500\/65 {
  color: #84cc16a6;
}

.text-lime-500\/70 {
  color: #84cc16b3;
}

.text-lime-500\/75 {
  color: #84cc16bf;
}

.text-lime-500\/80 {
  color: #84cc16cc;
}

.text-lime-500\/85 {
  color: #84cc16d9;
}

.text-lime-500\/90 {
  color: #84cc16e6;
}

.text-lime-500\/95 {
  color: #84cc16f2;
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity, 1));
}

.text-lime-800\/0 {
  color: #3f621200;
}

.text-lime-800\/10 {
  color: #3f62121a;
}

.text-lime-800\/100 {
  color: #3f6212;
}

.text-lime-800\/15 {
  color: #3f621226;
}

.text-lime-800\/20 {
  color: #3f621233;
}

.text-lime-800\/25 {
  color: #3f621240;
}

.text-lime-800\/30 {
  color: #3f62124d;
}

.text-lime-800\/35 {
  color: #3f621259;
}

.text-lime-800\/40 {
  color: #3f621266;
}

.text-lime-800\/45 {
  color: #3f621273;
}

.text-lime-800\/5 {
  color: #3f62120d;
}

.text-lime-800\/50 {
  color: #3f621280;
}

.text-lime-800\/55 {
  color: #3f62128c;
}

.text-lime-800\/60 {
  color: #3f621299;
}

.text-lime-800\/65 {
  color: #3f6212a6;
}

.text-lime-800\/70 {
  color: #3f6212b3;
}

.text-lime-800\/75 {
  color: #3f6212bf;
}

.text-lime-800\/80 {
  color: #3f6212cc;
}

.text-lime-800\/85 {
  color: #3f6212d9;
}

.text-lime-800\/90 {
  color: #3f6212e6;
}

.text-lime-800\/95 {
  color: #3f6212f2;
}

.text-lime-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity, 1));
}

.text-lime-900\/0 {
  color: #36531400;
}

.text-lime-900\/10 {
  color: #3653141a;
}

.text-lime-900\/100 {
  color: #365314;
}

.text-lime-900\/15 {
  color: #36531426;
}

.text-lime-900\/20 {
  color: #36531433;
}

.text-lime-900\/25 {
  color: #36531440;
}

.text-lime-900\/30 {
  color: #3653144d;
}

.text-lime-900\/35 {
  color: #36531459;
}

.text-lime-900\/40 {
  color: #36531466;
}

.text-lime-900\/45 {
  color: #36531473;
}

.text-lime-900\/5 {
  color: #3653140d;
}

.text-lime-900\/50 {
  color: #36531480;
}

.text-lime-900\/55 {
  color: #3653148c;
}

.text-lime-900\/60 {
  color: #36531499;
}

.text-lime-900\/65 {
  color: #365314a6;
}

.text-lime-900\/70 {
  color: #365314b3;
}

.text-lime-900\/75 {
  color: #365314bf;
}

.text-lime-900\/80 {
  color: #365314cc;
}

.text-lime-900\/85 {
  color: #365314d9;
}

.text-lime-900\/90 {
  color: #365314e6;
}

.text-lime-900\/95 {
  color: #365314f2;
}

.text-orange-100 {
  --tw-text-opacity: 1;
  color: rgb(255 237 213 / var(--tw-text-opacity, 1));
}

.text-orange-100\/0 {
  color: #ffedd500;
}

.text-orange-100\/10 {
  color: #ffedd51a;
}

.text-orange-100\/100 {
  color: #ffedd5;
}

.text-orange-100\/15 {
  color: #ffedd526;
}

.text-orange-100\/20 {
  color: #ffedd533;
}

.text-orange-100\/25 {
  color: #ffedd540;
}

.text-orange-100\/30 {
  color: #ffedd54d;
}

.text-orange-100\/35 {
  color: #ffedd559;
}

.text-orange-100\/40 {
  color: #ffedd566;
}

.text-orange-100\/45 {
  color: #ffedd573;
}

.text-orange-100\/5 {
  color: #ffedd50d;
}

.text-orange-100\/50 {
  color: #ffedd580;
}

.text-orange-100\/55 {
  color: #ffedd58c;
}

.text-orange-100\/60 {
  color: #ffedd599;
}

.text-orange-100\/65 {
  color: #ffedd5a6;
}

.text-orange-100\/70 {
  color: #ffedd5b3;
}

.text-orange-100\/75 {
  color: #ffedd5bf;
}

.text-orange-100\/80 {
  color: #ffedd5cc;
}

.text-orange-100\/85 {
  color: #ffedd5d9;
}

.text-orange-100\/90 {
  color: #ffedd5e6;
}

.text-orange-100\/95 {
  color: #ffedd5f2;
}

.text-orange-200 {
  --tw-text-opacity: 1;
  color: rgb(254 215 170 / var(--tw-text-opacity, 1));
}

.text-orange-200\/0 {
  color: #fed7aa00;
}

.text-orange-200\/10 {
  color: #fed7aa1a;
}

.text-orange-200\/100 {
  color: #fed7aa;
}

.text-orange-200\/15 {
  color: #fed7aa26;
}

.text-orange-200\/20 {
  color: #fed7aa33;
}

.text-orange-200\/25 {
  color: #fed7aa40;
}

.text-orange-200\/30 {
  color: #fed7aa4d;
}

.text-orange-200\/35 {
  color: #fed7aa59;
}

.text-orange-200\/40 {
  color: #fed7aa66;
}

.text-orange-200\/45 {
  color: #fed7aa73;
}

.text-orange-200\/5 {
  color: #fed7aa0d;
}

.text-orange-200\/50 {
  color: #fed7aa80;
}

.text-orange-200\/55 {
  color: #fed7aa8c;
}

.text-orange-200\/60 {
  color: #fed7aa99;
}

.text-orange-200\/65 {
  color: #fed7aaa6;
}

.text-orange-200\/70 {
  color: #fed7aab3;
}

.text-orange-200\/75 {
  color: #fed7aabf;
}

.text-orange-200\/80 {
  color: #fed7aacc;
}

.text-orange-200\/85 {
  color: #fed7aad9;
}

.text-orange-200\/90 {
  color: #fed7aae6;
}

.text-orange-200\/95 {
  color: #fed7aaf2;
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity, 1));
}

.text-orange-500\/0 {
  color: #f9731600;
}

.text-orange-500\/10 {
  color: #f973161a;
}

.text-orange-500\/100 {
  color: #f97316;
}

.text-orange-500\/15 {
  color: #f9731626;
}

.text-orange-500\/20 {
  color: #f9731633;
}

.text-orange-500\/25 {
  color: #f9731640;
}

.text-orange-500\/30 {
  color: #f973164d;
}

.text-orange-500\/35 {
  color: #f9731659;
}

.text-orange-500\/40 {
  color: #f9731666;
}

.text-orange-500\/45 {
  color: #f9731673;
}

.text-orange-500\/5 {
  color: #f973160d;
}

.text-orange-500\/50 {
  color: #f9731680;
}

.text-orange-500\/55 {
  color: #f973168c;
}

.text-orange-500\/60 {
  color: #f9731699;
}

.text-orange-500\/65 {
  color: #f97316a6;
}

.text-orange-500\/70 {
  color: #f97316b3;
}

.text-orange-500\/75 {
  color: #f97316bf;
}

.text-orange-500\/80 {
  color: #f97316cc;
}

.text-orange-500\/85 {
  color: #f97316d9;
}

.text-orange-500\/90 {
  color: #f97316e6;
}

.text-orange-500\/95 {
  color: #f97316f2;
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(154 52 18 / var(--tw-text-opacity, 1));
}

.text-orange-800\/0 {
  color: #9a341200;
}

.text-orange-800\/10 {
  color: #9a34121a;
}

.text-orange-800\/100 {
  color: #9a3412;
}

.text-orange-800\/15 {
  color: #9a341226;
}

.text-orange-800\/20 {
  color: #9a341233;
}

.text-orange-800\/25 {
  color: #9a341240;
}

.text-orange-800\/30 {
  color: #9a34124d;
}

.text-orange-800\/35 {
  color: #9a341259;
}

.text-orange-800\/40 {
  color: #9a341266;
}

.text-orange-800\/45 {
  color: #9a341273;
}

.text-orange-800\/5 {
  color: #9a34120d;
}

.text-orange-800\/50 {
  color: #9a341280;
}

.text-orange-800\/55 {
  color: #9a34128c;
}

.text-orange-800\/60 {
  color: #9a341299;
}

.text-orange-800\/65 {
  color: #9a3412a6;
}

.text-orange-800\/70 {
  color: #9a3412b3;
}

.text-orange-800\/75 {
  color: #9a3412bf;
}

.text-orange-800\/80 {
  color: #9a3412cc;
}

.text-orange-800\/85 {
  color: #9a3412d9;
}

.text-orange-800\/90 {
  color: #9a3412e6;
}

.text-orange-800\/95 {
  color: #9a3412f2;
}

.text-orange-900 {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity, 1));
}

.text-orange-900\/0 {
  color: #7c2d1200;
}

.text-orange-900\/10 {
  color: #7c2d121a;
}

.text-orange-900\/100 {
  color: #7c2d12;
}

.text-orange-900\/15 {
  color: #7c2d1226;
}

.text-orange-900\/20 {
  color: #7c2d1233;
}

.text-orange-900\/25 {
  color: #7c2d1240;
}

.text-orange-900\/30 {
  color: #7c2d124d;
}

.text-orange-900\/35 {
  color: #7c2d1259;
}

.text-orange-900\/40 {
  color: #7c2d1266;
}

.text-orange-900\/45 {
  color: #7c2d1273;
}

.text-orange-900\/5 {
  color: #7c2d120d;
}

.text-orange-900\/50 {
  color: #7c2d1280;
}

.text-orange-900\/55 {
  color: #7c2d128c;
}

.text-orange-900\/60 {
  color: #7c2d1299;
}

.text-orange-900\/65 {
  color: #7c2d12a6;
}

.text-orange-900\/70 {
  color: #7c2d12b3;
}

.text-orange-900\/75 {
  color: #7c2d12bf;
}

.text-orange-900\/80 {
  color: #7c2d12cc;
}

.text-orange-900\/85 {
  color: #7c2d12d9;
}

.text-orange-900\/90 {
  color: #7c2d12e6;
}

.text-orange-900\/95 {
  color: #7c2d12f2;
}

.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgb(252 231 243 / var(--tw-text-opacity, 1));
}

.text-pink-100\/0 {
  color: #fce7f300;
}

.text-pink-100\/10 {
  color: #fce7f31a;
}

.text-pink-100\/100 {
  color: #fce7f3;
}

.text-pink-100\/15 {
  color: #fce7f326;
}

.text-pink-100\/20 {
  color: #fce7f333;
}

.text-pink-100\/25 {
  color: #fce7f340;
}

.text-pink-100\/30 {
  color: #fce7f34d;
}

.text-pink-100\/35 {
  color: #fce7f359;
}

.text-pink-100\/40 {
  color: #fce7f366;
}

.text-pink-100\/45 {
  color: #fce7f373;
}

.text-pink-100\/5 {
  color: #fce7f30d;
}

.text-pink-100\/50 {
  color: #fce7f380;
}

.text-pink-100\/55 {
  color: #fce7f38c;
}

.text-pink-100\/60 {
  color: #fce7f399;
}

.text-pink-100\/65 {
  color: #fce7f3a6;
}

.text-pink-100\/70 {
  color: #fce7f3b3;
}

.text-pink-100\/75 {
  color: #fce7f3bf;
}

.text-pink-100\/80 {
  color: #fce7f3cc;
}

.text-pink-100\/85 {
  color: #fce7f3d9;
}

.text-pink-100\/90 {
  color: #fce7f3e6;
}

.text-pink-100\/95 {
  color: #fce7f3f2;
}

.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(251 207 232 / var(--tw-text-opacity, 1));
}

.text-pink-200\/0 {
  color: #fbcfe800;
}

.text-pink-200\/10 {
  color: #fbcfe81a;
}

.text-pink-200\/100 {
  color: #fbcfe8;
}

.text-pink-200\/15 {
  color: #fbcfe826;
}

.text-pink-200\/20 {
  color: #fbcfe833;
}

.text-pink-200\/25 {
  color: #fbcfe840;
}

.text-pink-200\/30 {
  color: #fbcfe84d;
}

.text-pink-200\/35 {
  color: #fbcfe859;
}

.text-pink-200\/40 {
  color: #fbcfe866;
}

.text-pink-200\/45 {
  color: #fbcfe873;
}

.text-pink-200\/5 {
  color: #fbcfe80d;
}

.text-pink-200\/50 {
  color: #fbcfe880;
}

.text-pink-200\/55 {
  color: #fbcfe88c;
}

.text-pink-200\/60 {
  color: #fbcfe899;
}

.text-pink-200\/65 {
  color: #fbcfe8a6;
}

.text-pink-200\/70 {
  color: #fbcfe8b3;
}

.text-pink-200\/75 {
  color: #fbcfe8bf;
}

.text-pink-200\/80 {
  color: #fbcfe8cc;
}

.text-pink-200\/85 {
  color: #fbcfe8d9;
}

.text-pink-200\/90 {
  color: #fbcfe8e6;
}

.text-pink-200\/95 {
  color: #fbcfe8f2;
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity, 1));
}

.text-pink-500\/0 {
  color: #ec489900;
}

.text-pink-500\/10 {
  color: #ec48991a;
}

.text-pink-500\/100 {
  color: #ec4899;
}

.text-pink-500\/15 {
  color: #ec489926;
}

.text-pink-500\/20 {
  color: #ec489933;
}

.text-pink-500\/25 {
  color: #ec489940;
}

.text-pink-500\/30 {
  color: #ec48994d;
}

.text-pink-500\/35 {
  color: #ec489959;
}

.text-pink-500\/40 {
  color: #ec489966;
}

.text-pink-500\/45 {
  color: #ec489973;
}

.text-pink-500\/5 {
  color: #ec48990d;
}

.text-pink-500\/50 {
  color: #ec489980;
}

.text-pink-500\/55 {
  color: #ec48998c;
}

.text-pink-500\/60 {
  color: #ec489999;
}

.text-pink-500\/65 {
  color: #ec4899a6;
}

.text-pink-500\/70 {
  color: #ec4899b3;
}

.text-pink-500\/75 {
  color: #ec4899bf;
}

.text-pink-500\/80 {
  color: #ec4899cc;
}

.text-pink-500\/85 {
  color: #ec4899d9;
}

.text-pink-500\/90 {
  color: #ec4899e6;
}

.text-pink-500\/95 {
  color: #ec4899f2;
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity, 1));
}

.text-pink-800\/0 {
  color: #9d174d00;
}

.text-pink-800\/10 {
  color: #9d174d1a;
}

.text-pink-800\/100 {
  color: #9d174d;
}

.text-pink-800\/15 {
  color: #9d174d26;
}

.text-pink-800\/20 {
  color: #9d174d33;
}

.text-pink-800\/25 {
  color: #9d174d40;
}

.text-pink-800\/30 {
  color: #9d174d4d;
}

.text-pink-800\/35 {
  color: #9d174d59;
}

.text-pink-800\/40 {
  color: #9d174d66;
}

.text-pink-800\/45 {
  color: #9d174d73;
}

.text-pink-800\/5 {
  color: #9d174d0d;
}

.text-pink-800\/50 {
  color: #9d174d80;
}

.text-pink-800\/55 {
  color: #9d174d8c;
}

.text-pink-800\/60 {
  color: #9d174d99;
}

.text-pink-800\/65 {
  color: #9d174da6;
}

.text-pink-800\/70 {
  color: #9d174db3;
}

.text-pink-800\/75 {
  color: #9d174dbf;
}

.text-pink-800\/80 {
  color: #9d174dcc;
}

.text-pink-800\/85 {
  color: #9d174dd9;
}

.text-pink-800\/90 {
  color: #9d174de6;
}

.text-pink-800\/95 {
  color: #9d174df2;
}

.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity, 1));
}

.text-pink-900\/0 {
  color: #83184300;
}

.text-pink-900\/10 {
  color: #8318431a;
}

.text-pink-900\/100 {
  color: #831843;
}

.text-pink-900\/15 {
  color: #83184326;
}

.text-pink-900\/20 {
  color: #83184333;
}

.text-pink-900\/25 {
  color: #83184340;
}

.text-pink-900\/30 {
  color: #8318434d;
}

.text-pink-900\/35 {
  color: #83184359;
}

.text-pink-900\/40 {
  color: #83184366;
}

.text-pink-900\/45 {
  color: #83184373;
}

.text-pink-900\/5 {
  color: #8318430d;
}

.text-pink-900\/50 {
  color: #83184380;
}

.text-pink-900\/55 {
  color: #8318438c;
}

.text-pink-900\/60 {
  color: #83184399;
}

.text-pink-900\/65 {
  color: #831843a6;
}

.text-pink-900\/70 {
  color: #831843b3;
}

.text-pink-900\/75 {
  color: #831843bf;
}

.text-pink-900\/80 {
  color: #831843cc;
}

.text-pink-900\/85 {
  color: #831843d9;
}

.text-pink-900\/90 {
  color: #831843e6;
}

.text-pink-900\/95 {
  color: #831843f2;
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity, 1));
}

.text-purple-100\/0 {
  color: #f3e8ff00;
}

.text-purple-100\/10 {
  color: #f3e8ff1a;
}

.text-purple-100\/100 {
  color: #f3e8ff;
}

.text-purple-100\/15 {
  color: #f3e8ff26;
}

.text-purple-100\/20 {
  color: #f3e8ff33;
}

.text-purple-100\/25 {
  color: #f3e8ff40;
}

.text-purple-100\/30 {
  color: #f3e8ff4d;
}

.text-purple-100\/35 {
  color: #f3e8ff59;
}

.text-purple-100\/40 {
  color: #f3e8ff66;
}

.text-purple-100\/45 {
  color: #f3e8ff73;
}

.text-purple-100\/5 {
  color: #f3e8ff0d;
}

.text-purple-100\/50 {
  color: #f3e8ff80;
}

.text-purple-100\/55 {
  color: #f3e8ff8c;
}

.text-purple-100\/60 {
  color: #f3e8ff99;
}

.text-purple-100\/65 {
  color: #f3e8ffa6;
}

.text-purple-100\/70 {
  color: #f3e8ffb3;
}

.text-purple-100\/75 {
  color: #f3e8ffbf;
}

.text-purple-100\/80 {
  color: #f3e8ffcc;
}

.text-purple-100\/85 {
  color: #f3e8ffd9;
}

.text-purple-100\/90 {
  color: #f3e8ffe6;
}

.text-purple-100\/95 {
  color: #f3e8fff2;
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(233 213 255 / var(--tw-text-opacity, 1));
}

.text-purple-200\/0 {
  color: #e9d5ff00;
}

.text-purple-200\/10 {
  color: #e9d5ff1a;
}

.text-purple-200\/100 {
  color: #e9d5ff;
}

.text-purple-200\/15 {
  color: #e9d5ff26;
}

.text-purple-200\/20 {
  color: #e9d5ff33;
}

.text-purple-200\/25 {
  color: #e9d5ff40;
}

.text-purple-200\/30 {
  color: #e9d5ff4d;
}

.text-purple-200\/35 {
  color: #e9d5ff59;
}

.text-purple-200\/40 {
  color: #e9d5ff66;
}

.text-purple-200\/45 {
  color: #e9d5ff73;
}

.text-purple-200\/5 {
  color: #e9d5ff0d;
}

.text-purple-200\/50 {
  color: #e9d5ff80;
}

.text-purple-200\/55 {
  color: #e9d5ff8c;
}

.text-purple-200\/60 {
  color: #e9d5ff99;
}

.text-purple-200\/65 {
  color: #e9d5ffa6;
}

.text-purple-200\/70 {
  color: #e9d5ffb3;
}

.text-purple-200\/75 {
  color: #e9d5ffbf;
}

.text-purple-200\/80 {
  color: #e9d5ffcc;
}

.text-purple-200\/85 {
  color: #e9d5ffd9;
}

.text-purple-200\/90 {
  color: #e9d5ffe6;
}

.text-purple-200\/95 {
  color: #e9d5fff2;
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.text-purple-500\/0 {
  color: #a855f700;
}

.text-purple-500\/10 {
  color: #a855f71a;
}

.text-purple-500\/100 {
  color: #a855f7;
}

.text-purple-500\/15 {
  color: #a855f726;
}

.text-purple-500\/20 {
  color: #a855f733;
}

.text-purple-500\/25 {
  color: #a855f740;
}

.text-purple-500\/30 {
  color: #a855f74d;
}

.text-purple-500\/35 {
  color: #a855f759;
}

.text-purple-500\/40 {
  color: #a855f766;
}

.text-purple-500\/45 {
  color: #a855f773;
}

.text-purple-500\/5 {
  color: #a855f70d;
}

.text-purple-500\/50 {
  color: #a855f780;
}

.text-purple-500\/55 {
  color: #a855f78c;
}

.text-purple-500\/60 {
  color: #a855f799;
}

.text-purple-500\/65 {
  color: #a855f7a6;
}

.text-purple-500\/70 {
  color: #a855f7b3;
}

.text-purple-500\/75 {
  color: #a855f7bf;
}

.text-purple-500\/80 {
  color: #a855f7cc;
}

.text-purple-500\/85 {
  color: #a855f7d9;
}

.text-purple-500\/90 {
  color: #a855f7e6;
}

.text-purple-500\/95 {
  color: #a855f7f2;
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity, 1));
}

.text-purple-800\/0 {
  color: #6b21a800;
}

.text-purple-800\/10 {
  color: #6b21a81a;
}

.text-purple-800\/100 {
  color: #6b21a8;
}

.text-purple-800\/15 {
  color: #6b21a826;
}

.text-purple-800\/20 {
  color: #6b21a833;
}

.text-purple-800\/25 {
  color: #6b21a840;
}

.text-purple-800\/30 {
  color: #6b21a84d;
}

.text-purple-800\/35 {
  color: #6b21a859;
}

.text-purple-800\/40 {
  color: #6b21a866;
}

.text-purple-800\/45 {
  color: #6b21a873;
}

.text-purple-800\/5 {
  color: #6b21a80d;
}

.text-purple-800\/50 {
  color: #6b21a880;
}

.text-purple-800\/55 {
  color: #6b21a88c;
}

.text-purple-800\/60 {
  color: #6b21a899;
}

.text-purple-800\/65 {
  color: #6b21a8a6;
}

.text-purple-800\/70 {
  color: #6b21a8b3;
}

.text-purple-800\/75 {
  color: #6b21a8bf;
}

.text-purple-800\/80 {
  color: #6b21a8cc;
}

.text-purple-800\/85 {
  color: #6b21a8d9;
}

.text-purple-800\/90 {
  color: #6b21a8e6;
}

.text-purple-800\/95 {
  color: #6b21a8f2;
}

.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity, 1));
}

.text-purple-900\/0 {
  color: #581c8700;
}

.text-purple-900\/10 {
  color: #581c871a;
}

.text-purple-900\/100 {
  color: #581c87;
}

.text-purple-900\/15 {
  color: #581c8726;
}

.text-purple-900\/20 {
  color: #581c8733;
}

.text-purple-900\/25 {
  color: #581c8740;
}

.text-purple-900\/30 {
  color: #581c874d;
}

.text-purple-900\/35 {
  color: #581c8759;
}

.text-purple-900\/40 {
  color: #581c8766;
}

.text-purple-900\/45 {
  color: #581c8773;
}

.text-purple-900\/5 {
  color: #581c870d;
}

.text-purple-900\/50 {
  color: #581c8780;
}

.text-purple-900\/55 {
  color: #581c878c;
}

.text-purple-900\/60 {
  color: #581c8799;
}

.text-purple-900\/65 {
  color: #581c87a6;
}

.text-purple-900\/70 {
  color: #581c87b3;
}

.text-purple-900\/75 {
  color: #581c87bf;
}

.text-purple-900\/80 {
  color: #581c87cc;
}

.text-purple-900\/85 {
  color: #581c87d9;
}

.text-purple-900\/90 {
  color: #581c87e6;
}

.text-purple-900\/95 {
  color: #581c87f2;
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity, 1));
}

.text-red-100\/0 {
  color: #fee2e200;
}

.text-red-100\/10 {
  color: #fee2e21a;
}

.text-red-100\/100 {
  color: #fee2e2;
}

.text-red-100\/15 {
  color: #fee2e226;
}

.text-red-100\/20 {
  color: #fee2e233;
}

.text-red-100\/25 {
  color: #fee2e240;
}

.text-red-100\/30 {
  color: #fee2e24d;
}

.text-red-100\/35 {
  color: #fee2e259;
}

.text-red-100\/40 {
  color: #fee2e266;
}

.text-red-100\/45 {
  color: #fee2e273;
}

.text-red-100\/5 {
  color: #fee2e20d;
}

.text-red-100\/50 {
  color: #fee2e280;
}

.text-red-100\/55 {
  color: #fee2e28c;
}

.text-red-100\/60 {
  color: #fee2e299;
}

.text-red-100\/65 {
  color: #fee2e2a6;
}

.text-red-100\/70 {
  color: #fee2e2b3;
}

.text-red-100\/75 {
  color: #fee2e2bf;
}

.text-red-100\/80 {
  color: #fee2e2cc;
}

.text-red-100\/85 {
  color: #fee2e2d9;
}

.text-red-100\/90 {
  color: #fee2e2e6;
}

.text-red-100\/95 {
  color: #fee2e2f2;
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity, 1));
}

.text-red-200\/0 {
  color: #fecaca00;
}

.text-red-200\/10 {
  color: #fecaca1a;
}

.text-red-200\/100 {
  color: #fecaca;
}

.text-red-200\/15 {
  color: #fecaca26;
}

.text-red-200\/20 {
  color: #fecaca33;
}

.text-red-200\/25 {
  color: #fecaca40;
}

.text-red-200\/30 {
  color: #fecaca4d;
}

.text-red-200\/35 {
  color: #fecaca59;
}

.text-red-200\/40 {
  color: #fecaca66;
}

.text-red-200\/45 {
  color: #fecaca73;
}

.text-red-200\/5 {
  color: #fecaca0d;
}

.text-red-200\/50 {
  color: #fecaca80;
}

.text-red-200\/55 {
  color: #fecaca8c;
}

.text-red-200\/60 {
  color: #fecaca99;
}

.text-red-200\/65 {
  color: #fecacaa6;
}

.text-red-200\/70 {
  color: #fecacab3;
}

.text-red-200\/75 {
  color: #fecacabf;
}

.text-red-200\/80 {
  color: #fecacacc;
}

.text-red-200\/85 {
  color: #fecacad9;
}

.text-red-200\/90 {
  color: #fecacae6;
}

.text-red-200\/95 {
  color: #fecacaf2;
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.text-red-500\/0 {
  color: #ef444400;
}

.text-red-500\/10 {
  color: #ef44441a;
}

.text-red-500\/100 {
  color: #ef4444;
}

.text-red-500\/15 {
  color: #ef444426;
}

.text-red-500\/20 {
  color: #ef444433;
}

.text-red-500\/25 {
  color: #ef444440;
}

.text-red-500\/30 {
  color: #ef44444d;
}

.text-red-500\/35 {
  color: #ef444459;
}

.text-red-500\/40 {
  color: #ef444466;
}

.text-red-500\/45 {
  color: #ef444473;
}

.text-red-500\/5 {
  color: #ef44440d;
}

.text-red-500\/50 {
  color: #ef444480;
}

.text-red-500\/55 {
  color: #ef44448c;
}

.text-red-500\/60 {
  color: #ef444499;
}

.text-red-500\/65 {
  color: #ef4444a6;
}

.text-red-500\/70 {
  color: #ef4444b3;
}

.text-red-500\/75 {
  color: #ef4444bf;
}

.text-red-500\/80 {
  color: #ef4444cc;
}

.text-red-500\/85 {
  color: #ef4444d9;
}

.text-red-500\/90 {
  color: #ef4444e6;
}

.text-red-500\/95 {
  color: #ef4444f2;
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.text-red-800\/0 {
  color: #991b1b00;
}

.text-red-800\/10 {
  color: #991b1b1a;
}

.text-red-800\/100 {
  color: #991b1b;
}

.text-red-800\/15 {
  color: #991b1b26;
}

.text-red-800\/20 {
  color: #991b1b33;
}

.text-red-800\/25 {
  color: #991b1b40;
}

.text-red-800\/30 {
  color: #991b1b4d;
}

.text-red-800\/35 {
  color: #991b1b59;
}

.text-red-800\/40 {
  color: #991b1b66;
}

.text-red-800\/45 {
  color: #991b1b73;
}

.text-red-800\/5 {
  color: #991b1b0d;
}

.text-red-800\/50 {
  color: #991b1b80;
}

.text-red-800\/55 {
  color: #991b1b8c;
}

.text-red-800\/60 {
  color: #991b1b99;
}

.text-red-800\/65 {
  color: #991b1ba6;
}

.text-red-800\/70 {
  color: #991b1bb3;
}

.text-red-800\/75 {
  color: #991b1bbf;
}

.text-red-800\/80 {
  color: #991b1bcc;
}

.text-red-800\/85 {
  color: #991b1bd9;
}

.text-red-800\/90 {
  color: #991b1be6;
}

.text-red-800\/95 {
  color: #991b1bf2;
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity, 1));
}

.text-red-900\/0 {
  color: #7f1d1d00;
}

.text-red-900\/10 {
  color: #7f1d1d1a;
}

.text-red-900\/100 {
  color: #7f1d1d;
}

.text-red-900\/15 {
  color: #7f1d1d26;
}

.text-red-900\/20 {
  color: #7f1d1d33;
}

.text-red-900\/25 {
  color: #7f1d1d40;
}

.text-red-900\/30 {
  color: #7f1d1d4d;
}

.text-red-900\/35 {
  color: #7f1d1d59;
}

.text-red-900\/40 {
  color: #7f1d1d66;
}

.text-red-900\/45 {
  color: #7f1d1d73;
}

.text-red-900\/5 {
  color: #7f1d1d0d;
}

.text-red-900\/50 {
  color: #7f1d1d80;
}

.text-red-900\/55 {
  color: #7f1d1d8c;
}

.text-red-900\/60 {
  color: #7f1d1d99;
}

.text-red-900\/65 {
  color: #7f1d1da6;
}

.text-red-900\/70 {
  color: #7f1d1db3;
}

.text-red-900\/75 {
  color: #7f1d1dbf;
}

.text-red-900\/80 {
  color: #7f1d1dcc;
}

.text-red-900\/85 {
  color: #7f1d1dd9;
}

.text-red-900\/90 {
  color: #7f1d1de6;
}

.text-red-900\/95 {
  color: #7f1d1df2;
}

.text-rose-100 {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity, 1));
}

.text-rose-100\/0 {
  color: #ffe4e600;
}

.text-rose-100\/10 {
  color: #ffe4e61a;
}

.text-rose-100\/100 {
  color: #ffe4e6;
}

.text-rose-100\/15 {
  color: #ffe4e626;
}

.text-rose-100\/20 {
  color: #ffe4e633;
}

.text-rose-100\/25 {
  color: #ffe4e640;
}

.text-rose-100\/30 {
  color: #ffe4e64d;
}

.text-rose-100\/35 {
  color: #ffe4e659;
}

.text-rose-100\/40 {
  color: #ffe4e666;
}

.text-rose-100\/45 {
  color: #ffe4e673;
}

.text-rose-100\/5 {
  color: #ffe4e60d;
}

.text-rose-100\/50 {
  color: #ffe4e680;
}

.text-rose-100\/55 {
  color: #ffe4e68c;
}

.text-rose-100\/60 {
  color: #ffe4e699;
}

.text-rose-100\/65 {
  color: #ffe4e6a6;
}

.text-rose-100\/70 {
  color: #ffe4e6b3;
}

.text-rose-100\/75 {
  color: #ffe4e6bf;
}

.text-rose-100\/80 {
  color: #ffe4e6cc;
}

.text-rose-100\/85 {
  color: #ffe4e6d9;
}

.text-rose-100\/90 {
  color: #ffe4e6e6;
}

.text-rose-100\/95 {
  color: #ffe4e6f2;
}

.text-rose-200 {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity, 1));
}

.text-rose-200\/0 {
  color: #fecdd300;
}

.text-rose-200\/10 {
  color: #fecdd31a;
}

.text-rose-200\/100 {
  color: #fecdd3;
}

.text-rose-200\/15 {
  color: #fecdd326;
}

.text-rose-200\/20 {
  color: #fecdd333;
}

.text-rose-200\/25 {
  color: #fecdd340;
}

.text-rose-200\/30 {
  color: #fecdd34d;
}

.text-rose-200\/35 {
  color: #fecdd359;
}

.text-rose-200\/40 {
  color: #fecdd366;
}

.text-rose-200\/45 {
  color: #fecdd373;
}

.text-rose-200\/5 {
  color: #fecdd30d;
}

.text-rose-200\/50 {
  color: #fecdd380;
}

.text-rose-200\/55 {
  color: #fecdd38c;
}

.text-rose-200\/60 {
  color: #fecdd399;
}

.text-rose-200\/65 {
  color: #fecdd3a6;
}

.text-rose-200\/70 {
  color: #fecdd3b3;
}

.text-rose-200\/75 {
  color: #fecdd3bf;
}

.text-rose-200\/80 {
  color: #fecdd3cc;
}

.text-rose-200\/85 {
  color: #fecdd3d9;
}

.text-rose-200\/90 {
  color: #fecdd3e6;
}

.text-rose-200\/95 {
  color: #fecdd3f2;
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity, 1));
}

.text-rose-500\/0 {
  color: #f43f5e00;
}

.text-rose-500\/10 {
  color: #f43f5e1a;
}

.text-rose-500\/100 {
  color: #f43f5e;
}

.text-rose-500\/15 {
  color: #f43f5e26;
}

.text-rose-500\/20 {
  color: #f43f5e33;
}

.text-rose-500\/25 {
  color: #f43f5e40;
}

.text-rose-500\/30 {
  color: #f43f5e4d;
}

.text-rose-500\/35 {
  color: #f43f5e59;
}

.text-rose-500\/40 {
  color: #f43f5e66;
}

.text-rose-500\/45 {
  color: #f43f5e73;
}

.text-rose-500\/5 {
  color: #f43f5e0d;
}

.text-rose-500\/50 {
  color: #f43f5e80;
}

.text-rose-500\/55 {
  color: #f43f5e8c;
}

.text-rose-500\/60 {
  color: #f43f5e99;
}

.text-rose-500\/65 {
  color: #f43f5ea6;
}

.text-rose-500\/70 {
  color: #f43f5eb3;
}

.text-rose-500\/75 {
  color: #f43f5ebf;
}

.text-rose-500\/80 {
  color: #f43f5ecc;
}

.text-rose-500\/85 {
  color: #f43f5ed9;
}

.text-rose-500\/90 {
  color: #f43f5ee6;
}

.text-rose-500\/95 {
  color: #f43f5ef2;
}

.text-rose-800 {
  --tw-text-opacity: 1;
  color: rgb(159 18 57 / var(--tw-text-opacity, 1));
}

.text-rose-800\/0 {
  color: #9f123900;
}

.text-rose-800\/10 {
  color: #9f12391a;
}

.text-rose-800\/100 {
  color: #9f1239;
}

.text-rose-800\/15 {
  color: #9f123926;
}

.text-rose-800\/20 {
  color: #9f123933;
}

.text-rose-800\/25 {
  color: #9f123940;
}

.text-rose-800\/30 {
  color: #9f12394d;
}

.text-rose-800\/35 {
  color: #9f123959;
}

.text-rose-800\/40 {
  color: #9f123966;
}

.text-rose-800\/45 {
  color: #9f123973;
}

.text-rose-800\/5 {
  color: #9f12390d;
}

.text-rose-800\/50 {
  color: #9f123980;
}

.text-rose-800\/55 {
  color: #9f12398c;
}

.text-rose-800\/60 {
  color: #9f123999;
}

.text-rose-800\/65 {
  color: #9f1239a6;
}

.text-rose-800\/70 {
  color: #9f1239b3;
}

.text-rose-800\/75 {
  color: #9f1239bf;
}

.text-rose-800\/80 {
  color: #9f1239cc;
}

.text-rose-800\/85 {
  color: #9f1239d9;
}

.text-rose-800\/90 {
  color: #9f1239e6;
}

.text-rose-800\/95 {
  color: #9f1239f2;
}

.text-rose-900 {
  --tw-text-opacity: 1;
  color: rgb(136 19 55 / var(--tw-text-opacity, 1));
}

.text-rose-900\/0 {
  color: #88133700;
}

.text-rose-900\/10 {
  color: #8813371a;
}

.text-rose-900\/100 {
  color: #881337;
}

.text-rose-900\/15 {
  color: #88133726;
}

.text-rose-900\/20 {
  color: #88133733;
}

.text-rose-900\/25 {
  color: #88133740;
}

.text-rose-900\/30 {
  color: #8813374d;
}

.text-rose-900\/35 {
  color: #88133759;
}

.text-rose-900\/40 {
  color: #88133766;
}

.text-rose-900\/45 {
  color: #88133773;
}

.text-rose-900\/5 {
  color: #8813370d;
}

.text-rose-900\/50 {
  color: #88133780;
}

.text-rose-900\/55 {
  color: #8813378c;
}

.text-rose-900\/60 {
  color: #88133799;
}

.text-rose-900\/65 {
  color: #881337a6;
}

.text-rose-900\/70 {
  color: #881337b3;
}

.text-rose-900\/75 {
  color: #881337bf;
}

.text-rose-900\/80 {
  color: #881337cc;
}

.text-rose-900\/85 {
  color: #881337d9;
}

.text-rose-900\/90 {
  color: #881337e6;
}

.text-rose-900\/95 {
  color: #881337f2;
}

.text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity, 1));
}

.text-sky-100\/0 {
  color: #e0f2fe00;
}

.text-sky-100\/10 {
  color: #e0f2fe1a;
}

.text-sky-100\/100 {
  color: #e0f2fe;
}

.text-sky-100\/15 {
  color: #e0f2fe26;
}

.text-sky-100\/20 {
  color: #e0f2fe33;
}

.text-sky-100\/25 {
  color: #e0f2fe40;
}

.text-sky-100\/30 {
  color: #e0f2fe4d;
}

.text-sky-100\/35 {
  color: #e0f2fe59;
}

.text-sky-100\/40 {
  color: #e0f2fe66;
}

.text-sky-100\/45 {
  color: #e0f2fe73;
}

.text-sky-100\/5 {
  color: #e0f2fe0d;
}

.text-sky-100\/50 {
  color: #e0f2fe80;
}

.text-sky-100\/55 {
  color: #e0f2fe8c;
}

.text-sky-100\/60 {
  color: #e0f2fe99;
}

.text-sky-100\/65 {
  color: #e0f2fea6;
}

.text-sky-100\/70 {
  color: #e0f2feb3;
}

.text-sky-100\/75 {
  color: #e0f2febf;
}

.text-sky-100\/80 {
  color: #e0f2fecc;
}

.text-sky-100\/85 {
  color: #e0f2fed9;
}

.text-sky-100\/90 {
  color: #e0f2fee6;
}

.text-sky-100\/95 {
  color: #e0f2fef2;
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity, 1));
}

.text-sky-200\/0 {
  color: #bae6fd00;
}

.text-sky-200\/10 {
  color: #bae6fd1a;
}

.text-sky-200\/100 {
  color: #bae6fd;
}

.text-sky-200\/15 {
  color: #bae6fd26;
}

.text-sky-200\/20 {
  color: #bae6fd33;
}

.text-sky-200\/25 {
  color: #bae6fd40;
}

.text-sky-200\/30 {
  color: #bae6fd4d;
}

.text-sky-200\/35 {
  color: #bae6fd59;
}

.text-sky-200\/40 {
  color: #bae6fd66;
}

.text-sky-200\/45 {
  color: #bae6fd73;
}

.text-sky-200\/5 {
  color: #bae6fd0d;
}

.text-sky-200\/50 {
  color: #bae6fd80;
}

.text-sky-200\/55 {
  color: #bae6fd8c;
}

.text-sky-200\/60 {
  color: #bae6fd99;
}

.text-sky-200\/65 {
  color: #bae6fda6;
}

.text-sky-200\/70 {
  color: #bae6fdb3;
}

.text-sky-200\/75 {
  color: #bae6fdbf;
}

.text-sky-200\/80 {
  color: #bae6fdcc;
}

.text-sky-200\/85 {
  color: #bae6fdd9;
}

.text-sky-200\/90 {
  color: #bae6fde6;
}

.text-sky-200\/95 {
  color: #bae6fdf2;
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity, 1));
}

.text-sky-500\/0 {
  color: #0ea5e900;
}

.text-sky-500\/10 {
  color: #0ea5e91a;
}

.text-sky-500\/100 {
  color: #0ea5e9;
}

.text-sky-500\/15 {
  color: #0ea5e926;
}

.text-sky-500\/20 {
  color: #0ea5e933;
}

.text-sky-500\/25 {
  color: #0ea5e940;
}

.text-sky-500\/30 {
  color: #0ea5e94d;
}

.text-sky-500\/35 {
  color: #0ea5e959;
}

.text-sky-500\/40 {
  color: #0ea5e966;
}

.text-sky-500\/45 {
  color: #0ea5e973;
}

.text-sky-500\/5 {
  color: #0ea5e90d;
}

.text-sky-500\/50 {
  color: #0ea5e980;
}

.text-sky-500\/55 {
  color: #0ea5e98c;
}

.text-sky-500\/60 {
  color: #0ea5e999;
}

.text-sky-500\/65 {
  color: #0ea5e9a6;
}

.text-sky-500\/70 {
  color: #0ea5e9b3;
}

.text-sky-500\/75 {
  color: #0ea5e9bf;
}

.text-sky-500\/80 {
  color: #0ea5e9cc;
}

.text-sky-500\/85 {
  color: #0ea5e9d9;
}

.text-sky-500\/90 {
  color: #0ea5e9e6;
}

.text-sky-500\/95 {
  color: #0ea5e9f2;
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity, 1));
}

.text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity, 1));
}

.text-sky-800\/0 {
  color: #07598500;
}

.text-sky-800\/10 {
  color: #0759851a;
}

.text-sky-800\/100 {
  color: #075985;
}

.text-sky-800\/15 {
  color: #07598526;
}

.text-sky-800\/20 {
  color: #07598533;
}

.text-sky-800\/25 {
  color: #07598540;
}

.text-sky-800\/30 {
  color: #0759854d;
}

.text-sky-800\/35 {
  color: #07598559;
}

.text-sky-800\/40 {
  color: #07598566;
}

.text-sky-800\/45 {
  color: #07598573;
}

.text-sky-800\/5 {
  color: #0759850d;
}

.text-sky-800\/50 {
  color: #07598580;
}

.text-sky-800\/55 {
  color: #0759858c;
}

.text-sky-800\/60 {
  color: #07598599;
}

.text-sky-800\/65 {
  color: #075985a6;
}

.text-sky-800\/70 {
  color: #075985b3;
}

.text-sky-800\/75 {
  color: #075985bf;
}

.text-sky-800\/80 {
  color: #075985cc;
}

.text-sky-800\/85 {
  color: #075985d9;
}

.text-sky-800\/90 {
  color: #075985e6;
}

.text-sky-800\/95 {
  color: #075985f2;
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity, 1));
}

.text-sky-900\/0 {
  color: #0c4a6e00;
}

.text-sky-900\/10 {
  color: #0c4a6e1a;
}

.text-sky-900\/100 {
  color: #0c4a6e;
}

.text-sky-900\/15 {
  color: #0c4a6e26;
}

.text-sky-900\/20 {
  color: #0c4a6e33;
}

.text-sky-900\/25 {
  color: #0c4a6e40;
}

.text-sky-900\/30 {
  color: #0c4a6e4d;
}

.text-sky-900\/35 {
  color: #0c4a6e59;
}

.text-sky-900\/40 {
  color: #0c4a6e66;
}

.text-sky-900\/45 {
  color: #0c4a6e73;
}

.text-sky-900\/5 {
  color: #0c4a6e0d;
}

.text-sky-900\/50 {
  color: #0c4a6e80;
}

.text-sky-900\/55 {
  color: #0c4a6e8c;
}

.text-sky-900\/60 {
  color: #0c4a6e99;
}

.text-sky-900\/65 {
  color: #0c4a6ea6;
}

.text-sky-900\/70 {
  color: #0c4a6eb3;
}

.text-sky-900\/75 {
  color: #0c4a6ebf;
}

.text-sky-900\/80 {
  color: #0c4a6ecc;
}

.text-sky-900\/85 {
  color: #0c4a6ed9;
}

.text-sky-900\/90 {
  color: #0c4a6ee6;
}

.text-sky-900\/95 {
  color: #0c4a6ef2;
}

.text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity, 1));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity, 1));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity, 1));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1));
}

.text-stone-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 244 / var(--tw-text-opacity, 1));
}

.text-stone-100\/0 {
  color: #f5f5f400;
}

.text-stone-100\/10 {
  color: #f5f5f41a;
}

.text-stone-100\/100 {
  color: #f5f5f4;
}

.text-stone-100\/15 {
  color: #f5f5f426;
}

.text-stone-100\/20 {
  color: #f5f5f433;
}

.text-stone-100\/25 {
  color: #f5f5f440;
}

.text-stone-100\/30 {
  color: #f5f5f44d;
}

.text-stone-100\/35 {
  color: #f5f5f459;
}

.text-stone-100\/40 {
  color: #f5f5f466;
}

.text-stone-100\/45 {
  color: #f5f5f473;
}

.text-stone-100\/5 {
  color: #f5f5f40d;
}

.text-stone-100\/50 {
  color: #f5f5f480;
}

.text-stone-100\/55 {
  color: #f5f5f48c;
}

.text-stone-100\/60 {
  color: #f5f5f499;
}

.text-stone-100\/65 {
  color: #f5f5f4a6;
}

.text-stone-100\/70 {
  color: #f5f5f4b3;
}

.text-stone-100\/75 {
  color: #f5f5f4bf;
}

.text-stone-100\/80 {
  color: #f5f5f4cc;
}

.text-stone-100\/85 {
  color: #f5f5f4d9;
}

.text-stone-100\/90 {
  color: #f5f5f4e6;
}

.text-stone-100\/95 {
  color: #f5f5f4f2;
}

.text-stone-200 {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity, 1));
}

.text-stone-200\/0 {
  color: #e7e5e400;
}

.text-stone-200\/10 {
  color: #e7e5e41a;
}

.text-stone-200\/100 {
  color: #e7e5e4;
}

.text-stone-200\/15 {
  color: #e7e5e426;
}

.text-stone-200\/20 {
  color: #e7e5e433;
}

.text-stone-200\/25 {
  color: #e7e5e440;
}

.text-stone-200\/30 {
  color: #e7e5e44d;
}

.text-stone-200\/35 {
  color: #e7e5e459;
}

.text-stone-200\/40 {
  color: #e7e5e466;
}

.text-stone-200\/45 {
  color: #e7e5e473;
}

.text-stone-200\/5 {
  color: #e7e5e40d;
}

.text-stone-200\/50 {
  color: #e7e5e480;
}

.text-stone-200\/55 {
  color: #e7e5e48c;
}

.text-stone-200\/60 {
  color: #e7e5e499;
}

.text-stone-200\/65 {
  color: #e7e5e4a6;
}

.text-stone-200\/70 {
  color: #e7e5e4b3;
}

.text-stone-200\/75 {
  color: #e7e5e4bf;
}

.text-stone-200\/80 {
  color: #e7e5e4cc;
}

.text-stone-200\/85 {
  color: #e7e5e4d9;
}

.text-stone-200\/90 {
  color: #e7e5e4e6;
}

.text-stone-200\/95 {
  color: #e7e5e4f2;
}

.text-stone-500 {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity, 1));
}

.text-stone-500\/0 {
  color: #78716c00;
}

.text-stone-500\/10 {
  color: #78716c1a;
}

.text-stone-500\/100 {
  color: #78716c;
}

.text-stone-500\/15 {
  color: #78716c26;
}

.text-stone-500\/20 {
  color: #78716c33;
}

.text-stone-500\/25 {
  color: #78716c40;
}

.text-stone-500\/30 {
  color: #78716c4d;
}

.text-stone-500\/35 {
  color: #78716c59;
}

.text-stone-500\/40 {
  color: #78716c66;
}

.text-stone-500\/45 {
  color: #78716c73;
}

.text-stone-500\/5 {
  color: #78716c0d;
}

.text-stone-500\/50 {
  color: #78716c80;
}

.text-stone-500\/55 {
  color: #78716c8c;
}

.text-stone-500\/60 {
  color: #78716c99;
}

.text-stone-500\/65 {
  color: #78716ca6;
}

.text-stone-500\/70 {
  color: #78716cb3;
}

.text-stone-500\/75 {
  color: #78716cbf;
}

.text-stone-500\/80 {
  color: #78716ccc;
}

.text-stone-500\/85 {
  color: #78716cd9;
}

.text-stone-500\/90 {
  color: #78716ce6;
}

.text-stone-500\/95 {
  color: #78716cf2;
}

.text-stone-800 {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity, 1));
}

.text-stone-800\/0 {
  color: #29252400;
}

.text-stone-800\/10 {
  color: #2925241a;
}

.text-stone-800\/100 {
  color: #292524;
}

.text-stone-800\/15 {
  color: #29252426;
}

.text-stone-800\/20 {
  color: #29252433;
}

.text-stone-800\/25 {
  color: #29252440;
}

.text-stone-800\/30 {
  color: #2925244d;
}

.text-stone-800\/35 {
  color: #29252459;
}

.text-stone-800\/40 {
  color: #29252466;
}

.text-stone-800\/45 {
  color: #29252473;
}

.text-stone-800\/5 {
  color: #2925240d;
}

.text-stone-800\/50 {
  color: #29252480;
}

.text-stone-800\/55 {
  color: #2925248c;
}

.text-stone-800\/60 {
  color: #29252499;
}

.text-stone-800\/65 {
  color: #292524a6;
}

.text-stone-800\/70 {
  color: #292524b3;
}

.text-stone-800\/75 {
  color: #292524bf;
}

.text-stone-800\/80 {
  color: #292524cc;
}

.text-stone-800\/85 {
  color: #292524d9;
}

.text-stone-800\/90 {
  color: #292524e6;
}

.text-stone-800\/95 {
  color: #292524f2;
}

.text-stone-900 {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity, 1));
}

.text-stone-900\/0 {
  color: #1c191700;
}

.text-stone-900\/10 {
  color: #1c19171a;
}

.text-stone-900\/100 {
  color: #1c1917;
}

.text-stone-900\/15 {
  color: #1c191726;
}

.text-stone-900\/20 {
  color: #1c191733;
}

.text-stone-900\/25 {
  color: #1c191740;
}

.text-stone-900\/30 {
  color: #1c19174d;
}

.text-stone-900\/35 {
  color: #1c191759;
}

.text-stone-900\/40 {
  color: #1c191766;
}

.text-stone-900\/45 {
  color: #1c191773;
}

.text-stone-900\/5 {
  color: #1c19170d;
}

.text-stone-900\/50 {
  color: #1c191780;
}

.text-stone-900\/55 {
  color: #1c19178c;
}

.text-stone-900\/60 {
  color: #1c191799;
}

.text-stone-900\/65 {
  color: #1c1917a6;
}

.text-stone-900\/70 {
  color: #1c1917b3;
}

.text-stone-900\/75 {
  color: #1c1917bf;
}

.text-stone-900\/80 {
  color: #1c1917cc;
}

.text-stone-900\/85 {
  color: #1c1917d9;
}

.text-stone-900\/90 {
  color: #1c1917e6;
}

.text-stone-900\/95 {
  color: #1c1917f2;
}

.text-teal-100 {
  --tw-text-opacity: 1;
  color: rgb(204 251 241 / var(--tw-text-opacity, 1));
}

.text-teal-100\/0 {
  color: #ccfbf100;
}

.text-teal-100\/10 {
  color: #ccfbf11a;
}

.text-teal-100\/100 {
  color: #ccfbf1;
}

.text-teal-100\/15 {
  color: #ccfbf126;
}

.text-teal-100\/20 {
  color: #ccfbf133;
}

.text-teal-100\/25 {
  color: #ccfbf140;
}

.text-teal-100\/30 {
  color: #ccfbf14d;
}

.text-teal-100\/35 {
  color: #ccfbf159;
}

.text-teal-100\/40 {
  color: #ccfbf166;
}

.text-teal-100\/45 {
  color: #ccfbf173;
}

.text-teal-100\/5 {
  color: #ccfbf10d;
}

.text-teal-100\/50 {
  color: #ccfbf180;
}

.text-teal-100\/55 {
  color: #ccfbf18c;
}

.text-teal-100\/60 {
  color: #ccfbf199;
}

.text-teal-100\/65 {
  color: #ccfbf1a6;
}

.text-teal-100\/70 {
  color: #ccfbf1b3;
}

.text-teal-100\/75 {
  color: #ccfbf1bf;
}

.text-teal-100\/80 {
  color: #ccfbf1cc;
}

.text-teal-100\/85 {
  color: #ccfbf1d9;
}

.text-teal-100\/90 {
  color: #ccfbf1e6;
}

.text-teal-100\/95 {
  color: #ccfbf1f2;
}

.text-teal-200 {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity, 1));
}

.text-teal-200\/0 {
  color: #99f6e400;
}

.text-teal-200\/10 {
  color: #99f6e41a;
}

.text-teal-200\/100 {
  color: #99f6e4;
}

.text-teal-200\/15 {
  color: #99f6e426;
}

.text-teal-200\/20 {
  color: #99f6e433;
}

.text-teal-200\/25 {
  color: #99f6e440;
}

.text-teal-200\/30 {
  color: #99f6e44d;
}

.text-teal-200\/35 {
  color: #99f6e459;
}

.text-teal-200\/40 {
  color: #99f6e466;
}

.text-teal-200\/45 {
  color: #99f6e473;
}

.text-teal-200\/5 {
  color: #99f6e40d;
}

.text-teal-200\/50 {
  color: #99f6e480;
}

.text-teal-200\/55 {
  color: #99f6e48c;
}

.text-teal-200\/60 {
  color: #99f6e499;
}

.text-teal-200\/65 {
  color: #99f6e4a6;
}

.text-teal-200\/70 {
  color: #99f6e4b3;
}

.text-teal-200\/75 {
  color: #99f6e4bf;
}

.text-teal-200\/80 {
  color: #99f6e4cc;
}

.text-teal-200\/85 {
  color: #99f6e4d9;
}

.text-teal-200\/90 {
  color: #99f6e4e6;
}

.text-teal-200\/95 {
  color: #99f6e4f2;
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}

.text-teal-500\/0 {
  color: #14b8a600;
}

.text-teal-500\/10 {
  color: #14b8a61a;
}

.text-teal-500\/100 {
  color: #14b8a6;
}

.text-teal-500\/15 {
  color: #14b8a626;
}

.text-teal-500\/20 {
  color: #14b8a633;
}

.text-teal-500\/25 {
  color: #14b8a640;
}

.text-teal-500\/30 {
  color: #14b8a64d;
}

.text-teal-500\/35 {
  color: #14b8a659;
}

.text-teal-500\/40 {
  color: #14b8a666;
}

.text-teal-500\/45 {
  color: #14b8a673;
}

.text-teal-500\/5 {
  color: #14b8a60d;
}

.text-teal-500\/50 {
  color: #14b8a680;
}

.text-teal-500\/55 {
  color: #14b8a68c;
}

.text-teal-500\/60 {
  color: #14b8a699;
}

.text-teal-500\/65 {
  color: #14b8a6a6;
}

.text-teal-500\/70 {
  color: #14b8a6b3;
}

.text-teal-500\/75 {
  color: #14b8a6bf;
}

.text-teal-500\/80 {
  color: #14b8a6cc;
}

.text-teal-500\/85 {
  color: #14b8a6d9;
}

.text-teal-500\/90 {
  color: #14b8a6e6;
}

.text-teal-500\/95 {
  color: #14b8a6f2;
}

.text-teal-800 {
  --tw-text-opacity: 1;
  color: rgb(17 94 89 / var(--tw-text-opacity, 1));
}

.text-teal-800\/0 {
  color: #115e5900;
}

.text-teal-800\/10 {
  color: #115e591a;
}

.text-teal-800\/100 {
  color: #115e59;
}

.text-teal-800\/15 {
  color: #115e5926;
}

.text-teal-800\/20 {
  color: #115e5933;
}

.text-teal-800\/25 {
  color: #115e5940;
}

.text-teal-800\/30 {
  color: #115e594d;
}

.text-teal-800\/35 {
  color: #115e5959;
}

.text-teal-800\/40 {
  color: #115e5966;
}

.text-teal-800\/45 {
  color: #115e5973;
}

.text-teal-800\/5 {
  color: #115e590d;
}

.text-teal-800\/50 {
  color: #115e5980;
}

.text-teal-800\/55 {
  color: #115e598c;
}

.text-teal-800\/60 {
  color: #115e5999;
}

.text-teal-800\/65 {
  color: #115e59a6;
}

.text-teal-800\/70 {
  color: #115e59b3;
}

.text-teal-800\/75 {
  color: #115e59bf;
}

.text-teal-800\/80 {
  color: #115e59cc;
}

.text-teal-800\/85 {
  color: #115e59d9;
}

.text-teal-800\/90 {
  color: #115e59e6;
}

.text-teal-800\/95 {
  color: #115e59f2;
}

.text-teal-900 {
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity, 1));
}

.text-teal-900\/0 {
  color: #134e4a00;
}

.text-teal-900\/10 {
  color: #134e4a1a;
}

.text-teal-900\/100 {
  color: #134e4a;
}

.text-teal-900\/15 {
  color: #134e4a26;
}

.text-teal-900\/20 {
  color: #134e4a33;
}

.text-teal-900\/25 {
  color: #134e4a40;
}

.text-teal-900\/30 {
  color: #134e4a4d;
}

.text-teal-900\/35 {
  color: #134e4a59;
}

.text-teal-900\/40 {
  color: #134e4a66;
}

.text-teal-900\/45 {
  color: #134e4a73;
}

.text-teal-900\/5 {
  color: #134e4a0d;
}

.text-teal-900\/50 {
  color: #134e4a80;
}

.text-teal-900\/55 {
  color: #134e4a8c;
}

.text-teal-900\/60 {
  color: #134e4a99;
}

.text-teal-900\/65 {
  color: #134e4aa6;
}

.text-teal-900\/70 {
  color: #134e4ab3;
}

.text-teal-900\/75 {
  color: #134e4abf;
}

.text-teal-900\/80 {
  color: #134e4acc;
}

.text-teal-900\/85 {
  color: #134e4ad9;
}

.text-teal-900\/90 {
  color: #134e4ae6;
}

.text-teal-900\/95 {
  color: #134e4af2;
}

.text-violet-100 {
  --tw-text-opacity: 1;
  color: rgb(237 233 254 / var(--tw-text-opacity, 1));
}

.text-violet-100\/0 {
  color: #ede9fe00;
}

.text-violet-100\/10 {
  color: #ede9fe1a;
}

.text-violet-100\/100 {
  color: #ede9fe;
}

.text-violet-100\/15 {
  color: #ede9fe26;
}

.text-violet-100\/20 {
  color: #ede9fe33;
}

.text-violet-100\/25 {
  color: #ede9fe40;
}

.text-violet-100\/30 {
  color: #ede9fe4d;
}

.text-violet-100\/35 {
  color: #ede9fe59;
}

.text-violet-100\/40 {
  color: #ede9fe66;
}

.text-violet-100\/45 {
  color: #ede9fe73;
}

.text-violet-100\/5 {
  color: #ede9fe0d;
}

.text-violet-100\/50 {
  color: #ede9fe80;
}

.text-violet-100\/55 {
  color: #ede9fe8c;
}

.text-violet-100\/60 {
  color: #ede9fe99;
}

.text-violet-100\/65 {
  color: #ede9fea6;
}

.text-violet-100\/70 {
  color: #ede9feb3;
}

.text-violet-100\/75 {
  color: #ede9febf;
}

.text-violet-100\/80 {
  color: #ede9fecc;
}

.text-violet-100\/85 {
  color: #ede9fed9;
}

.text-violet-100\/90 {
  color: #ede9fee6;
}

.text-violet-100\/95 {
  color: #ede9fef2;
}

.text-violet-200 {
  --tw-text-opacity: 1;
  color: rgb(221 214 254 / var(--tw-text-opacity, 1));
}

.text-violet-200\/0 {
  color: #ddd6fe00;
}

.text-violet-200\/10 {
  color: #ddd6fe1a;
}

.text-violet-200\/100 {
  color: #ddd6fe;
}

.text-violet-200\/15 {
  color: #ddd6fe26;
}

.text-violet-200\/20 {
  color: #ddd6fe33;
}

.text-violet-200\/25 {
  color: #ddd6fe40;
}

.text-violet-200\/30 {
  color: #ddd6fe4d;
}

.text-violet-200\/35 {
  color: #ddd6fe59;
}

.text-violet-200\/40 {
  color: #ddd6fe66;
}

.text-violet-200\/45 {
  color: #ddd6fe73;
}

.text-violet-200\/5 {
  color: #ddd6fe0d;
}

.text-violet-200\/50 {
  color: #ddd6fe80;
}

.text-violet-200\/55 {
  color: #ddd6fe8c;
}

.text-violet-200\/60 {
  color: #ddd6fe99;
}

.text-violet-200\/65 {
  color: #ddd6fea6;
}

.text-violet-200\/70 {
  color: #ddd6feb3;
}

.text-violet-200\/75 {
  color: #ddd6febf;
}

.text-violet-200\/80 {
  color: #ddd6fecc;
}

.text-violet-200\/85 {
  color: #ddd6fed9;
}

.text-violet-200\/90 {
  color: #ddd6fee6;
}

.text-violet-200\/95 {
  color: #ddd6fef2;
}

.text-violet-500 {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity, 1));
}

.text-violet-500\/0 {
  color: #8b5cf600;
}

.text-violet-500\/10 {
  color: #8b5cf61a;
}

.text-violet-500\/100 {
  color: #8b5cf6;
}

.text-violet-500\/15 {
  color: #8b5cf626;
}

.text-violet-500\/20 {
  color: #8b5cf633;
}

.text-violet-500\/25 {
  color: #8b5cf640;
}

.text-violet-500\/30 {
  color: #8b5cf64d;
}

.text-violet-500\/35 {
  color: #8b5cf659;
}

.text-violet-500\/40 {
  color: #8b5cf666;
}

.text-violet-500\/45 {
  color: #8b5cf673;
}

.text-violet-500\/5 {
  color: #8b5cf60d;
}

.text-violet-500\/50 {
  color: #8b5cf680;
}

.text-violet-500\/55 {
  color: #8b5cf68c;
}

.text-violet-500\/60 {
  color: #8b5cf699;
}

.text-violet-500\/65 {
  color: #8b5cf6a6;
}

.text-violet-500\/70 {
  color: #8b5cf6b3;
}

.text-violet-500\/75 {
  color: #8b5cf6bf;
}

.text-violet-500\/80 {
  color: #8b5cf6cc;
}

.text-violet-500\/85 {
  color: #8b5cf6d9;
}

.text-violet-500\/90 {
  color: #8b5cf6e6;
}

.text-violet-500\/95 {
  color: #8b5cf6f2;
}

.text-violet-800 {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity, 1));
}

.text-violet-800\/0 {
  color: #5b21b600;
}

.text-violet-800\/10 {
  color: #5b21b61a;
}

.text-violet-800\/100 {
  color: #5b21b6;
}

.text-violet-800\/15 {
  color: #5b21b626;
}

.text-violet-800\/20 {
  color: #5b21b633;
}

.text-violet-800\/25 {
  color: #5b21b640;
}

.text-violet-800\/30 {
  color: #5b21b64d;
}

.text-violet-800\/35 {
  color: #5b21b659;
}

.text-violet-800\/40 {
  color: #5b21b666;
}

.text-violet-800\/45 {
  color: #5b21b673;
}

.text-violet-800\/5 {
  color: #5b21b60d;
}

.text-violet-800\/50 {
  color: #5b21b680;
}

.text-violet-800\/55 {
  color: #5b21b68c;
}

.text-violet-800\/60 {
  color: #5b21b699;
}

.text-violet-800\/65 {
  color: #5b21b6a6;
}

.text-violet-800\/70 {
  color: #5b21b6b3;
}

.text-violet-800\/75 {
  color: #5b21b6bf;
}

.text-violet-800\/80 {
  color: #5b21b6cc;
}

.text-violet-800\/85 {
  color: #5b21b6d9;
}

.text-violet-800\/90 {
  color: #5b21b6e6;
}

.text-violet-800\/95 {
  color: #5b21b6f2;
}

.text-violet-900 {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity, 1));
}

.text-violet-900\/0 {
  color: #4c1d9500;
}

.text-violet-900\/10 {
  color: #4c1d951a;
}

.text-violet-900\/100 {
  color: #4c1d95;
}

.text-violet-900\/15 {
  color: #4c1d9526;
}

.text-violet-900\/20 {
  color: #4c1d9533;
}

.text-violet-900\/25 {
  color: #4c1d9540;
}

.text-violet-900\/30 {
  color: #4c1d954d;
}

.text-violet-900\/35 {
  color: #4c1d9559;
}

.text-violet-900\/40 {
  color: #4c1d9566;
}

.text-violet-900\/45 {
  color: #4c1d9573;
}

.text-violet-900\/5 {
  color: #4c1d950d;
}

.text-violet-900\/50 {
  color: #4c1d9580;
}

.text-violet-900\/55 {
  color: #4c1d958c;
}

.text-violet-900\/60 {
  color: #4c1d9599;
}

.text-violet-900\/65 {
  color: #4c1d95a6;
}

.text-violet-900\/70 {
  color: #4c1d95b3;
}

.text-violet-900\/75 {
  color: #4c1d95bf;
}

.text-violet-900\/80 {
  color: #4c1d95cc;
}

.text-violet-900\/85 {
  color: #4c1d95d9;
}

.text-violet-900\/90 {
  color: #4c1d95e6;
}

.text-violet-900\/95 {
  color: #4c1d95f2;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgb(254 249 195 / var(--tw-text-opacity, 1));
}

.text-yellow-100\/0 {
  color: #fef9c300;
}

.text-yellow-100\/10 {
  color: #fef9c31a;
}

.text-yellow-100\/100 {
  color: #fef9c3;
}

.text-yellow-100\/15 {
  color: #fef9c326;
}

.text-yellow-100\/20 {
  color: #fef9c333;
}

.text-yellow-100\/25 {
  color: #fef9c340;
}

.text-yellow-100\/30 {
  color: #fef9c34d;
}

.text-yellow-100\/35 {
  color: #fef9c359;
}

.text-yellow-100\/40 {
  color: #fef9c366;
}

.text-yellow-100\/45 {
  color: #fef9c373;
}

.text-yellow-100\/5 {
  color: #fef9c30d;
}

.text-yellow-100\/50 {
  color: #fef9c380;
}

.text-yellow-100\/55 {
  color: #fef9c38c;
}

.text-yellow-100\/60 {
  color: #fef9c399;
}

.text-yellow-100\/65 {
  color: #fef9c3a6;
}

.text-yellow-100\/70 {
  color: #fef9c3b3;
}

.text-yellow-100\/75 {
  color: #fef9c3bf;
}

.text-yellow-100\/80 {
  color: #fef9c3cc;
}

.text-yellow-100\/85 {
  color: #fef9c3d9;
}

.text-yellow-100\/90 {
  color: #fef9c3e6;
}

.text-yellow-100\/95 {
  color: #fef9c3f2;
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgb(254 240 138 / var(--tw-text-opacity, 1));
}

.text-yellow-200\/0 {
  color: #fef08a00;
}

.text-yellow-200\/10 {
  color: #fef08a1a;
}

.text-yellow-200\/100 {
  color: #fef08a;
}

.text-yellow-200\/15 {
  color: #fef08a26;
}

.text-yellow-200\/20 {
  color: #fef08a33;
}

.text-yellow-200\/25 {
  color: #fef08a40;
}

.text-yellow-200\/30 {
  color: #fef08a4d;
}

.text-yellow-200\/35 {
  color: #fef08a59;
}

.text-yellow-200\/40 {
  color: #fef08a66;
}

.text-yellow-200\/45 {
  color: #fef08a73;
}

.text-yellow-200\/5 {
  color: #fef08a0d;
}

.text-yellow-200\/50 {
  color: #fef08a80;
}

.text-yellow-200\/55 {
  color: #fef08a8c;
}

.text-yellow-200\/60 {
  color: #fef08a99;
}

.text-yellow-200\/65 {
  color: #fef08aa6;
}

.text-yellow-200\/70 {
  color: #fef08ab3;
}

.text-yellow-200\/75 {
  color: #fef08abf;
}

.text-yellow-200\/80 {
  color: #fef08acc;
}

.text-yellow-200\/85 {
  color: #fef08ad9;
}

.text-yellow-200\/90 {
  color: #fef08ae6;
}

.text-yellow-200\/95 {
  color: #fef08af2;
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}

.text-yellow-500\/0 {
  color: #eab30800;
}

.text-yellow-500\/10 {
  color: #eab3081a;
}

.text-yellow-500\/100 {
  color: #eab308;
}

.text-yellow-500\/15 {
  color: #eab30826;
}

.text-yellow-500\/20 {
  color: #eab30833;
}

.text-yellow-500\/25 {
  color: #eab30840;
}

.text-yellow-500\/30 {
  color: #eab3084d;
}

.text-yellow-500\/35 {
  color: #eab30859;
}

.text-yellow-500\/40 {
  color: #eab30866;
}

.text-yellow-500\/45 {
  color: #eab30873;
}

.text-yellow-500\/5 {
  color: #eab3080d;
}

.text-yellow-500\/50 {
  color: #eab30880;
}

.text-yellow-500\/55 {
  color: #eab3088c;
}

.text-yellow-500\/60 {
  color: #eab30899;
}

.text-yellow-500\/65 {
  color: #eab308a6;
}

.text-yellow-500\/70 {
  color: #eab308b3;
}

.text-yellow-500\/75 {
  color: #eab308bf;
}

.text-yellow-500\/80 {
  color: #eab308cc;
}

.text-yellow-500\/85 {
  color: #eab308d9;
}

.text-yellow-500\/90 {
  color: #eab308e6;
}

.text-yellow-500\/95 {
  color: #eab308f2;
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity, 1));
}

.text-yellow-800\/0 {
  color: #854d0e00;
}

.text-yellow-800\/10 {
  color: #854d0e1a;
}

.text-yellow-800\/100 {
  color: #854d0e;
}

.text-yellow-800\/15 {
  color: #854d0e26;
}

.text-yellow-800\/20 {
  color: #854d0e33;
}

.text-yellow-800\/25 {
  color: #854d0e40;
}

.text-yellow-800\/30 {
  color: #854d0e4d;
}

.text-yellow-800\/35 {
  color: #854d0e59;
}

.text-yellow-800\/40 {
  color: #854d0e66;
}

.text-yellow-800\/45 {
  color: #854d0e73;
}

.text-yellow-800\/5 {
  color: #854d0e0d;
}

.text-yellow-800\/50 {
  color: #854d0e80;
}

.text-yellow-800\/55 {
  color: #854d0e8c;
}

.text-yellow-800\/60 {
  color: #854d0e99;
}

.text-yellow-800\/65 {
  color: #854d0ea6;
}

.text-yellow-800\/70 {
  color: #854d0eb3;
}

.text-yellow-800\/75 {
  color: #854d0ebf;
}

.text-yellow-800\/80 {
  color: #854d0ecc;
}

.text-yellow-800\/85 {
  color: #854d0ed9;
}

.text-yellow-800\/90 {
  color: #854d0ee6;
}

.text-yellow-800\/95 {
  color: #854d0ef2;
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(113 63 18 / var(--tw-text-opacity, 1));
}

.text-yellow-900\/0 {
  color: #713f1200;
}

.text-yellow-900\/10 {
  color: #713f121a;
}

.text-yellow-900\/100 {
  color: #713f12;
}

.text-yellow-900\/15 {
  color: #713f1226;
}

.text-yellow-900\/20 {
  color: #713f1233;
}

.text-yellow-900\/25 {
  color: #713f1240;
}

.text-yellow-900\/30 {
  color: #713f124d;
}

.text-yellow-900\/35 {
  color: #713f1259;
}

.text-yellow-900\/40 {
  color: #713f1266;
}

.text-yellow-900\/45 {
  color: #713f1273;
}

.text-yellow-900\/5 {
  color: #713f120d;
}

.text-yellow-900\/50 {
  color: #713f1280;
}

.text-yellow-900\/55 {
  color: #713f128c;
}

.text-yellow-900\/60 {
  color: #713f1299;
}

.text-yellow-900\/65 {
  color: #713f12a6;
}

.text-yellow-900\/70 {
  color: #713f12b3;
}

.text-yellow-900\/75 {
  color: #713f12bf;
}

.text-yellow-900\/80 {
  color: #713f12cc;
}

.text-yellow-900\/85 {
  color: #713f12d9;
}

.text-yellow-900\/90 {
  color: #713f12e6;
}

.text-yellow-900\/95 {
  color: #713f12f2;
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-slate-300 {
  --tw-shadow-color: #cbd5e1;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline {
  outline-style: solid;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity, 1));
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity, 1));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.ring-opacity-60 {
  --tw-ring-opacity: .6;
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-sky-300 {
  --tw-ring-offset-color: #7dd3fc;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ProseMirror p.is-editor-empty:first-child:before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  pointer-events: none;
  height: 0;
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sky-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity, 1));
}

.hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity, 1));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.hover\:ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-sky-900:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity, 1));
}

.hover\:ring-offset-2:hover {
  --tw-ring-offset-width: 2px;
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity, 1));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity, 1));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity, 1));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

@media (width >= 640px) {
  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:tracking-tight {
    letter-spacing: -.025em;
  }

  .sm\:duration-700 {
    transition-duration: .7s;
  }
}

@media (width >= 768px) {
  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }
}
/*# sourceMappingURL=index.01ccebbe.css.map */
