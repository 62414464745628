@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .notes-editor h1 {
    @apply text-3xl font-bold mb-4
  }

  .notes-editor h2 {
    @apply text-2xl font-bold mb-3
  }

  .notes-editor h3 {
    @apply text-xl font-bold mb-2
  }

  .notes-editor h4 {
    @apply text-lg font-bold mb-2
  }

  .notes-editor p {
    @apply mb-2
  }

  .notes-editor ul {
    @apply mb-4
  }

  .notes-editor li {
    @apply mb-1 ml-8 list-disc
  }

  .notes-editor li p {
    @apply mb-1
  }

  .notes-editor .ProseMirror:focus-visible {
    @apply outline-none
  }

  .notes-editor {
    @apply overflow-y-auto h-48
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}